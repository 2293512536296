import { useQuery } from 'react-query';
import { profileCompany as getProfileCompany } from 'api/profile';
import { isNil } from 'lodash';
import { ICompanyProfile } from 'common/interface';

/**
 * description : get profile company dynamic with companyId
 */

const useProfileCompanySuper = ({
  companyId,
  enabled = false,
  keepPreviousData,
  onSuccess,
}: {
  companyId?: number;
  enabled?: boolean;
  keepPreviousData?: boolean;
  onSuccess?: ((data: any) => void) | undefined;
}) => {
  // get profile company of admin in super calendar
  const { data: dataProfileCompany, ...rest } = useQuery(['profileCompany-multi-calendar', companyId], {
    queryFn: () => {
      return getProfileCompany(companyId);
    },
    enabled: enabled && !isNil(companyId),
    keepPreviousData,
    onSuccess(data) {
      onSuccess && onSuccess(data);
    },
  });
  const profileCompanySuper = dataProfileCompany?.data as ICompanyProfile;

  return { profileCompanySuper, ...rest };
};

export default useProfileCompanySuper;
