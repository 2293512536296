import icSendEmail from 'assets/icons/send-email.svg';
import classNames from 'classnames';
import { CommonModal } from 'components/CommonModal';
import { Button, Form } from 'antd';
import { CommonInput } from 'components/CommonInput';
import styles from './styles.module.scss';
import useToggleModal from 'hooks/useToggleModal';
import { useMutation } from 'react-query';
import { sendEmail } from 'api/task';
import { handleErrorMessage, handleMessageSuccess } from 'helper';
import { useState } from 'react';
import { ICompanyActive, ITaskDetail } from 'common/interface';
import { useRulesForm } from 'hooks/useRulesForm';
import useProfile from 'hooks/useProfile';
import { getNamePdfFile } from './utils';
import Loader from 'components/Loader';
import sanitize from 'sanitize-filename';
import { TYPE_INPUT } from 'common';
//@ts-ignore
import html2pdf from 'html2pdf.js';
import { getImageFormUrl } from 'api/common';
import CommonSpace from 'components/CommonSpace';
import icons from 'assets/icons';
import { dateUtils } from 'common/dateUtils';

interface IProps {
  detailsTaskRef: React.RefObject<HTMLDivElement>;
  taskDetail?: ITaskDetail;
  companySelected?: ICompanyActive;
}

type TValue = {
  replyToEmail: string;
  to: string;
  subject: string;
  html: string;
};

type TInfoFile = {
  file: File | null;
  blob: Blob | null;
};

const ButtonSendEmail = ({ detailsTaskRef, taskDetail, companySelected }: IProps) => {
  const profile = useProfile();

  const [form] = Form.useForm();
  const { rulesForm } = useRulesForm();

  const { isOpen, toggleModal } = useToggleModal();
  const [isLoadingPdf, setIsLoadingPdf] = useState(false);

  // File send email
  const [infoFile, setInfoFile] = useState<TInfoFile>({
    file: null,
    blob: null,
  });

  // Preview PDF
  const [previewPdf, setPreviewPdf] = useState({
    open: false,
    html: '',
  });

  const generatePdf = async ({ nameFile }: { nameFile: string }) => {
    if (!detailsTaskRef.current) return;

    setIsLoadingPdf(true);

    try {
      const clonedElement = detailsTaskRef.current.cloneNode(true) as HTMLElement;

      // Add extra elements
      const extraElements = document.createElement('div');
      extraElements.innerHTML = `
        <div style="padding-left:10px;">
          <div>
            ${[taskDetail?.taskCompanyName, profile?.name].filter(Boolean).join(' - ')}
          </div>
          <div>件名 : ${nameFile}</div>
          <div>${dateUtils.currentFullTimeJaPan3()}</div>
          <div></div>
        </div>
      `;

      // Hide unnecessary elements with class hide-with-pdf
      clonedElement.querySelectorAll('.hide-with-pdf').forEach((el) => el.remove());

      // Replace image URLs to avoid CORS issues
      clonedElement.querySelectorAll('img')?.forEach((img: any) => {
        if (img?.src?.startsWith('https://')) {
          img.src = img.src.replace(img.src, getImageFormUrl(img.src));
        }
      });

      // Remove unnecessary classes for proper PDF rendering
      clonedElement.classList.remove('fixed-header-form');

      // Tạo div cha mới
      const wrapperDiv = document.createElement('div');

      // Thêm extraElements và clonedElement vào wrapperDiv
      wrapperDiv.appendChild(extraElements);
      wrapperDiv.appendChild(clonedElement);

      setPreviewPdf((pre) => ({ ...pre, html: wrapperDiv.innerHTML }));

      // PDF options
      const opt = {
        margin: 0.5,
        image: { type: 'jpeg', quality: 0.95 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: 'in', format: 'a3', orientation: 'portrait' },
      };

      // Generate PDF blob

      const blob = await html2pdf().set(opt).from(wrapperDiv).outputPdf('blob', nameFile);

      // Convert blob to file
      const file = new File([blob], nameFile, { type: 'application/pdf' });

      setInfoFile((pre) => ({ ...pre, file, blob }));
    } catch (error) {
      handleErrorMessage(error);
    } finally {
      setIsLoadingPdf(false);
    }
  };

  const sendEmailMutation = useMutation({
    mutationFn: sendEmail,
  });

  const onSendEmail = (values: TValue) => {
    const formData = new FormData();
    formData.append('replyToEmail', values.replyToEmail);
    formData.append('to', values.to);
    formData.append('subject', values.subject ?? '');
    formData.append('html', values.html ?? '');
    formData.append('file', infoFile.file as File);
    formData.append('taskId', taskDetail?.id as any);

    sendEmailMutation.mutate(
      { data: formData, companyId: companySelected?.id },
      {
        onSuccess() {
          // Ví sau khi gửi email xong thì đóng modal lại nên cần reset form, state
          form.resetFields();
          setInfoFile({ file: null, blob: null });

          handleMessageSuccess('メールを送信しました。');
          toggleModal(false);
        },
        onError(error) {
          handleErrorMessage(error);
        },
      }
    );
  };

  const onClickBtnSendEmail = async () => {
    toggleModal(true);

    const namePdfFile = sanitize(getNamePdfFile(taskDetail) || 'task_details.pdf');

    // Set default form values
    form.setFieldsValue({
      replyToEmail: profile?.email,
      to: taskDetail?.customerEmail,
      subject: namePdfFile,
    });

    // Generate PDF
    await generatePdf({ nameFile: namePdfFile });
  };

  return (
    <>
      <div className={styles.customButton} onClick={onClickBtnSendEmail}>
        <img src={icSendEmail} alt="" />
        <div className={classNames(styles.titleUnderline, 'strong')}>メール送信</div>
      </div>

      <CommonModal
        visible={isOpen}
        resetPadding
        title="クライアントにメールを送信"
        okText="メール送信"
        cancelText="キャンセル"
        className={styles.modalSendEmail}
        onOk={() => form.submit()}
        onCancel={() => {
          toggleModal(false);
          form.resetFields();
          setInfoFile({ file: null, blob: null });
        }}
        confirmLoading={sendEmailMutation.isLoading}
      >
        <Form form={form} layout="vertical" onFinish={onSendEmail}>
          <Form.Item label="From" name="replyToEmail" className="mb-10" rules={[rulesForm().required]}>
            <CommonInput placeholder="example@gmail.com" />
          </Form.Item>
          <Form.Item label="To" name="to" className="mb-10" rules={[rulesForm().required]}>
            <CommonInput placeholder="example@gmail.com" />
          </Form.Item>
          <Form.Item
            label="件名"
            name="subject"
            className="mb-10"
            rules={[rulesForm().required, rulesForm().validatePdfFileName()]}
            validateFirst
          >
            <CommonInput placeholder="タイトルを入力してください。" maxLength={255} />
          </Form.Item>
          <Form.Item label="メッセージ" name="html" className="mb-10">
            <CommonInput placeholder="メッセージを入力してください" mode={TYPE_INPUT.MULTIPLE} />
          </Form.Item>

          <Loader isLoading={isLoadingPdf}>
            {infoFile.file && (
              <CommonSpace align="center" gap={10}>
                <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues?.subject !== curValues?.subject}>
                  {({ getFieldValue }) => {
                    const fileNamePdf = getFieldValue('subject');

                    return <div className={styles.linkPdf}>{fileNamePdf}</div>;
                  }}
                </Form.Item>

                <Form.Item shouldUpdate noStyle>
                  {({ getFieldValue }) => {
                    // Re-render PDF
                    const namePdfFile = getFieldValue('subject');
                    // const replyToEmail = getFieldValue('replyToEmail');

                    return (
                      <Button
                        type="text"
                        icon={<img src={icons.eye.gray} alt="" style={{ width: '20px' }} />}
                        shape="circle"
                        onClick={async () => {
                          await generatePdf({ nameFile: namePdfFile });
                          setPreviewPdf((pre) => ({ ...pre, open: true }));
                        }}
                      />
                    );
                  }}
                </Form.Item>
              </CommonSpace>
            )}
          </Loader>
        </Form>
      </CommonModal>

      <CommonModal
        title=""
        titleCenter
        visible={previewPdf.open}
        width={1200}
        footer={null}
        onCancel={() => setPreviewPdf({ html: '', open: false })}
        resetPadding
      >
        <div dangerouslySetInnerHTML={{ __html: previewPdf.html }}></div>
      </CommonModal>
    </>
  );
};

export default ButtonSendEmail;
