import { Form, Radio, Spin, Typography } from 'antd';
import { searchCompany } from 'api/profile';
import { CompanyStatus, STATUS, SubscriptionType, UserStatus } from 'common';
import AvatarProfile from 'components/Avatar';
import Cookies from 'js-cookie';

import { useQuery } from 'react-query';
import styles from './styles.module.scss';
import { checkIsDisableCompany, isExpiredPurchased } from '../utils';
import { ROLES_ADMIN } from 'common/const';

const { Text, Title } = Typography;

// display company for any role
const DisplayCompany = (adminId: number, profileId: number, status: number) => {
  if (status === CompanyStatus.NOT_VERIFY && profileId !== adminId) {
    return false;
  } else if (status === CompanyStatus.REJECT_VERIFY && profileId !== adminId) {
    return false;
  }
  return true;
};

interface IProps {
  currentProfile: any;
  handleChange: (data: any) => any;
  currentCompany?: boolean;
}

export default function ListCompany({ currentProfile, handleChange, currentCompany = false }: IProps) {
  const { data: companies, isLoading } = useQuery('commonCompany', () => searchCompany(), {
    enabled: true,
  });

  const currentCompanyId = +Cookies.get('companyId')!;

  const listCompany = companies?.data.filter((el: any) => el.id !== currentCompanyId);
  const companyActive = companies?.data.find((el: any) => el.id === currentCompanyId);

  const renderErrorText = (companyItem: any) => {
    const isAdmin = ROLES_ADMIN.includes(companyItem?.roleId);

    if (companyItem?.paymentStatus === STATUS.INACTIVE && !isAdmin) {
      return (
        <Text type="danger" className={styles.errorText}>
          有料プランの有効期限が切れました。
        </Text>
      );
    }

    if (companyItem?.adminStatus === UserStatus.BLOCKED)
      return (
        <Text type="danger" className={styles.errorText}>
          この企業にはアクセスできません。
        </Text>
      );

    if (companyItem.status === CompanyStatus.REJECT_VERIFY)
      return (
        <Text type="danger" className={styles.errorText}>
          企業を拒否されました。
        </Text>
      );

    // if (companyItem?.subscriptionType === SubscriptionType.ADMIN_BUY) return '';

    if (isExpiredPurchased(companyItem) && companyItem?.adminId !== currentProfile?.id)
      return <Text className={`${styles.errorText} color-brown`}>支払いが完了していません。</Text>;

    if (companyItem.status === CompanyStatus.NOT_VERIFY)
      return (
        <Text type="warning" className={styles.errorText}>
          企業が承認されていません。
        </Text>
      );

    if (companyItem.status === CompanyStatus.INACTIVE)
      return (
        <Text type="danger" className={styles.errorText}>
          企業がインアクティブです。
        </Text>
      );

    if (companyItem.userCompanyStatus === CompanyStatus.INACTIVE)
      return (
        <Text type="danger" className={styles.errorText}>
          アカウントがロックされています。
        </Text>
      );

    return <></>;
  };

  return (
    <>
      {currentCompany && (
        <div className={styles.profileCompany}>
          <AvatarProfile size="large" name={companyActive?.name || 'c'} src={companyActive?.avatar} />
          <Title level={4} className="line-1" title={companyActive?.name}>
            {companyActive?.name}
          </Title>
        </div>
      )}
      <div className={styles.listCompany}>
        <Form.Item name="role" className={styles.buttonSelect}>
          <Radio.Group className={styles.role} onChange={handleChange}>
            {(isLoading && (
              <div className={styles.spin}>
                <Spin size="default" />
              </div>
            )) ||
              (currentCompany ? listCompany : companies?.data)?.map(
                (el: any, index: number) =>
                  DisplayCompany(el?.adminId, currentProfile?.id, el.status) && (
                    <Radio
                      value={index}
                      className={styles.logoButton}
                      key={index}
                      disabled={checkIsDisableCompany(el, currentProfile)}
                    >
                      <AvatarProfile src={el.avatar} name={el?.name || ''} className={styles.logoImage} />
                      <div className={styles.companyName}>
                        <Title
                          level={5}
                          disabled={checkIsDisableCompany(el, currentProfile)}
                          ellipsis
                          className={styles.ellipsis}
                          title={el.name}
                        >
                          {el.name}
                        </Title>
                        {renderErrorText(el)}
                      </div>
                    </Radio>
                  )
              )}
          </Radio.Group>
        </Form.Item>
      </div>
    </>
  );
}
