import { FormInstance } from 'antd';
import { IVendor } from 'common/interface';
import _, { capitalize } from 'lodash';
import { Shift } from 'pages/Tasks/AddTaskModal';
import { useMemo, useState } from 'react';

type HandleSelectedVendorFunc = ({
  vendor,
  shift,
  type,
  nameFields,
}: {
  vendor: IVendor;
  type: 'add' | 'remove';
  shift?: Shift;
  nameFields?: string[];
}) => void;

export type HandlePickSelectedVendorFunc = (vendor: Partial<IVendor>, type: 'add' | 'remove') => void;
export type SelectedVendor = Partial<IVendor & { isPicked?: boolean; shift?: Shift }>;

// Custom hook to handle select vendor in calendar

interface IProps {
  form?: FormInstance;
}

const useSelectVendorCalendar = ({ form }: IProps) => {
  // selected vendor
  const [selectedVendor, setSelectedVendor] = useState<SelectedVendor[]>([]);

  // unique selected vendor to render
  const uniqueSelectedVendor: SelectedVendor[] = useMemo(() => {
    return _.uniqBy(selectedVendor, 'id');
  }, [selectedVendor]);

  // handle select vendor in shift area
  const handleSelectedVendor: HandleSelectedVendorFunc = ({ vendor, shift, type, nameFields }) => {
    if (type === 'add') {
      setSelectedVendor((pre) => [...pre, { ...vendor, shift }]);
    } else if (type === 'remove') {
      const newSelectedVendor = _.cloneDeep(selectedVendor);

      const indexVendorDelete = newSelectedVendor.findIndex(
        (item) => item.id === vendor.id && (item as any)?.shift === shift
      );

      if (indexVendorDelete !== -1) {
        newSelectedVendor.splice(indexVendorDelete, 1);
      }

      const isExistVendorAfterRemove = newSelectedVendor.some((item) => item.id === vendor.id);

      if (!isExistVendorAfterRemove && form) {
        const values = form.getFieldsValue(true) || {};

        form.setFieldsValue({ ...values, [vendor.id]: undefined });
      }

      setSelectedVendor(newSelectedVendor);
    }
  };

  // handle select vendor in vendor area
  const handlePickSelectedVendor: HandlePickSelectedVendorFunc = (vendor, type) => {
    const newSelectedVendor = selectedVendor.map((item) => {
      if (item.id === vendor.id) {
        return { ...item, isPicked: type === 'add' };
      }

      return item;
    });

    setSelectedVendor(newSelectedVendor);
  };

  // remove all vendor in shift when uncheck
  const removeAllVendorInShift = (shift: Shift) => {
    const tableVendors = selectedVendor.reduce((obj, vendor) => {
      return { ...obj, [vendor.id as number]: obj?.[vendor?.id as number] + 1 || 1 };
    }, {} as any);

    const newSelectedVendor = selectedVendor.filter((vendor) => {
      if (tableVendors?.[vendor?.id as number] === 1 && form) {
        const values = form.getFieldsValue(true) || {};

        form.setFieldsValue({ ...values, [vendor?.id as number]: undefined });
      }

      return vendor?.shift !== shift;
    });

    const nameVendorField = `vendors${capitalize(shift)}`;

    form?.resetFields([nameVendorField]);
    setSelectedVendor(newSelectedVendor);
  };

  return {
    selectedVendor,
    setSelectedVendor,
    uniqueSelectedVendor,
    handlePickSelectedVendor,
    removeAllVendorInShift,
    handleSelectedVendor,
  };
};

export default useSelectVendorCalendar;
