import { Row, Tooltip } from 'antd';
import classNames from 'classnames';
import AvatarProfile from 'components/Avatar';
import { formatCurrencyJP, getRoleName, getSalaryStaff, getShiftNumber } from 'helper';
import { NavLink } from 'react-router-dom';
import styles from './styles.module.scss';
import { SalaryType, UserRole } from 'common';
import { IUnitPriceCertificateWorkerItem, IWorker } from 'common/interface';
import useProfile from 'hooks/useProfile';
import { Shift } from '../AddTaskModal';
import { capitalize, isNil } from 'lodash';
import { DEFAULT_COUNT_WORK, ROLES_ADMIN, textJP } from 'common/const';
import CommonSpace from 'components/CommonSpace';

interface StaffItemProps {
  worker: IWorker;
  hiddenPrice?: boolean;
  isCanViewLink?: boolean;
  shift: Shift;
  roleId: UserRole;
}

const priceWorkerObj: Record<Shift, string> = {
  morning: 'priceMorning',
  afternoon: 'priceAfternoon',
  evening: 'priceEvening',
  other: 'price',
};

function StaffItem({ worker, isCanViewLink, shift, roleId }: StaffItemProps) {
  const profile = useProfile();

  const isUnActive = () => {
    if ([UserRole.ADMIN, UserRole.MANAGER, UserRole.LEADER, UserRole.OFFICE_MANAGER].includes(worker?.roleId))
      return false;

    return !Boolean(worker?.status);
  };

  const isShowRoleTitle = ![UserRole.VENDOR, UserRole.CUSTOMER, UserRole.VENDOR_WORKER].includes(roleId);

  const isCanViewPrice = () => {
    // const isFreelancer = worker?.roleId === UserRole.OTHER_WORKER;
    const isAdmin = [UserRole.ADMIN, UserRole.MANAGER, UserRole.OFFICE_MANAGER].includes(roleId);

    if (isAdmin) {
      return true;
    }

    // Công nhân của vendor
    if (roleId === UserRole.VENDOR) {
      return worker?.roleId === UserRole.VENDOR_WORKER && worker?.managerId === profile?.id;
    }

    // Chính bản thân worker
    return worker?.id === profile.id;
  };

  const isWorkerHavePrice = () => {
    const isHavePrice =
      !isNil(worker?.salary?.salaryType) &&
      ![SalaryType.MONTH_SALARY, SalaryType.HOUR_SALARY].includes(worker?.salary?.salaryType);

    return isHavePrice;
  };

  const isCanViewCountWorker = () => {
    const isAdmin = ROLES_ADMIN.includes(roleId);
    if (isAdmin) return true;

    // lương giờ không view công số được
    const isHourSalary = worker?.salary?.salaryType === SalaryType.HOUR_SALARY;
    if (isHourSalary) return false;

    // worker có thể tự xem thông tin của mìnhs
    return worker?.id === profile?.id;
  };

  const isCanViewCertificateStaff = () => {
    const isAdmin = ROLES_ADMIN.includes(roleId);
    if (isAdmin) return true;

    // worker có thể tự xem thông tin của mìnhs
    return worker?.id === profile?.id;
  };

  const isOldDataPrice = [worker.priceMorning, worker.priceAfternoon, worker.priceEvening].every(isNil);

  const countWorker = worker?.[`countWork${capitalize(shift)}`] ?? DEFAULT_COUNT_WORK;
  const priceWorker = worker?.[priceWorkerObj[shift as Shift]];
  const defaultPrice =
    worker?.salary &&
    getSalaryStaff({
      roleIdStaff: worker?.roleId,
      shift,
      salary: worker?.salary,
      salaryType: worker?.salary?.salaryType,
    });

  const unitPriceCertificate: IUnitPriceCertificateWorkerItem[] = worker?.unitPriceCertificate
    ? JSON.parse(worker?.unitPriceCertificate)?.[getShiftNumber(shift) as any]
    : [];

  const nameStaff = isShowRoleTitle ? `${worker?.name} (${getRoleName(worker?.roleId)})` : worker?.name;
  const priceString = () => {
    // TH staff có đơn giá thì đơn giá sẽ hiển thị sau công số . vd: 人工 : 1 (単価 : ¥300)
    const price = formatCurrencyJP(isOldDataPrice ? defaultPrice : priceWorker);

    if (unitPriceCertificate?.length > 0) {
      return `(${textJP.label.price} : ${price})`;
    }

    return `${textJP.label.price} : ${price}`;
  };

  // const isWorkerHaveCountWork = () => {
  //   const isHaveCountWork =
  //     !isNil(worker?.salary?.salaryType) && ![SalaryType.HOUR_SALARY].includes(worker?.salary?.salaryType);

  //   return isHaveCountWork;
  // };

  return (
    <div className={classNames(styles.staffItem)}>
      <NavLink
        to={`/staff/${worker?.id}`}
        key={worker?.id}
        target="_blank"
        className={classNames(
          { [styles.unActive]: isUnActive(), [styles.disableLink]: !isCanViewLink },
          styles.linkWorker
        )}
      >
        <AvatarProfile src={worker.avatar} name={worker.name} size={33} />
      </NavLink>

      <div className={styles.info}>
        <NavLink
          to={`/staff/${worker?.id}`}
          key={worker?.id}
          target="_blank"
          className={classNames(
            { [styles.unActive]: isUnActive(), [styles.disableLink]: !isCanViewLink },
            styles.linkWorker
          )}
        >
          <Tooltip title={nameStaff} placement="topLeft">
            <span className={styles.name}>{nameStaff}</span>
          </Tooltip>
        </NavLink>

        <Row align="middle" justify="space-between">
          <CommonSpace align="center" gap={5} style={{ marginLeft: 'auto' }}>
            {isCanViewCountWorker() && (
              <div>
                {textJP.label.numberWorker} : {countWorker}
              </div>
            )}

            {isCanViewPrice() && isWorkerHavePrice() && (
              <Tooltip title={formatCurrencyJP(worker?.price)} placement="topRight">
                <div className={classNames(styles.inputPrice, 'max-1-line')}>{priceString()}</div>
              </Tooltip>
            )}
          </CommonSpace>
        </Row>

        {isCanViewCertificateStaff() && (
          <>
            {unitPriceCertificate?.map((item) => `${item?.title} : ${formatCurrencyJP(item?.priceStaff)}`)?.join(', ')}
          </>
        )}
      </div>
    </div>
  );
}

export default StaffItem;
