import { Col, Form, Input, message, Radio, Row, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import { searchNameCompany } from 'api/customer';
import { updateProfileCustomer } from 'api/profile';
import classNames from 'classnames';
import {
  BIG_PAGESIZE,
  listDateClosingSalary,
  listTypePaymentOffsetWithMonth,
  monthPaymentName,
  PATTERN_EMAIL,
  PATTERN_KATAKANA,
  PATTERN_PHONE,
} from 'common/const';
import CommonAutoComplete from 'components/CommonAutoComplete';
import { CommonButton } from 'components/CommonButton';
import { CommonInput } from 'components/CommonInput';
import { CommonModal } from 'components/CommonModal';
import { CommonSelect } from 'components/CommonSelect';
import { debounce, omit } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useSetState } from 'react-use';
import style from './styles.module.scss';
import { handleErrorMessage } from 'helper';
import { ICustomer } from 'common/interface';
import FormPrice from './components/FormPrice';
import FormCertificate from './components/FormCertificate';
import CommonRadioGroup from 'components/CommonRadioGroup';
import { CommonStatus, TaxType } from 'common';
import FormServiceCharge from './components/FormServiceCharge';
import { getDefaultValueCustomer } from './utils';
import { useRulesForm } from 'hooks/useRulesForm';

// this is form confirm customer when admin invite customer
export default function FormInviteCustomer({ open, handleClose, currentInviteCustomer, currentProfile }: any) {
  const [form] = useForm();
  const queryClient = useQueryClient();
  const customerDetail = currentInviteCustomer ? JSON.parse(currentInviteCustomer?.metadata) : {};
  const { rulesForm } = useRulesForm();

  useEffect(() => {
    if (!currentProfile) return;

    const formValue = getDefaultValueCustomer({
      ...currentProfile,
      ...currentInviteCustomer,
      ...customerDetail,
    });

    form.setFieldsValue({ ...formValue, isTax: formValue?.company?.isTax });
  }, []);

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (
    payload: ICustomer & {
      serviceCharge: CommonStatus;
      checkboxServiceChargeValue: boolean;
      serviceChargePercent: number;
      serviceChargeValue: number;
      checkboxServiceChargePercent: boolean;
    }
  ) => {
    const {
      paymentDate,
      deadline,
      titleRegularUnitPrice,
      regularUnitPrice,
      titleNightUnitPrice,
      nightUnitPrice,
      dataUnitPrice,
      titleCertificate,
      priceCertificate,
      dataUnitPriceCertificate,
      note,
      ...data
    } = payload;

    const dataUpdate = omit(data, [
      'isTax',
      'serviceCharge',
      'typeServiceCharge',
      'valueServiceCharge',
      'checkboxServiceChargeValue',
      'checkboxServiceChargePercent',
      'serviceChargePercent',
      'serviceChargeValue',
      `${monthPaymentName}`,
    ]);

    try {
      setLoading(true);
      await updateProfileCustomer(dataUpdate);
      await queryClient.refetchQueries();
      message.success('成功');
      handleClose();
      setLoading(false);
    } catch (error) {
      handleErrorMessage(error);
      setLoading(false);
    }
  };

  const [searchCustomerParam, setSearchCustomerParam] = useSetState({
    pageIndex: 1,
    pageSize: BIG_PAGESIZE,
    keyword: '',
  });

  const { data: companyName } = useQuery(['list-company-of-customer', searchCustomerParam], () =>
    searchNameCompany(searchCustomerParam).then((res: any) => res?.data)
  );
  const handleSearchCompany = useCallback(
    debounce((keyword: string) => setSearchCustomerParam({ keyword }), 300),
    []
  );

  return (
    <>
      <CommonModal
        title=""
        centered
        isModalVisible={open}
        form={form}
        closable={false}
        handleClose={handleClose}
        footer=""
        className={classNames(style.modalAddVendor, style.pModal, style.formInviteCustomer)}
        maskClosable={false}
      >
        <div className={style.head}>
          <div className={style.name}>クライアントを確認</div>
        </div>
        <Form form={form} layout="vertical" scrollToFirstError={true} onFinish={handleSubmit}>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                label={<span className={classNames('color-text', 'text-13')}>メール</span>}
                name="email"
                rules={[
                  {
                    required: true,
                    message: '全ての項目を入力してください。',
                  },
                  {
                    pattern: PATTERN_EMAIL,
                    message: 'メールが違います',
                  },
                ]}
              >
                <CommonInput className={`inputGreyNoBorder`} disabled />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={<span className={classNames('color-text', 'text-13')}>氏名</span>}
                name="name"
                rules={[
                  {
                    required: true,
                    message: '全ての項目を入力してください。',
                  },
                ]}
                labelAlign="left"
              >
                <CommonInput className={`inputGreyNoBorder`} disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                label={<span className={classNames('color-text', 'text-13')}>企業名</span>}
                name="companyName"
                rules={[
                  {
                    required: true,
                    message: '全ての項目を入力してください。',
                  },
                ]}
              >
                <CommonAutoComplete
                  optionName="companyName"
                  // defaultValue={`${formValues?.companyName ?? ''}`}
                  options={companyName || []}
                  onSearch={handleSearchCompany}
                  className="inputGreyNoBorder"
                  disabled
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<span className={classNames('color-text', 'text-13')}>氏名（カタカナ）</span>}
                name="furiganaName"
                rules={[
                  {
                    required: true,
                    message: '全ての項目を入力してください。',
                  },
                  {
                    pattern: PATTERN_KATAKANA,
                    message: 'カタカナで入力してください。',
                  },
                ]}
                labelAlign="left"
              >
                <CommonInput className={`inputGreyNoBorder`} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                label={<span className={classNames('color-text', 'text-13')}>電話番号</span>}
                name="phone"
                rules={[
                  {
                    required: true,
                    message: '全ての項目を入力してください。',
                  },
                  {
                    pattern: PATTERN_PHONE,
                    message: '電話番号の形式が正しくありません。',
                  },
                ]}
              >
                <Input className={`inputGreyNoBorder`} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<span className={classNames('color-text', 'text-13')}>ファックス</span>}
                name="faxNumber"
                rules={[
                  {
                    required: true,
                    message: '全ての項目を入力してください。',
                  },
                ]}
              >
                <Input className={`inputGreyNoBorder`} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                label={<span className={classNames('color-text', 'text-13')}>郵便番号</span>}
                name="postalCode"
                rules={[
                  {
                    required: true,
                    message: '全ての項目を入力してください。',
                  },
                ]}
              >
                <Input className={`inputGreyNoBorder`} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="住所"
                name="address"
                rules={[
                  {
                    required: true,
                    message: '全ての項目を入力してください。',
                  },
                ]}
              >
                <Input className={`inputGreyNoBorder`} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col span={12}>
              <Row gutter={20}>
                <Col span={12}>
                  <Form.Item
                    label={<span className={classNames('color-text', 'text-13')}>支払月</span>}
                    name={monthPaymentName}
                    rules={[rulesForm().required]}
                  >
                    <CommonSelect options={listTypePaymentOffsetWithMonth} className={style.selectCustom} disabled />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item label="支払日" name="paymentDate">
                    <CommonSelect options={listDateClosingSalary} disabled className={style.selectCustom} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={12}>
              <Form.Item label="締め日" name="deadline">
                <CommonSelect options={listDateClosingSalary} disabled className={style.selectCustom} />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item label="" name="isTax">
                <CommonRadioGroup disabled>
                  <Radio value={TaxType.INACTIVE}>外税</Radio>
                  <Radio value={TaxType.ACTIVE}>内税</Radio>
                </CommonRadioGroup>
              </Form.Item>
            </Col>
          </Row>

          <div className={style.wrapContent}>
            <Row gutter={24}>
              {/* Đơn giá khách hàng */}
              <FormPrice disabledAll showIcons={false} />

              {/* Chứng chỉ của khách hàng */}
              <FormCertificate disabledAll showIcons={false} showCertificateWorker />
            </Row>
          </div>

          <Col span={12}>
            <FormServiceCharge form={form} disabledAll />
          </Col>

          <Row gutter={20}>
            <Col span={24}>
              <Form.Item label="メモ" name="note">
                <TextArea autoSize={{ minRows: 6, maxRows: 6 }} className={`inputGreyNoBorder`} disabled />
              </Form.Item>
            </Col>
          </Row>

          <div className={`${style.footerAddVendor} ${style.footer}`}>
            <Space size={'middle'}>
              <Form.Item>
                <CommonButton
                  title="確認 "
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  className={style.btnSubmit}
                />
              </Form.Item>
            </Space>
          </div>
        </Form>
      </CommonModal>
    </>
  );
}
