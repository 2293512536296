import { DAY_OF_WEEK } from 'common';
import { isNil } from 'lodash';
import moment, { Moment, unitOfTime } from 'moment';
import { listDay } from './const';

const formatFullTime = 'YYYY/MM/DD HH:mm';
const formatDate = 'YYYY/MM/DD';
const formatDatabaseDate = 'YYYY-MM-DD';
const formatHour = 'HH:mm';

export const dateUtils = {
  isDateInPast: (date: any) => {
    return moment(date).isBefore(moment(), 'day');
  },
  formatTime: (time: any, format = 'YYYY-MM-DD') => {
    return moment(time).format(format);
  },
  startOf: (date: any) => {
    return moment(date).startOf('day').toISOString();
  },
  endOf: (date: any) => {
    return moment(date).endOf('day').toISOString();
  },
  getDateOfJapan: (date: any) => {
    return moment(date).format('M月D日');
  },
  getDateAndMonth: (date: any) => {
    if (!date) return '';
    return moment(date).format('MM/DD');
  },
  formatYYYYMMDD: (date: any) => {
    try {
      if (!date) return '';
      return moment(date).format('YYYY/MM/DD');
    } catch (error) {
      return '';
    }
  },
  getDateAndTime: (date: any) => {
    if (!date) return '';
    return moment(date).format('YYYY/MM/DD (hh:mm)');
  },
  getDateAndTime24h: (date: any) => {
    if (!date) return '';
    return moment(date).format('YYYY/MM/DD (HH:mm)');
  },
  getMonth: (date: any) => {
    if (!date) return null;
    return moment(date).format('YYYY-MM');
  },
  getYearJP: (date: any) => {
    return moment(date).format('YYYY年');
  },
  getLastDateOfCurrentMonth: () => {
    return moment().endOf('month').date();
  },
  getDateToString: (date: any) => {
    if (!date) return undefined;
    return moment(date).format('YYYY-MM-DD');
  },
  getToday: () => moment(),
  currentFullTimeJapan: () => {
    return moment().format('YYYY年-MM月-DD日');
  },
  currentFullTimeJapan2: () => {
    return moment().format('YYYY年M月D日');
  },
  currentFullTimeJaPan3: () => {
    return moment().format('YYYY年MM月DD日');
  },
  getFullTimeJapan: (date: any) => {
    return moment(date).format('YYYY年M月D日');
  },
  getMonthYearJapan: (date: any) => {
    return moment(date).format('YYYY年MM月');
  },
  getMonthDayJapan: (date: any) => {
    return moment(date).format('M月D日');
  },
  currentDate: () => {
    return moment().format(formatDate);
  },
  currentDatabaseDate: (date: any) => {
    if (typeof date === 'string') return date;
    return moment(date).format(formatDatabaseDate);
  },
  formatDatabaseDate: (date: any) => {
    if (!date) return null;

    return moment(date).format(formatDatabaseDate);
  },
  currentHour: () => {
    return moment().format(formatHour);
  },

  getFullTime: (date: string) => {
    return moment(date).format(formatFullTime);
  },

  getDate: (date: string | undefined | Date | any) => {
    if (!date) return '';
    return moment(date).format(formatDate);
  },

  getYear: (date: any) => {
    return moment(date).format('YYYY');
  },

  getHour: (date: any) => {
    if (!date) return null;
    return moment(date).format(formatHour);
  },

  convertTime: (time: string) => {
    return moment(time, 'HH:mm');
  },

  isAfter: (startTime: any, endTime: any) => {
    return endTime.isAfter(startTime);
  },
  startOfWeekDatabase: (date: any) => {
    return moment(date).startOf('week').format(formatDatabaseDate);
  },
  endOfWeekDatabase: (date: any) => {
    return moment(date).endOf('week').format(formatDatabaseDate);
  },
  startOfMonthDatabase: (date: any) => {
    return moment(date).startOf('month').format(formatDatabaseDate);
  },
  endOfMonthDatabase: (date: any) => {
    return moment(date).endOf('month').format(formatDatabaseDate);
  },
  startOfWeekDisplay: (date: any) => {
    return moment(date).startOf('week').format(formatDate);
  },
  endOfWeekDisplay: (date: any) => {
    return moment(date).endOf('week').format(formatDate);
  },
  getNextWeek: () => {
    return moment().add(1, 'week');
  },
  disableCurrentWeek: (current: any) => {
    return current && current < moment().add(1, 'week').startOf('week');
  },
  disablePreviousWeek: (current: any) => {
    return current && current < moment().startOf('week');
  },
  startOfWeek: (date: any) => {
    return moment(date).startOf('week');
  },
  getDay: (date: any) => {
    return moment(date);
  },

  getDayIndex: (date: any) => {
    return moment(date).format('DD');
  },

  getWeekday: () => {
    return moment().weekday();
  },
  getIsoWeekday: () => {
    return moment().isoWeekday();
  },
  getEndOfMonth: (date: any) => {
    return moment(date).endOf('month');
  },
  getStartOfMonth: (date: any) => {
    return moment(date).startOf('month');
  },
  getSalaryPaymentDate: (date: any, paymentDate: number) => {
    return moment(date)
      .startOf('month')
      .add(paymentDate - 1, 'd')
      .format('YYYY/MM/DD');
  },
  getDayInMonth: (date: any) => {
    return moment(date).daysInMonth();
  },
  getDateOfMonth: (index: number, date: any) => {
    return moment(date).date(index);
  },
  getWeeksDay: (date: any) => {
    return moment(date).weekday();
  },
  isBeforeTime: (start: any, end: any) => {
    const startTime = typeof start === 'string' ? start : moment(new Date(start)).format(formatHour);
    const endTime = typeof end === 'string' ? end : moment(new Date(end)).format(formatHour);

    if (startTime.toString() <= endTime.toString()) {
      return true;
    }
    return false;
  },

  isBetweenTime: (startTime: any, endTime: any, value: any) => {
    const start = typeof startTime === 'string' ? startTime : moment(new Date(startTime)).format(formatHour);
    const end = typeof endTime === 'string' ? endTime : moment(new Date(endTime)).format(formatHour);
    const currentValue = moment(new Date(value)).format(formatHour);

    if (start.toString() <= currentValue.toString() && currentValue.toString() <= end.toString()) return true;
    return false;
  },

  isBetweenTimeNotEqual: (startTime: any, endTime: any, value: any) => {
    const start = typeof startTime === 'string' ? startTime : moment(new Date(startTime)).format(formatHour);
    const end = typeof endTime === 'string' ? endTime : moment(new Date(endTime)).format(formatHour);
    const currentValue = moment(new Date(value)).format(formatHour);

    if (start.toString() < currentValue.toString() && currentValue.toString() < end.toString()) return true;
    return false;
  },
  isSameDate: (firstDate: any, secondDate: any) => {
    return moment(firstDate).isSame(secondDate, 'day');
  },
  isSameMonthAndYear: (firstDate?: Moment, secondDate?: Moment) => {
    if (!firstDate || !secondDate) return false;

    const isSameMonth = firstDate.isSame(secondDate, 'month');
    const isSameYear = firstDate.isSame(secondDate, 'year');

    return isSameMonth && isSameYear;
  },
  isSameDayMonthYear: (firstDate?: Moment, secondDate?: Moment) => {
    if (!firstDate || !secondDate) return false;

    const isSameDay = firstDate.isSame(secondDate, 'day');
    const isSameMonth = firstDate.isSame(secondDate, 'month');
    const isSameYear = firstDate.isSame(secondDate, 'year');

    return isSameDay && isSameMonth && isSameYear;
  },
  isSameOrBeforeDate: (startDate: any, endDate: any) => {
    return moment(startDate).isSameOrBefore(endDate, 'day');
  },
  isDateInNextWeek(date?: Moment) {
    if (!date) return false;

    const inputDate = moment(date);
    const now = moment();
    const endOfNextWeek = now.clone().add(1, 'weeks').endOf('week');

    // If the date is after now and before the end of next week
    return inputDate.isAfter(now) && inputDate.isBefore(endOfNextWeek);
  },
  getPreviousMonth: (date: any) => {
    return moment(date).subtract(1, 'months');
  },
  getNextMonth: (date: any) => {
    return moment(date).add(1, 'months');
  },
  getDifferentHours: (startDate: any, endDate: any) => moment(endDate).diff(moment(startDate), 'hours'),
  // convert HH:mm to hours
  // ex: 01:30 => 1.5
  convertTimeToHours: (time: string | null) => {
    if (isNil(time)) return 0;

    const [hour = 0, minute = 0] = time?.split(':');

    const result = +hour + +minute / 60;

    return result;
  },
  // ex:  1.5 => 01:30
  convertHoursToTime: (hours?: string) => {
    if (isNil(hours) || hours === '') return '';

    // Extract the whole number of hours
    let wholeHours = Math.floor(+hours);

    // Calculate the remaining minutes
    let remainingMinutes = Math.round((+hours - wholeHours) * 60);

    // Handle case where minutes = 60
    if (remainingMinutes === 60) {
      wholeHours += 1;
      remainingMinutes = 0;
    }

    // Format hours to have leading zero if necessary
    const formattedWholeHours = wholeHours < 10 ? `0${wholeHours}` : `${wholeHours}`;

    // Format minutes to have leading zero if necessary
    const formattedMinutes = remainingMinutes < 10 ? `0${remainingMinutes}` : `${remainingMinutes}`;

    // Display the result
    const result = `${formattedWholeHours}:${formattedMinutes}`;

    return result;
  },
  // convert times 0:0 -> 00:00
  convertToCorrectFormatHours: (timeString?: string) => {
    if (isNil(timeString)) return '';

    // Parse the input time using moment
    const time = moment(timeString, 'H:mm');

    // Format the time to display in '00:00' format
    const formattedTime = time.format('HH:mm');

    return formattedTime;
  },
  getAllDayOfWeekInMonth: (date: Moment, dayOfWeek: DAY_OF_WEEK) => {
    const year = date.get('year');
    const month = date.get('month');

    const days = [];

    const startDate = moment(`${year}-${month + 1}-01`, 'YYYY-MM-DD');
    const endDate = startDate.clone().endOf('month');

    let currentDate = startDate.clone();

    while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day')) {
      if (currentDate.day() === dayOfWeek) {
        days.push(currentDate.format('YYYY-MM-DD'));
      }
      currentDate.add(1, 'day');
    }

    return days;
  },
  getAllDaysInMonth: (date: Moment) => {
    const year = date.get('year');
    const month = date.get('month');

    const days = [];

    const startDate = moment(`${year}-${month + 1}-01`, 'YYYY-MM-DD');
    const endDate = startDate.clone().endOf('month');

    let currentDate = startDate.clone();

    while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day')) {
      days.push(currentDate.format('YYYY-MM-DD'));
      currentDate.add(1, 'day');
    }

    return days;
  },
  getTextDayOfWeek: (date: string | Moment) => {
    const weekday = moment(date).day();

    return listDay?.[weekday as keyof typeof listDay];
  },
  getDuration({ timeStart, timeEnd, format }: { timeStart: string; timeEnd: string; format: unitOfTime.Base }) {
    const start = moment(timeStart, 'HH:mm');
    const end = moment(timeEnd, 'HH:mm');

    // Calculate the difference in hours and minutes
    let duration = moment.duration(end.diff(start));

    // Handle the scenario where check-out time is on the next day
    if (duration.asHours() < 0) {
      end.add(1, 'day');
      duration = moment.duration(end.diff(start));
    }

    // return the duration in the desired format
    return Math.floor(duration.as(format));
  },

  // check if time is in duration
  isBetweenDuration: ({
    startTime,
    endTime,
    time,
  }: {
    startTime: Moment | string;
    endTime: Moment | string;
    time: Moment | string;
  }) => {
    const isInRange = moment(time).isBetween(moment(startTime), moment(endTime), 'day', '[]');

    return isInRange;
  },
  subtractMinutesWithMinLimit: ({
    time,
    minutes,
    minLimit = '00:00',
  }: {
    time: string;
    minutes: string | number;
    minLimit?: string | number;
  }) => {
    if (!time) return '';

    let resultTime = moment(time, 'HH:mm').subtract(minutes, 'minutes');

    // Kiểm tra nếu thời gian nhỏ hơn giới hạn tối thiểu
    if (resultTime.isBefore(moment(minLimit, 'HH:mm'))) {
      resultTime = moment(minLimit, 'HH:mm');
    }

    return resultTime.format('HH:mm');
  },
  convertMinutesToHours: (minutes: number) => {
    if (!minutes) return '';

    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`;
  },
  // check time is today
  isToday: (date?: string | Moment) => {
    if (!date) return false;

    return moment(date).isSame(moment(), 'day');
  },
};
