import {
  REMOVE_NON_NUMERIC_CHARACTERS,
  BLOCK_MULTIPLE_DECIMALS,
  LIMIT_DECIMAL_PLACES,
  VALID_NUMBER_REGEX,
} from 'common/const';

// Helper function: Format giá trị dựa trên decimalPlaces
export const formatValue = (value?: string, decimalPlaces?: number): string => {
  if (decimalPlaces === 0) {
    return value?.replace(/[^\d]/g, '') || ''; // Chỉ giữ số nguyên
  }

  if (!decimalPlaces)
    return value?.replace(REMOVE_NON_NUMERIC_CHARACTERS, '').replace(BLOCK_MULTIPLE_DECIMALS, '$1') || '';

  return (
    value
      ?.replace(REMOVE_NON_NUMERIC_CHARACTERS, '') // Xóa ký tự không hợp lệ
      .replace(LIMIT_DECIMAL_PLACES(decimalPlaces), '$1') // Giới hạn chữ số sau dấu .
      .replace(BLOCK_MULTIPLE_DECIMALS, '$1') || '' // Chặn nhiều dấu .
  );
};

// Helper function: Kiểm tra tính hợp lệ của giá trị
export const isValidNumber = (value?: string | number, decimalPlaces?: number): boolean => {
  if (!decimalPlaces) return true;

  return VALID_NUMBER_REGEX(decimalPlaces).test(value?.toString() || '');
};
