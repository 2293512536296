import { Form } from 'antd';
import { messageError } from 'common/const';
import { checkCheckedShiftAdminSameCountShift } from 'pages/Tasks/utils';

interface IProps {
  checkedOfAdmin: {
    morningShift: boolean;
    afternoonShift: boolean;
    eveningShift: boolean;
    otherShift: boolean;
  };
}

const ErrorCheckBoxShiftAdmin = ({ checkedOfAdmin }: IProps) => {
  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldsValue }) => {
        const formValues = getFieldsValue(true);

        const isCheckedSame = checkCheckedShiftAdminSameCountShift({ checkedOfAdmin, values: formValues });

        if (isCheckedSame) {
          return (
            <div className="text-error strong" style={{ textAlign: 'right' }}>
              {messageError.checkedShiftAdminSameCountShift}
            </div>
          );
        }

        return null;
      }}
    </Form.Item>
  );
};

export default ErrorCheckBoxShiftAdmin;
