import { Checkbox, Col, Form, Radio, Row, Space } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { CompanyScheduleDay, TaxType, TypePaymentOffset, TypePaymentTerm } from 'common';
import {
  listDateClosingSalary,
  listGender,
  listTypePaymentOffsetWithMonth,
  listPaymentDateInWeek,
  listSalaryType,
  listTypePaymentTerm,
  listWeekDisplay,
  MIN_PERCENT,
  monthPaymentName,
  listTypePaymentOffsetWithDay,
  listTypePaymentOffsetWithWeek,
} from 'common/const';
import { CommonInput } from 'components/CommonInput';
import { CommonInputNumber } from 'components/CommonInputNumber';
import CommonRadioGroup from 'components/CommonRadioGroup';
import { CommonSelect } from 'components/CommonSelect';
import { UploadFile } from 'components/UploadFile';
import { UploadListFile } from 'components/UploadListFile';
import { useRulesForm } from 'hooks/useRulesForm';
import CheckboxPlaceWork from './components/CheckboxPlaceWork';
import classNames from 'classnames';
import { FormInstance } from 'antd/es/form/Form';

interface IProps {
  disable?: boolean;
  form?: FormInstance;
}

export const OtherWorkerFormItem = ({ disable = false, form }: IProps) => {
  const { rulesForm } = useRulesForm();

  const onChangeTypePaymentTerm = (typePaymentTerm: TypePaymentTerm) => {
    if (typePaymentTerm === TypePaymentTerm.EVERY_DAY) {
      form?.setFieldsValue({
        metadata: {
          [monthPaymentName]: TypePaymentOffset.DAY_DEFAULT,
          paymentTerm: undefined,
          paymentDate: undefined,
        },
      });

      return;
    }

    form?.setFieldsValue({
      metadata: {
        [monthPaymentName]: undefined,
        paymentTerm: undefined,
        paymentDate: undefined,
      },
    });
  };

  const onChangePaymentTerm = (paymentTerm: number) => {
    form?.setFieldsValue({
      metadata: {
        [monthPaymentName]: undefined,
        paymentDate: undefined,
      },
    });
  };

  return (
    <>
      <Col span={12}>
        <Form.Item
          label="報酬の形態"
          name={['metadata', 'salaryType']}
          rules={[
            {
              required: true,
              message: '全ての項目を入力してください。',
            },
          ]}
          labelAlign="left"
        >
          <CommonSelect options={listSalaryType} disabled={true} />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item label="性別" name={['metadata', 'gender']} rules={[rulesForm().required]} labelAlign="left">
          <CommonSelect options={listGender} disabled={disable} />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item
          label="安全協力会費"
          name={['metadata', 'safetyAssociationFee']}
          rules={[
            {
              required: !disable,
              message: '全ての項目を入力してください。',
            },
          ]}
          labelAlign="left"
        >
          <CommonInputNumber min={MIN_PERCENT} disabled={disable} />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item
          label="基本報酬額"
          name={['metadata', 'basicSalary']}
          rules={[
            {
              required: !disable,
              message: '全ての項目を入力してください。',
            },
          ]}
          labelAlign="left"
        >
          <CommonInputNumber disabled={disable} min={MIN_PERCENT} />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item label="契約書ファイル" name={['metadata', 'contract']} labelAlign="left">
          <UploadListFile
            multiple={false}
            maxFile={1}
            accept="application/pdf,image/*,application/msword,text/plain"
            disabled={disable}
          />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item
          label="登録番号"
          name={['metadata', 'registrationNumber']}
          rules={rulesForm().registrationNumber}
          labelAlign="left"
          validateFirst
        >
          <CommonInput className="border-none" maxLength={13} prefix="T" disabled={disable} />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item label=" " name={['metadata', 'isTax']} labelAlign="left">
          <CommonRadioGroup disabled={disable}>
            <Radio value={TaxType.INACTIVE}>外税</Radio>
            <Radio value={TaxType.ACTIVE}>内税</Radio>
          </CommonRadioGroup>
        </Form.Item>
      </Col>

      <Col span={6}>
        <Form.Item
          label="締日"
          name={['metadata', 'typePaymentTerm']} // kiểu ngày chốt thanh toán : ngày | tuần | tháng
          rules={[
            {
              required: !disable,
              message: '全ての項目を入力してください。',
            },
          ]}
          labelAlign="left"
        >
          <CommonSelect options={listTypePaymentTerm} disabled={disable} onChange={onChangeTypePaymentTerm} />
        </Form.Item>
      </Col>

      {/* Ngày chốt */}
      <Col span={6}>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const typePaymentTerm: TypePaymentTerm = getFieldValue(['metadata', 'typePaymentTerm']);

            const option = {
              [TypePaymentTerm.EVERY_DAY]: [],
              [TypePaymentTerm.EVERY_WEEK]: listPaymentDateInWeek,
              [TypePaymentTerm.EVERY_MONTH]: listDateClosingSalary,
            };

            if (typePaymentTerm === TypePaymentTerm.EVERY_DAY) return null;

            return (
              <Form.Item
                // label="日"
                label=" "
                name={['metadata', 'paymentTerm']}
                rules={[
                  {
                    required: !disable,
                    message: '全ての項目を入力してください。',
                  },
                ]}
                labelAlign="left"
              >
                <CommonSelect
                  options={typePaymentTerm ? option[typePaymentTerm] : []}
                  disabled={disable}
                  onChange={onChangePaymentTerm}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item label=" " name={['metadata', 'isCreatePaper']} valuePropName="checked" initialValue={true}>
          <Checkbox disabled={disable}>請求書発行の機能</Checkbox>
        </Form.Item>
      </Col>

      <Col span={12}>
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const typePaymentTerm: TypePaymentTerm = getFieldValue(['metadata', 'typePaymentTerm']);

                // const isDisable = typePaymentTerm === TypePaymentTerm.EVERY_DAY || disable;

                const getOptions = () => {
                  if (typePaymentTerm === TypePaymentTerm.EVERY_DAY) {
                    return listTypePaymentOffsetWithDay;
                  }
                  //
                  else if (typePaymentTerm === TypePaymentTerm.EVERY_WEEK) {
                    return listTypePaymentOffsetWithWeek;
                  }

                  return listTypePaymentOffsetWithMonth;
                };

                return (
                  <Form.Item
                    label={<span className={classNames('color-text', 'text-13')}>支払い</span>}
                    name={['metadata', monthPaymentName]}
                    rules={[rulesForm().required]}
                  >
                    <CommonSelect
                      options={getOptions()}
                      disabled={disable}
                      onChange={() => {
                        // reset payment date
                        form?.setFieldsValue({ metadata: { paymentDate: undefined } });
                      }}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const typePaymentTerm: TypePaymentTerm = getFieldValue(['metadata', 'typePaymentTerm']);
                const paymentTerm = getFieldValue(['metadata', 'paymentTerm']);
                const paymentMonthOffset = getFieldValue(['metadata', monthPaymentName]);

                if (typePaymentTerm === TypePaymentTerm.EVERY_DAY) return null;

                const getOptions = () => {
                  if (typePaymentTerm === TypePaymentTerm.EVERY_WEEK) {
                    if (paymentMonthOffset === TypePaymentOffset.THIS_WEEK) {
                      return listPaymentDateInWeek.filter((date) => date.id >= +paymentTerm);
                    }

                    return listPaymentDateInWeek;
                  }

                  if (typePaymentTerm === TypePaymentTerm.EVERY_MONTH) {
                    const isSelectLastDayOfMonth = paymentTerm === listDateClosingSalary[0].id;
                    const isSelectThisMonth = paymentMonthOffset === TypePaymentOffset.THIS_MONTH;

                    if (isSelectThisMonth && isSelectLastDayOfMonth) {
                      return listDateClosingSalary.filter((date) => date.id === 0);
                    }

                    if (isSelectThisMonth && !isSelectLastDayOfMonth) {
                      return [listDateClosingSalary?.[0]].concat(
                        listDateClosingSalary.filter((date) => date.id >= +paymentTerm)
                      );
                    }

                    return listDateClosingSalary;
                  }
                };

                return (
                  <Form.Item label="支払日" name={['metadata', 'paymentDate']} rules={[rulesForm().required]}>
                    <CommonSelect options={getOptions()} disabled={disable} />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Row>
          <Col span={12}>
            <CheckboxPlaceWork disable={disable} form={form} />
          </Col>

          <Col span={12}>
            <Form.Item shouldUpdate>
              {({ getFieldValue }) => {
                const isWorkOffice = getFieldValue(['metadata', 'isWorkOffice']) as boolean;

                // check if is in office
                if (!isWorkOffice) return;

                return (
                  <Row align="middle">
                    {listWeekDisplay.map((el, index) => (
                      <Col flex={1}>
                        <Form.Item
                          valuePropName="checked"
                          label=""
                          key={index}
                          name={['metadata', 'schedule', el?.name]}
                        >
                          <Checkbox disabled={disable}>{el.label}</Checkbox>
                        </Form.Item>
                      </Col>
                    ))}
                  </Row>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Form.Item label="印影" name={['metadata', 'stamp']} labelAlign="left">
          <UploadFile shape="square" disabled={disable} showDelete isCrop isUploadStamp />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item label="メモ" name={['metadata', 'description']}>
          <TextArea autoSize={{ minRows: 6, maxRows: 6 }} className={`inputGrey`} disabled={disable} />
        </Form.Item>
      </Col>
    </>
  );
};
