import { allResource } from 'api/init';
import { loadProfile, profileCompany } from 'api/profile';
import { STATUS, SubscriptionType, UserRole } from 'common';
import PageHeader from 'components/PageHeader';
import SideNav from 'components/SideNav';
import { SideNavProvider, useSideNavContext } from 'context/SideNavContext';
import { handleErrorMessage } from 'helper';
import Cookies from 'js-cookie';
import moment from 'moment';
import React, { lazy, Suspense, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Route, Switch, useHistory } from 'react-router-dom';
import { RoleBasedRouting } from './RoleBasedRouting';
import styles from './styles.module.scss';
import useViewRoutes from 'hooks/useViewRoutes';
import useSyncAccountMultiTabBrowser from 'hooks/useSyncAccountMultiTabBrowser';
import classNames from 'classnames';

import useListCompanyActive from 'hooks/apis/useListCompanyActive';
import SendEmail from 'pages/Mobile/SendEmail';
import { NoteCalendarProvider } from 'context/NoteCalendarContext';
import useScrollWhenPressKey from 'hooks/useScrollWhenPressKey';
import SuperAdminRoutes from './SuperAdminRoutes';
import Loader from 'components/Loader';

const Salary = lazy(() => import('pages/Salary'));
const Payroll = lazy(() => import('pages/Salary/Payroll'));
const Bonus = lazy(() => import('pages/Bonus'));
const WorkerReport = lazy(() => import('pages/WorkerReport'));
// const CalendarManager = lazy(() => import('pages/Calendar'));
const SuperCalendar = lazy(() => import('pages/SuperCalendar'));
const WorksManager = lazy(() => import('pages/Works'));
const ReportRevenue = lazy(() => import('pages/ReportRevenue'));
const Vendor = lazy(() => import('pages/Vendor'));
const DetailVendor = lazy(() => import('pages/Vendor/DetailVendor'));
const Staff = lazy(() => import('pages/Staff'));
const DetailStaff = lazy(() => import('pages/Staff/DetailStaff'));
const Customer = lazy(() => import('pages/Customer'));
const DetailCustomer = lazy(() => import('pages/Customer/DetailCustomer'));
const Permission = lazy(() => import('pages/Permission'));
const MyPage = lazy(() => import('pages/MyPage'));
const AccountBuyMore = lazy(() => import('pages/AccountBuyMore'));
const Account = lazy(() => import('pages/Account'));
const OpenAccount = lazy(() => import('pages/OpenAccount'));
const DetailUser = lazy(() => import('pages/Account/DetailUser'));
const Schedule = lazy(() => import('pages/Schedule'));
const Company = lazy(() => import('pages/Company'));
const Invite = lazy(() => import('pages/Invite'));
const InviteUser = lazy(() => import('pages/Invite/InviteUser'));
const CompanyDetail = lazy(() => import('pages/Company/CompanyDetail'));
const Notification = lazy(() => import('pages/Notification'));
const Paper = lazy(() => import('pages/Paper'));
const CreatePaper = lazy(() => import('pages/Paper/CreatePaper'));
const Payment = lazy(() => import('pages/Paper/Payment'));
const SubscriptionPlan = lazy(() => import('pages/SubscriptionPlan'));
const TimeOff = lazy(() => import('pages/TimeOff'));
const Insurance = lazy(() => import('pages/Insurance'));
const Suspend = lazy(() => import('pages/Suspend'));

const SalaryV2Detail = lazy(() => import('pages/SalaryV2Detail'));
const SalaryV4Export = lazy(() => import('pages/SalaryV4Export'));
const FixedCost = lazy(() => import('pages/FixedCost'));
const SalaryV2Edit = lazy(() => import('pages/SalaryV2Edit'));
const SalaryV4 = lazy(() => import('pages/SalaryV4'));
const SalaryV4Detail = lazy(() => import('pages/SalaryV4Detail'));
const BonusV4 = lazy(() => import('pages/BonusV4'));
const Campaign = lazy(() => import('pages/Campaign'));

const PageWrapper = () => {
  const history = useHistory();
  const roleId = Cookies.get('roleId');

  const isAuthenticated = !!Cookies.get('token') && !!roleId;
  const { data: listResource } = useQuery('resource', () => allResource());
  const { data: profile } = useQuery('profile', loadProfile, { enabled: isAuthenticated });
  const isSuperAdmin = profile?.data?.isSuperAdmin;

  const { isCanViewRevenue } = useViewRoutes();
  const { isCollapsed } = useSideNavContext();

  const currentCompanyId = +Cookies.get('companyId')!;
  const { error: errorDetailCompany, data: detailCompany } = useQuery(
    ['profileCompany'],
    () => profileCompany(currentCompanyId),
    {
      enabled: !!currentCompanyId,
    }
  );

  if (errorDetailCompany) {
    handleErrorMessage(errorDetailCompany);
  }

  useEffect(() => {
    const roleId = detailCompany?.data?.roleId;
    const paymentStatus = detailCompany?.data?.paymentStatus;
    const expiredAt = detailCompany?.data?.expiredAt;
    const subscriptionType = detailCompany?.data?.subscriptionType;

    if (
      roleId === UserRole.ADMIN &&
      (paymentStatus === STATUS.INACTIVE ||
        (expiredAt !== null && moment(expiredAt).isBefore(moment().format('YYYY-MM-DD')))) &&
      subscriptionType === SubscriptionType.STRIPE
    ) {
      history.replace('/subscription-intro');
    }

    if (roleId !== UserRole.ADMIN && paymentStatus === STATUS.INACTIVE) {
      Cookies.remove('companyId');
      history.push('/login-company');
    }
  }, [detailCompany, history]);

  // clear all sessionStorage after refresh page
  useEffect(() => {
    sessionStorage.clear();
  }, []);

  // sync account multi tab browser
  useSyncAccountMultiTabBrowser();

  // fetch list company active
  useListCompanyActive(true);

  // scroll to top when press key (PageUp)
  // scroll to bottom when press key (PageDown)
  useScrollWhenPressKey();

  if (!isAuthenticated) window.location.href = '/landing-page';
  if (!profile) return null;
  if (!detailCompany && Number(roleId) !== UserRole.SUPER_ADMIN) return null;

  return (
    <NoteCalendarProvider>
      <div className={styles.pageWrapper}>
        <SideNav />

        <div
          className={classNames(styles.mainWrapper, {
            [styles.collapsed]: isCollapsed,
          })}
        >
          <PageHeader />
          <div className={styles.pageContent} id="pageWrapper">
            <Suspense fallback={<Loader isLoading />}>
              <Switch>
                <RoleBasedRouting isSuperAdmin={isSuperAdmin} path="/salary" component={Salary} />
                <RoleBasedRouting isSuperAdmin={isSuperAdmin} path="/payroll" component={Payroll} />
                <RoleBasedRouting isSuperAdmin={isSuperAdmin} path="/bonus" component={Bonus} />
                <RoleBasedRouting isSuperAdmin={isSuperAdmin} path="/time-off" component={TimeOff} />
                <RoleBasedRouting
                  isSuperAdmin={isSuperAdmin}
                  path="/calendar/worker-report/:date"
                  component={WorkerReport}
                />
                <RoleBasedRouting isSuperAdmin={isSuperAdmin} path="/calendar" component={SuperCalendar} />
                <RoleBasedRouting
                  isSuperAdmin={isSuperAdmin}
                  path="/mobile/send-email/:taskId/:companyId"
                  component={SendEmail}
                />
                <RoleBasedRouting isSuperAdmin={isSuperAdmin} path="/works" component={WorksManager} />
                {isCanViewRevenue && <Route path="/report-revenue" component={ReportRevenue} />}
                <RoleBasedRouting isSuperAdmin={isSuperAdmin} exact path="/vendor" component={Vendor} />
                <RoleBasedRouting isSuperAdmin={isSuperAdmin} exact path="/vendor/:id" component={DetailVendor} />
                <RoleBasedRouting isSuperAdmin={isSuperAdmin} exact path="/staff" component={Staff} />
                <RoleBasedRouting isSuperAdmin={isSuperAdmin} exact path="/staff/:id" component={DetailStaff} />
                <RoleBasedRouting isSuperAdmin={isSuperAdmin} exact path="/customer" component={Customer} />
                <RoleBasedRouting isSuperAdmin={isSuperAdmin} exact path="/customer/:id" component={DetailCustomer} />
                <RoleBasedRouting isSuperAdmin={isSuperAdmin} path="/permission" component={Permission} />
                <RoleBasedRouting isSuperAdmin={isSuperAdmin} path="/mypage" component={MyPage} />
                <Route path="/buymore" component={AccountBuyMore} />

                <Route path="/invites-user" component={InviteUser} />
                <RoleBasedRouting isSuperAdmin={isSuperAdmin} path="/register-shift" component={Schedule} />
                <RoleBasedRouting isSuperAdmin={isSuperAdmin} path="/paper" component={Paper} />
                <RoleBasedRouting isSuperAdmin={isSuperAdmin} path="/payment" component={Payment} />
                <RoleBasedRouting isSuperAdmin={isSuperAdmin} path="/create-paper" component={CreatePaper} />
                <RoleBasedRouting isSuperAdmin={isSuperAdmin} path="/insurance" component={Insurance} />

                <RoleBasedRouting isSuperAdmin={isSuperAdmin} path="/salary-v2-detail/:id" component={SalaryV2Detail} />
                <RoleBasedRouting isSuperAdmin={isSuperAdmin} path="/salary-v2-edit/:id" component={SalaryV2Edit} />
                <RoleBasedRouting isSuperAdmin={isSuperAdmin} path="/salary-v4-export" component={SalaryV4Export} />

                <RoleBasedRouting isSuperAdmin={isSuperAdmin} path="/salary-v4" component={SalaryV4} />
                <RoleBasedRouting isSuperAdmin={isSuperAdmin} path="/bonus-v4" component={BonusV4} />
                <RoleBasedRouting isSuperAdmin={isSuperAdmin} path="/salary-v4-detail/:id" component={SalaryV4Detail} />
                <Route path="/suspend" component={Suspend} />
                <Route path="/notification" component={Notification} />

                <RoleBasedRouting isSuperAdmin={isSuperAdmin} path="/fixed-cost" component={FixedCost} />

                <SuperAdminRoutes>
                  <Route exact path="/account" component={Account} />
                  <Route exact path="/account/:id" component={DetailUser} />
                  <Route path="/companies" component={Company} />
                  <Route exact path="/company-detail/:id" component={CompanyDetail} />
                  <Route path="/invites" component={Invite} />
                  <Route path="/subscription-plan" component={SubscriptionPlan} />
                  <Route exact path="/open-account" component={OpenAccount} />
                  <Route path="/campaigns" component={Campaign} />
                </SuperAdminRoutes>
              </Switch>
            </Suspense>
          </div>
        </div>
      </div>
    </NoteCalendarProvider>
  );
};

const PageWrapperWithContext = () => {
  return (
    <SideNavProvider>
      <PageWrapper />
    </SideNavProvider>
  );
};

export default PageWrapperWithContext;
