import { Col, Row, Tooltip } from 'antd';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { searchCommonWorker } from 'api/common';
// import { UserRole } from 'common';
import AvatarProfile from 'components/Avatar';
import { CommonModal } from 'components/CommonModal';
import { getWorkerType } from 'helper';
// import useProfile from 'hooks/useProfile';
// import useRoleId from 'hooks/useRoleId';
import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { CommonStatus } from 'common';

interface IProps {
  value?: any[];
  onChange?: Function;
  vendorIds?: number[];
  companyIds?: number[];
  disable?: boolean;
}

export default function WorkerPicker({ value = [], onChange, vendorIds, companyIds, disable, ...rest }: IProps) {
  // const profile = useProfile();
  // const roleId = useRoleId();
  const [options, setOptions] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const fetchWorkers = useCallback(
    _.debounce((keyword: string = '') => {
      searchCommonWorker({
        pageIndex: 1,
        pageSize: 1000000,
        keyword,
        vendorIds: vendorIds?.length ? vendorIds : undefined,
        companyIds: companyIds?.length ? vendorIds : undefined,
        actionStatus: [CommonStatus.ACTIVE],
        isWorkConstructionSite: true,
      }).then((res) => {
        setOptions(res?.data);
      });
    }, 300),
    [vendorIds, companyIds]
  );

  useDeepCompareEffect(() => fetchWorkers(''), [vendorIds, companyIds]);

  const handleSelect = (worker: any) => {
    // const isSelected = value.some((x) => x.id === worker.id);
    // let newValue: any[];
    // if (isChecked) {
    //   newValue = value.filter((x) => x.id !== worker.id);
    // } else {
    //   newValue = _.uniqBy([worker, ...value], 'id');
    // }

    const newValue = value?.map((val) => {
      const isWorkerChange = val.id === worker.id;

      return { ...val, isChecked: isWorkerChange ? !val.isChecked : val.isChecked };
    });

    onChange && onChange(newValue);
  };

  const handleSelectAll = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      onChange && onChange(value?.map((val) => ({ ...val, isChecked: true })));
    } else {
      onChange && onChange && onChange(value?.map((val) => ({ ...val, isChecked: false })));
    }
  };

  // const workerCount = useMemo(() => {
  //   if (roleId === UserRole.VENDOR) return value?.filter((x) => x.managerId === profile.id)?.length;
  //   return value?.length;
  // }, [roleId, value, profile]);

  const numberSelected = useMemo(() => {
    return value?.reduce((acc, cur) => (cur?.isChecked ? acc + 1 : acc), 0);
  }, [value]);

  return (
    <>
      <label className={styles.textSelectedWorker}>
        {/* 選択のスタッフ数 : <span>{workerCount || 0}</span> */}
        選択のスタッフ数 : <span>{numberSelected || 0}</span>
        <a
          onClick={() => {
            !disable && setShowModal(true);
          }}
          className={classNames({ [styles.disabled]: disable })}
        >
          編集
        </a>
      </label>
      <CommonModal
        width={1045}
        isModalVisible={showModal}
        handleClose={() => setShowModal(false)}
        title="スタッフの一覧"
        footer={null}
      >
        {!options?.length ? (
          <p className="text-center">スタッフがいません。</p>
        ) : (
          <div>
            <div className={styles.selectAll}>
              <Checkbox
                onChange={handleSelectAll}
                // checked={value?.length > 0 && value?.length === options?.length}
                // indeterminate={value?.length > 0 && value?.length < options?.length}
                checked={numberSelected > 0 && numberSelected === options?.length}
                indeterminate={numberSelected > 0 && numberSelected < options?.length}
              />
              <label>全選択</label>
            </div>
            <Row gutter={8}>
              {options?.map((item: any) => {
                const vendorName = item.vendorName || item.managerName;
                // const selectedItem = value.find((x) => x.id === item.id);
                const currentWorker = value.find((x) => x.id === item.id);
                const isChecked = currentWorker?.isChecked;

                return (
                  <Col key={item.id} xs={12}>
                    <div className={styles.selectedItem}>
                      <div className={styles.wrapper}>
                        <Checkbox
                          // checked={!!selectedItem}
                          checked={Boolean(isChecked)}
                          style={{ marginRight: 10 }}
                          onChange={() => handleSelect(currentWorker)}
                        />
                        <AvatarProfile src={item.avatar} name={item.name} size={33} />
                        <div className={styles.info}>
                          <div className={styles.name}>
                            <Tooltip title={item?.name}>{item?.name}</Tooltip>
                          </div>
                          <div className={styles.manager}>
                            {vendorName ? `サプライヤー: ${vendorName}` : getWorkerType(item.roleId)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        )}
      </CommonModal>
    </>
  );
}
