const icons = {
  download: {
    gray: require('./icons/download.svg').default,
  },
  file: {
    gray: require('./icons/pin.svg').default,
    black: require('./icons/pin-black.svg').default,
    white: require('./icons/pin-white.svg').default,
  },
  eye: {
    gray: require('./icons/eye.svg').default,
    black: require('./icons/eye-black.svg').default,
  },
  arrow: {
    drag: require('./icons/ic_arrow-drag.png').default,
    left: require('./icons/arrow-left.svg').default,
    right: require('./icons/arrow-right.svg').default,
  },
  block: {
    gray: require('./icons/slash.svg').default,
    black: require('./icons/slash-black.svg').default,
  },
  trash: {
    gray: require('./icons/trash-2.svg').default,
    black: require('./icons/trash-2-black.svg').default,
  },
  pen: {
    gray: require('./icons/edit-3.svg').default,
    black: require('./icons/edit-3-black.svg').default,
  },
  edit: {
    gray: require('./icons/edit.svg').default,
  },
  loudspeaker: {
    white: require('./icons/loudspeaker-white.svg').default,
  },
  plus: {
    outlineGray: require('./icons/add_circle_outline.svg').default,
  },
  unlock: {
    gray: require('./icons/unlock.svg').default,
    black: require('./icons/unlock-black.svg').default,
  },
  userDouble: {
    black: require('./icons/user-double-black.svg').default,
  },
  calendar: {
    gray: require('./icons/calendar-white-2.svg').default,
  },
  yen: {
    outline: {
      black: require('./icons/yen-outline-black.svg').default,
    },
  },
  note: {
    black: require('./icons/note-task.svg').default,
  },
  search: {
    gray: require('./icons/search2.svg').default,
  },
  check: {
    blue: require('./icons/check-blue.svg').default,
  },
  certificate: {
    black: require('./icons/certificate-black.svg').default,
    white: require('./icons/certificate-white.svg').default,
  },
  timekeeping: {
    white: require('./icons/person-check-icon.svg').default,
    gray: require('./icons/person-check-icon-gray.svg').default,
  },
  info: {
    gray: require('./icons/infor-out-line.svg').default,
  },
} as const;

export default icons;
