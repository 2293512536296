import { ICheckedShiftOfAdmin, ICheckedShiftOfCustomer, ICompanyProfile, ITaskDetail } from 'common/interface';
import moment from 'moment';

interface IProps {
  checkedOfAdmin: ICheckedShiftOfAdmin;
  checkedOfCustomer: ICheckedShiftOfCustomer;
  isCustomer?: boolean;
  taskDetail?: ITaskDetail;
  profileCompany: ICompanyProfile;
}

const useGetTimeShiftCustomer = () => {
  const getTimeShiftCustomer = ({
    checkedOfAdmin,
    checkedOfCustomer,
    isCustomer,
    taskDetail,
    profileCompany,
  }: IProps) => {
    if (!taskDetail) return {};

    let timeShiftsOfCustomer = {} as {
      morningFrom?: any;
      morningTo?: any;
      afternoonFrom?: any;
      afternoonTo?: any;
      eveningFrom?: any;
      eveningTo?: any;
      otherFrom?: any;
      otherTo?: any;
    };

    if (checkedOfCustomer.other) {
      const isDifferentCheckedOtherShift = checkedOfAdmin.otherShift !== checkedOfCustomer.other;

      if (isDifferentCheckedOtherShift) {
        const otherFrom = taskDetail?.morningFrom ?? taskDetail?.afternoonFrom ?? taskDetail?.eveningFrom;
        const otherTo = taskDetail?.eveningTo ?? taskDetail?.afternoonTo ?? taskDetail?.morningTo;

        timeShiftsOfCustomer.otherFrom = otherFrom ? moment(otherFrom, 'HH:mm') : '';
        timeShiftsOfCustomer.otherTo = otherTo ? moment(otherTo, 'HH:mm') : '';
      } else {
        timeShiftsOfCustomer.otherFrom = taskDetail.otherFrom ? moment(taskDetail.otherFrom, 'HH:mm') : '';
        timeShiftsOfCustomer.otherTo = taskDetail.otherTo ? moment(taskDetail.otherTo, 'HH:mm') : '';
      }
    }

    // trường hợp : check xóa tất cả các ca phía admin và thêm vào ca other phía admin
    // xử lý : time Form và time To sẽ được fill theo thời gian của ca others.
    const isAdminDeleteAllShiftAndAddOther =
      [checkedOfAdmin.morningShift, checkedOfAdmin.afternoonShift, checkedOfAdmin.eveningShift].every(
        (shift) => !shift
      ) && checkedOfAdmin.otherShift;

    if (isAdminDeleteAllShiftAndAddOther && isCustomer) {
      const isAdminDeleteMorningShift = checkedOfCustomer.morning && !checkedOfAdmin.morningShift;
      const isAdminDeleteAfternoonShift = checkedOfCustomer.afternoon && !checkedOfAdmin.afternoonShift;
      const isAdminDeleteEveningShift = checkedOfCustomer.evening && !checkedOfAdmin.eveningShift;

      // TH checked 1 ca
      const isCheckOneShiftCustomer =
        [checkedOfCustomer.morning, checkedOfCustomer.afternoon, checkedOfCustomer.evening].filter(Boolean).length ===
        1;

      // TH checked 2 ca
      const isCheckTwoShiftCustomer =
        [checkedOfCustomer.morning, checkedOfCustomer.afternoon, checkedOfCustomer.evening].filter(Boolean).length ===
        2;

      // TH checked 3 ca
      const isCheckThreeShiftCustomer =
        [checkedOfCustomer.morning, checkedOfCustomer.afternoon, checkedOfCustomer.evening].filter(Boolean).length ===
        3;

      if (isCheckOneShiftCustomer && isAdminDeleteMorningShift) {
        timeShiftsOfCustomer.morningFrom = taskDetail.otherFrom ? moment(taskDetail.otherFrom, 'HH:mm') : '';
        timeShiftsOfCustomer.morningTo = taskDetail.otherTo ? moment(taskDetail.otherTo, 'HH:mm') : '';

        return timeShiftsOfCustomer;
      }

      if (isCheckOneShiftCustomer && isAdminDeleteAfternoonShift) {
        timeShiftsOfCustomer.afternoonFrom = taskDetail.otherFrom ? moment(taskDetail.otherFrom, 'HH:mm') : '';
        timeShiftsOfCustomer.afternoonTo = taskDetail.otherTo ? moment(taskDetail.otherTo, 'HH:mm') : '';

        return timeShiftsOfCustomer;
      }

      if (isCheckOneShiftCustomer && isAdminDeleteEveningShift) {
        timeShiftsOfCustomer.eveningFrom = taskDetail.otherFrom ? moment(taskDetail.otherFrom, 'HH:mm') : '';
        timeShiftsOfCustomer.eveningTo = taskDetail.otherTo ? moment(taskDetail.otherTo, 'HH:mm') : '';

        return timeShiftsOfCustomer;
      }

      if (isCheckTwoShiftCustomer && isAdminDeleteMorningShift && isAdminDeleteAfternoonShift) {
        timeShiftsOfCustomer.morningFrom = taskDetail.otherFrom ? moment(taskDetail.otherFrom, 'HH:mm') : '';
        timeShiftsOfCustomer.morningTo = profileCompany?.morningTo ? moment(profileCompany?.morningTo, 'HH:mm') : '';
        timeShiftsOfCustomer.afternoonFrom = profileCompany?.afternoonFrom
          ? moment(profileCompany?.afternoonFrom, 'HH:mm')
          : '';
        timeShiftsOfCustomer.afternoonTo = taskDetail.otherTo ? moment(taskDetail.otherTo, 'HH:mm') : '';

        return timeShiftsOfCustomer;
      }

      if (isCheckTwoShiftCustomer && isAdminDeleteMorningShift && isAdminDeleteEveningShift) {
        timeShiftsOfCustomer.morningFrom = taskDetail.otherFrom ? moment(taskDetail.otherFrom, 'HH:mm') : '';
        timeShiftsOfCustomer.morningTo = profileCompany?.morningTo ? moment(profileCompany?.morningTo, 'HH:mm') : '';
        timeShiftsOfCustomer.eveningFrom = profileCompany?.eveningFrom
          ? moment(profileCompany?.eveningFrom, 'HH:mm')
          : '';
        timeShiftsOfCustomer.eveningTo = taskDetail.otherTo ? moment(taskDetail.otherTo, 'HH:mm') : '';

        return timeShiftsOfCustomer;
      }

      if (isCheckTwoShiftCustomer && isAdminDeleteAfternoonShift && isAdminDeleteEveningShift) {
        timeShiftsOfCustomer.afternoonFrom = taskDetail.otherFrom ? moment(taskDetail.otherFrom, 'HH:mm') : '';
        timeShiftsOfCustomer.afternoonTo = profileCompany?.afternoonTo
          ? moment(profileCompany?.afternoonTo, 'HH:mm')
          : '';
        timeShiftsOfCustomer.eveningFrom = profileCompany?.eveningFrom
          ? moment(profileCompany?.eveningFrom, 'HH:mm')
          : '';
        timeShiftsOfCustomer.eveningTo = taskDetail.otherTo ? moment(taskDetail.otherTo, 'HH:mm') : '';

        return timeShiftsOfCustomer;
      }

      if (isCheckThreeShiftCustomer) {
        timeShiftsOfCustomer.morningFrom = taskDetail.otherFrom ? moment(taskDetail.otherFrom, 'HH:mm') : '';
        timeShiftsOfCustomer.morningTo = profileCompany?.morningTo ? moment(profileCompany?.morningTo, 'HH:mm') : '';
        timeShiftsOfCustomer.afternoonFrom = profileCompany?.afternoonFrom
          ? moment(profileCompany?.afternoonFrom, 'HH:mm')
          : '';
        timeShiftsOfCustomer.afternoonTo = profileCompany?.afternoonTo
          ? moment(profileCompany?.afternoonTo, 'HH:mm')
          : '';
        timeShiftsOfCustomer.eveningFrom = profileCompany?.eveningFrom
          ? moment(profileCompany?.eveningFrom, 'HH:mm')
          : '';
        timeShiftsOfCustomer.eveningTo = taskDetail.otherTo ? moment(taskDetail.otherTo, 'HH:mm') : '';

        return timeShiftsOfCustomer;
      }
    }

    return timeShiftsOfCustomer;
  };

  return { getTimeShiftCustomer };
};

export default useGetTimeShiftCustomer;
