import { CommonStatus } from 'common';
import { IWorker } from 'common/interface';
import { capitalize } from 'lodash';
import { Shift } from 'pages/Tasks/AddTaskModal';

interface IProps {
  workers?: IWorker[];
  shift: Shift;
  label: string;
}

const CountWorkShiftDetail = ({ workers, shift, label }: IProps) => {
  const totalCountWork = workers?.reduce((sum: number, worker: IWorker) => {
    const isWorkerInShift = worker?.[`${shift}Shift`] === CommonStatus.ACTIVE;

    const countWork = isWorkerInShift ? worker[`countWork${capitalize(shift)}`] ?? 1 : 0;

    return (sum * 100 + +countWork * 100) / 100; // fix bug floating point number in JS
  }, 0);

  return (
    <div className="strong text-error">
      {label} : {totalCountWork}
    </div>
  );
};

export default CountWorkShiftDetail;
