import { Button, Checkbox, Col, Collapse, Form, Menu, message, Row, Space, Tooltip, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { searchConstructionSiteWorkerV2 } from 'api/common';
import {
  getConstructionSiteDetail,
  getConstructionSiteTaskDetails,
  getListConstructionSiteMultiCompany,
  updateTaskV2,
} from 'api/constructionSite';
import icSearch from 'assets/icons/search2.svg';
import classNames from 'classnames';
import {
  CommonStatus,
  ConstructionSiteState,
  ConstructionSiteType,
  ModeType,
  SHIFT_TASK_SEARCH_CONSTRUCTION,
  STATUS,
  TYPE_INPUT,
  TYPE_PICKER,
  TypeTaskArrangement,
  UpdateTaskMode,
  UserRole,
} from 'common';
import {
  DEFAULT_COUNT_WORK,
  listProjectType,
  listTaskNames,
  messageError,
  ROLES_ADMIN,
  textJP,
  titlesShift,
} from 'common/const';
import { dateUtils } from 'common/dateUtils';
import { ICompanyActive, IConstructionSite, ITask, ITaskDetail, IVendor, IWorker } from 'common/interface';
import { CommonDatepicker } from 'components/CommonDatepicker';
import { CommonInputNumber } from 'components/CommonInputNumber';
import { CommonModal } from 'components/CommonModal';
import { CommonSelect } from 'components/CommonSelect';
import { TextAreaWithAttachment } from 'components/TextAreaWithAttachment';
import { getColorOfCalendar, getFileNameFromUrl, handleErrorMessage, handleMessageSuccess } from 'helper';
import useProfile from 'hooks/useProfile';
import _, { capitalize, debounce, isNil, isUndefined, omit, omitBy, unionBy, uniq } from 'lodash';
import moment, { Moment } from 'moment';
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';
import { useSetState } from 'react-use';
import VendorPicker from '../VendorPicker';
import styles from './styles.module.scss';

import useSelectVendorCalendar from 'hooks/useSelectVendorCalendar';
import queryKeys from 'common/queryKeys';
import useViewTask from 'hooks/useViewTask';
import ItemWorkerSelected from '../components/ItemWorkerSelected';
import { useRulesForm } from 'hooks/useRulesForm';
import DetailsProject from '../components/DetailsProject';
import SectionWrapper from 'components/SectionWrapper';
import LabelRequired from 'components/Form/LabelRequired';
import useGetWidth, { configShowOneColumn } from '../hooks/useGetWidth';
import NoteTaskInput from '../components/NoteTaskInput';
import SelectLeaderTask from '../components/SelectLeaderTask';
import NoteConstruction from '../components/NoteConstruction';
import ErrorCheckBoxShift from '../components/ErrorCheckBoxShift';
import TimeDesire from '../components/TimeDesire';
import {
  checkIsFixPriceConstructionSite,
  getCountWork,
  getDayOffs,
  getDayOffsFromMetadata,
  getInitialCountWorkWorker,
  // getDefaultOtherShiftType,
  // getOtherShiftType,
  getTimesDesireUpdate,
  getUnitPriceWorker,
  getUnitPriceWorkerDefault,
  checkCheckedShiftAdminSameCountShift,
  getUnitPriceCertificateWorkerDefault,
  getUnitPriceCertificate,
} from '../utils';
import DetailsTimeDesire from '../components/DetailsTimeDesire';
import Loader from 'components/Loader';
import TimeTaskPicker, { nameTempTypeDate } from '../components/TimeTaskPicker';
import icCalendarWhite2 from 'assets/icons/calendar-white-2.svg';
import LabelTaskName from '../components/LabelTaskName';
import CustomDropDown from 'components/DropDown/CustomDropDown';
import NameCompanyWithColor from 'pages/Calendar/components/NameCompanyWithColor';
import AccessControlMultiCompany from 'components/AccessControlMultiCompany';
import useGetRoleInCompany from '../hooks/useGetRoleInCompany';
import useProfileCompanySuper from 'hooks/apis/useProfileCompanySuper';
import MaxWorkerShift from '../components/MaxWorkerShift';

import icClose from 'assets/icons/x.svg';
import { getNumberVendorHaveCertificate } from '../TaskDetailModal/utils/getNumberVendorHaveCertificate';
import { useDragWorkersStore } from 'stores/DragWorkersStore';
import { useListDeleteTaskStore } from 'stores/ListDeleteTaskStore';
import useFillDefaultTime from '../hooks/useFillDefaultTime';
import useCheckDataUpdateChanged from '../hooks/useCheckDataUpdateChanged';
import SelectProject, { convertFullNameProject } from '../components/SelectProject';
import useListCompanyActive from 'hooks/apis/useListCompanyActive';
import useListProjectTask from 'hooks/apis/useListProjectTask';
import { createTaskWhenChangeConstructionSite, updateTaskNoProject } from 'api/task';
import useChangeProject from '../hooks/useChangeProject';
import icons from 'assets/icons';

import CommonSpace from 'components/CommonSpace';
import IconDeleteShift from '../components/IconDeleteShift';
import InformationCustomer from '../components/InformationCustomer';
import { CommonInput } from 'components/CommonInput';
import PaymentInformation from '../components/PaymentInformation';
import { getStaff } from 'api/staff';
import { getListVendor } from 'api/vendor';
import useGetTimeShiftCustomer from '../hooks/useGetTimeShiftCustomer';

import useRefetchCalendar from 'hooks/apis/useRefetchCalendar';
import CountWorkShift from '../components/CountWorkShift';
import { getCustomerDetail } from 'api/customer';
import CheckBoxShiftAdmin from '../components/CheckBoxShiftAdmin';
import ErrorCheckBoxShiftAdmin from '../components/ErrorCheckBoxShiftAdmin';
import { getValueTitleOtherShiftCustomer, setValueTitleOtherShiftCustomer } from '../components/PriceShiftCustomer';

const { Panel } = Collapse;

interface IPropsTaskForm {
  constructionSiteId?: number;
  startDate?: Moment;
  taskId?: number;
  handleClose: () => any;
  modeUpdate: number;
  task?: ITask;
  setFilterCalendar?: any;
  defaultCompanySelected?: ICompanyActive;
}

type Shift = 'morning' | 'afternoon' | 'evening' | 'other';

export const UpdateTaskModal = (props: IPropsTaskForm) => {
  const {
    constructionSiteId,
    startDate,
    taskId,
    handleClose,
    modeUpdate,
    task,
    setFilterCalendar,
    defaultCompanySelected,
  } = props;
  const initialFormValues = useRef({});

  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  const profile = useProfile();

  // roles
  const [isCustomer, setIsCustomer] = useState(false);

  const { rulesForm } = useRulesForm();
  const [isChangeConstruction, setIsChangeConstruction] = useState<boolean>(false);

  // admin đã cập nhật thời gian
  const isAdminUpdateAddedTime = () => {
    return Boolean(
      task?.isAdminUpdate &&
        (task?.creatorRoleId === UserRole.CUSTOMER || task?.isCustomer) &&
        (task?.startTime || task?.endTime)
    );
  };

  const [taskDetail, setTaskDetail] = useState<any>();
  const [isLoadingTaskDetail, setIsLoadingTaskDetail] = useState(false);

  const {
    currentRoleInCompany,
    isOnlyVendorOfTask,
    isVendorCustomerOfTask,
    isVendorOfTask,
    companySelected,
    setCompanySelected,
    isOnlyCustomerOfTask,
  } = useGetRoleInCompany({
    taskDetail,
    mode: ModeType.EDIT,
  });

  // get profile of current company
  const { profileCompanySuper: profileCompany } = useProfileCompanySuper({
    companyId: companySelected?.id,
    enabled: true,
  });

  const isAdmin = ROLES_ADMIN.includes(currentRoleInCompany);

  // is task in series
  const isTaskInSeries = !isNil(taskDetail?.groupId);

  // checked phía bên trái của view (có thể nhìn thấy dc)
  const [checked, setChecked] = useSetState({
    morning: false,
    afternoon: false,
    evening: false,
    other: false,
  });

  // checked phía bên phải của view (admin - ngầm ẩn đi)
  const [checkedOfAdmin, setCheckedOfAdmin] = useState({
    morningShift: false,
    afternoonShift: false,
    eveningShift: false,
    otherShift: false,
  });

  // check if check at least one shift : morning, afternoon, evening, other
  const isCheckedAtLeastOneShift = useMemo(
    () =>
      [
        checkedOfAdmin.morningShift,
        checkedOfAdmin.afternoonShift,
        checkedOfAdmin.eveningShift,
        checkedOfAdmin.otherShift,
      ].some(Boolean),
    [checkedOfAdmin]
  );

  // time desire of customer
  const [timeDesire, setTimeDesire] = useSetState({
    startTimeDesire: '',
    endTimeDesire: '',
  });

  // check if have at least one desired time
  const isHaveAtLeastOneDesiredTime = useMemo(
    () => [timeDesire?.startTimeDesire, timeDesire?.endTimeDesire].some(Boolean),
    [timeDesire]
  );

  const handleSelectShift = (e: any, shift: Shift) => {
    switch (shift) {
      case 'morning':
        setChecked((pre) => ({ ...pre, morning: e.target.checked }));
        setCheckedOfAdmin((pre) => ({ ...pre, morningShift: e.target.checked }));
        form.setFieldsValue({
          morningFrom: profileCompany?.morningFrom,
          morningTo: profileCompany?.morningTo,
        });
        // Neu unChecked morningShift
        if (!e.target.checked) {
          form.resetFields(['workerIdsMorning', 'vendorsMorning', 'morningShift', 'workerMorning']);

          removeAllVendorInShift('morning');

          // Neu unChecked morningShift + afternoonShift
          if (!checked.afternoon) {
            const values = form.getFieldsValue(true);
            form.setFieldsValue({ ...values, morning: undefined, workerIdsMorning: undefined, maxWorker: null });
          }
        }

        break;
      case 'afternoon':
        setChecked((pre) => ({ ...pre, afternoon: e.target.checked }));
        setCheckedOfAdmin((pre) => ({ ...pre, afternoonShift: e.target.checked }));
        form.setFieldsValue({
          afternoonFrom: profileCompany?.afternoonFrom,
          afternoonTo: profileCompany?.afternoonTo,
        });

        // Neu unChecked afternoonShift
        if (!e.target.checked) {
          form.resetFields(['workerIdsAfternoon', 'vendorsAfternoon', 'afternoonShift', 'workerAfternoon']);

          removeAllVendorInShift('afternoon');

          // Neu unChecked morningShift + afternoonShift
          if (!checked.morning) {
            const values = form.getFieldsValue(true);
            form.setFieldsValue({
              ...values,
              morning: undefined,
              workerIdsAfternoon: undefined,
              maxWorker: null,
            });
          }
        }

        break;
      case 'evening':
        setChecked((pre) => ({ ...pre, evening: e.target.checked }));
        setCheckedOfAdmin((pre) => ({ ...pre, eveningShift: e.target.checked }));
        form.setFieldsValue({
          eveningFrom: profileCompany?.eveningFrom,
          eveningTo: profileCompany?.eveningTo,
        });
        if (!e.target.checked) {
          form.resetFields(['workerIdsEvening', 'vendorsEvening', 'eveningShift', 'workerEvening']);

          removeAllVendorInShift('evening');

          const values = form.getFieldsValue(true);
          form.setFieldsValue({ ...values, evening: undefined, workerIdsEvening: undefined, maxWorkerEvening: null });
        }

        break;
      case 'other':
        setChecked((pre) => ({ ...pre, other: e.target.checked }));
        setCheckedOfAdmin((pre) => ({ ...pre, otherShift: e.target.checked }));
        if (!e.target.checked) {
          form.resetFields(['workerIdsOther', 'vendorsOther', 'otherShift', 'workerOther', 'workerOther']);

          removeAllVendorInShift('other');

          const values = form.getFieldsValue(true);
          form.setFieldsValue({ ...values, other: undefined, workerIdsOther: undefined, maxWorkerOther: null });
        }

        break;
      default:
        break;
    }
  };

  const { listCompanyActive, numberCompanyUserCanCreateTask } = useListCompanyActive();
  const [constructionSiteFilter, setConstructionSiteFilter] = useSetState({
    pageIndex: 1,
    pageSize: 100,
    keyword: '',
    // status: [ConstructionSiteStatus.ACTIVE],
    state: [ConstructionSiteState.COMING_TO_WORK, ConstructionSiteState.WORKING],
    companyIds: [defaultCompanySelected?.id],
    isAutoCreateTask: CommonStatus.INACTIVE,
  });

  const { data: listConstructionSite } = useQuery(['list_constructionSite__create_task', constructionSiteFilter], () =>
    getListConstructionSiteMultiCompany(constructionSiteFilter)
  );

  const [selectedConstructionSiteId, setSelectedConstructionSiteId] = useState<number>(0);

  useEffect(() => {
    if (constructionSiteId) {
      setSelectedConstructionSiteId(constructionSiteId);
    }
  }, []);

  const resetAndComparePriceOtherShiftCustomer = ({
    constructionSite,
    oldConstructionSiteRef,
  }: {
    constructionSite: IConstructionSite;
    oldConstructionSiteRef: MutableRefObject<IConstructionSite | undefined>;
  }) => {
    if (
      oldConstructionSiteRef.current?.customerId &&
      oldConstructionSiteRef.current?.customerId !== constructionSite?.customerId
    ) {
      form.resetFields(['titleOtherShiftCustomer', 'priceOtherShiftCustomer']);
    }
  };

  const resetAndCompareCertificateWorker = ({
    constructionSite,
    oldConstructionSiteRef,
  }: {
    constructionSite: IConstructionSite;
    oldConstructionSiteRef: MutableRefObject<IConstructionSite | undefined>;
  }) => {
    if (
      oldConstructionSiteRef.current?.customerId &&
      oldConstructionSiteRef.current?.customerId !== constructionSite?.customerId
    ) {
      // reset certificates of worker
      const fieldCertificateWorkerNames = form.getFieldsValue(true)
        ? Object.keys(form.getFieldsValue(true))?.filter((key) => key.startsWith('certificate_worker_'))
        : [];

      form.resetFields(fieldCertificateWorkerNames);
    }
  };

  const { handleChangeProject } = useChangeProject();
  const oldConstructionSiteRef = useRef<IConstructionSite>();
  const {
    data: constructionSiteDetail,
    isLoading: isLoadingConstruction,
    isFetching: isFetchingConstruction,
  } = useQuery(
    ['construction-site-detail', selectedConstructionSiteId, companySelected?.id],
    () =>
      getConstructionSiteDetail(
        selectedConstructionSiteId,
        {
          leader: STATUS.ACTIVE,
        },
        companySelected?.id
      ),
    {
      enabled: !!selectedConstructionSiteId,
      onSuccess(data) {
        /* -------- Compare old construction site with new construction site -------- */
        if (oldConstructionSiteRef.current) {
          handleChangeProject({
            form,
            selectedVendor,
            oldConstructionSiteRef,
            setTaskDetail,
            setSelectedVendor,
            data,
          });

          // compare and reset price other shift customer
          resetAndComparePriceOtherShiftCustomer({ constructionSite: data?.data, oldConstructionSiteRef });

          // compare and reset certificate worker
          resetAndCompareCertificateWorker({ constructionSite: data?.data, oldConstructionSiteRef });
        }

        oldConstructionSiteRef.current = data?.data;
      },
    }
  );

  const certificateOptions =
    constructionSiteDetail?.data.dataUnitPriceCertificateByUserId?.[constructionSiteDetail?.data?.customerId] ?? [];

  const customerIdOfConstructionSite = constructionSiteDetail?.data.customerId;
  const { data: dataCustomerOfConstruction } = useQuery({
    queryKey: [queryKeys.customer.details, customerIdOfConstructionSite, companySelected?.id],
    queryFn: () => getCustomerDetail(customerIdOfConstructionSite as number, companySelected?.id),
    enabled: !isNil(customerIdOfConstructionSite),
  });
  const unitPriceCustomerOptions = dataCustomerOfConstruction?.data?.dataUnitPrice ?? [];
  // const unitPriceWorkerOptions = dataCustomerOfConstruction?.data?.dataUnitPriceCertificate ?? [];

  const [vendor, setVendor] = useSetState({
    vendorMorning: [],
    vendorAfternoon: [],
    vendorEvening: [],
    vendorOther: [],
  });

  // check neu la task le
  const isSingleTaskRef = useRef(false);

  const { handleFillDefaultTime } = useFillDefaultTime();

  const { getTimeShiftCustomer } = useGetTimeShiftCustomer();

  // set default value for form
  useEffect(() => {
    setIsLoadingTaskDetail(true);

    getConstructionSiteTaskDetails(Number(taskId), companySelected?.id)
      .then((res) => {
        const { data: taskDetail }: { data: ITaskDetail } = res;

        setTaskDetail(taskDetail);

        // check if customer create task
        const isCustomerCreateTask = taskDetail?.creatorRoleId === UserRole.CUSTOMER;

        // add customerId to constructionSiteFilter
        if (isCustomerCreateTask) {
          setConstructionSiteFilter((pre) => ({ ...pre, customerId: taskDetail?.creatorId }));
        }

        const dayOffs = getDayOffsFromMetadata(taskDetail);
        const isCustomerOfTask = profile.id === taskDetail?.customerId;

        if (
          [
            UserRole.ADMIN,
            UserRole.CUSTOMER,
            UserRole.LEADER,
            UserRole.OFFICE_MANAGER,
            UserRole.MANAGER,
            UserRole.VENDOR,
          ].includes(currentRoleInCompany) ||
          taskDetail?.creatorRoleId === UserRole.CUSTOMER ||
          taskDetail?.creatorRoleId === UserRole.VENDOR
        ) {
          setIsCustomer(true);
        }

        setSelectedConstructionSiteId(taskDetail.constructionSiteId);

        // save start time desire and end time desire
        setTimeDesire({
          startTimeDesire: taskDetail?.startTimeDesire,
          endTimeDesire: taskDetail?.endTimeDesire,
        });

        const vendorsMorning =
          taskDetail?.vendors
            ?.filter((x: any) => x?.morningShift === 1)
            .map((x: any) => ({
              ...x,
              maxWorker: x?.workerMorning,
              shift: 'morning',
            })) || [];

        const vendorsAfternoon =
          taskDetail?.vendors
            ?.filter((x: any) => x?.afternoonShift === 1)
            .map((x: any) => ({
              ...x,
              maxWorker: x?.workerAfternoon,
              shift: 'afternoon',
            })) || [];

        const vendorsEvening =
          taskDetail?.vendors
            ?.filter((x: any) => x?.eveningShift === 1)
            .map((x: any) => ({
              ...x,
              maxWorker: x?.workerEvening,
              shift: 'evening',
            })) || [];

        const vendorsOther =
          taskDetail?.vendors
            ?.filter((x: any) => x?.otherShift === 1)
            .map((x: any) => ({
              ...x,
              maxWorker: x?.workerOther,
              shift: 'other',
            })) || [];

        const handleDate = (mode: number) => {
          if (mode === UpdateTaskMode.ONE) {
            return {
              startDate: taskDetail?.startDate ? moment(taskDetail.startDate) : '',
              endDate: taskDetail?.startDate ? moment(taskDetail.startDate) : '',
            };
          }
          if (mode === UpdateTaskMode.FUTURE) {
            return {
              startDate: taskDetail?.startDate ? moment(taskDetail.startDate) : '',
              endDate: taskDetail?.seriesTo ? moment(taskDetail.seriesTo) : '',
            };
          }

          if (mode === UpdateTaskMode.ALL) {
            return {
              startDate: taskDetail?.seriesFrom ? moment(taskDetail?.seriesFrom) : '',
              endDate: taskDetail?.seriesTo ? moment(taskDetail?.seriesTo) : '',
            };
          }
        };

        // check if is single task
        const checkIsSingleTask = () => {
          return dateUtils.isSameDate(handleDate(modeUpdate)?.startDate, handleDate(modeUpdate)?.endDate);
        };

        const morningFrom = taskDetail.morningFrom ?? profileCompany?.morningFrom;
        const morningTo = taskDetail.morningTo ?? profileCompany?.morningTo;
        const afternoonFrom = taskDetail.afternoonFrom ?? profileCompany?.afternoonFrom;
        const afternoonTo = taskDetail.afternoonTo ?? profileCompany?.afternoonTo;
        const eveningFrom = taskDetail.eveningFrom ?? profileCompany?.eveningFrom;
        const eveningTo = taskDetail.eveningTo ?? profileCompany?.eveningTo;

        // Certificate for vendor
        const certificatesVendor = taskDetail.vendors?.reduce((result, vendor) => {
          const { id } = vendor;

          const itemCertificateVendor = vendor?.metadataUnitPriceCertificate?.map((certificate) => ({
            workers: certificate.workers,
            certificateId: { value: certificate.id, label: certificate.title },
          }));

          return { ...result, [`certificate_vendor_id_${id}`]: itemCertificateVendor };
        }, {} as any);

        const certificatesVendorRemoveUndefined = omitBy(certificatesVendor, isUndefined);

        // Certificate for customer
        const morning = taskDetail?.metadataCustomerUnitPriceCertificate?.morningShift?.map((certificate) => ({
          workers: certificate.workers,
          certificateId: { value: certificate.id, label: certificate.title },
        }));
        const afternoon = taskDetail?.metadataCustomerUnitPriceCertificate?.afternoonShift?.map((certificate) => ({
          workers: certificate.workers,
          certificateId: { value: certificate.id, label: certificate.title },
        }));
        const evening = taskDetail?.metadataCustomerUnitPriceCertificate?.eveningShift?.map((certificate) => ({
          workers: certificate.workers,
          certificateId: { value: certificate.id, label: certificate.title },
        }));
        const other = taskDetail?.metadataCustomerUnitPriceCertificate?.otherShift?.map((certificate) => ({
          workers: certificate.workers,
          certificateId: { value: certificate.id, label: certificate.title },
        }));

        // const priceOtherWorkers = taskDetail?.workers?.reduce((obj, worker) => {
        //   if (worker.otherShift === CommonStatus.ACTIVE) {
        //     return { ...obj, [`price_other_worker_${worker.id}`]: worker?.price };
        //   }

        //   return obj;
        // }, {} as any);
        const unitPriceWorkerDefault = getUnitPriceWorkerDefault({ taskDetail });

        const countWorkersDefault = taskDetail?.workers?.reduce((obj, worker) => {
          let newObj = {} as any;

          if (worker?.countWorkMorning !== undefined) {
            newObj[`count_work_morning_${worker.id}`] = worker?.countWorkMorning ?? DEFAULT_COUNT_WORK;
          }

          if (worker?.countWorkAfternoon !== undefined) {
            newObj[`count_work_afternoon_${worker.id}`] = worker?.countWorkAfternoon ?? DEFAULT_COUNT_WORK;
          }

          if (worker?.countWorkEvening !== undefined) {
            newObj[`count_work_evening_${worker.id}`] = worker?.countWorkEvening ?? DEFAULT_COUNT_WORK;
          }

          if (worker?.countWorkOther !== undefined) {
            newObj[`count_work_other_${worker.id}`] = worker?.countWorkOther ?? DEFAULT_COUNT_WORK;
          }

          return {
            ...obj,
            ...newObj,
          };
        }, {} as any);

        // const defaultOtherShiftType = getDefaultOtherShiftType({ taskDetail });

        const unitPriceCertificateWorkerDefault = getUnitPriceCertificateWorkerDefault({ taskDetail });

        let formValue = {
          ...taskDetail,
          dayOffs,
          startDate: handleDate(modeUpdate)?.startDate,
          endDate: handleDate(modeUpdate)?.endDate,
          startTime: taskDetail.startTime ? moment(taskDetail.startTime, 'HH:mm') : '',
          endTime: taskDetail.endTime ? moment(taskDetail.endTime, 'HH:mm') : '',

          morningFrom: morningFrom ? moment(morningFrom, 'HH:mm') : '',
          morningTo: morningTo ? moment(morningTo, 'HH:mm') : '',
          afternoonFrom: afternoonFrom ? moment(afternoonFrom, 'HH:mm') : '',
          afternoonTo: afternoonTo ? moment(afternoonTo, 'HH:mm') : '',
          eveningFrom: eveningFrom ? moment(eveningFrom, 'HH:mm') : '',
          eveningTo: eveningTo ? moment(eveningTo, 'HH:mm') : '',
          otherFrom: taskDetail.otherFrom ? moment(taskDetail.otherFrom, 'HH:mm') : '',
          otherTo: taskDetail.otherTo ? moment(taskDetail.otherTo, 'HH:mm') : '',

          workerIds: taskDetail?.workers?.map((x: any) => x.id) || [],
          vendorsMorning: vendorsMorning || [],
          vendorsAfternoon: vendorsAfternoon || [],
          vendorsEvening: vendorsEvening || [],
          vendorsOther: vendorsOther || [],
          workerIdsMorning: taskDetail?.workers?.filter((x: any) => x.morningShift === 1).map((x: any) => x.id) || [],
          workersMorning: taskDetail?.workers?.filter((x: any) => x.morningShift === 1) || [],
          workerIdsAfternoon:
            taskDetail?.workers?.filter((x: any) => x.afternoonShift === 1).map((x: any) => x.id) || [],
          workersAfternoon: taskDetail?.workers?.filter((x: any) => x.afternoonShift === 1) || [],
          workerIdsEvening: taskDetail?.workers?.filter((x: any) => x.eveningShift === 1).map((x: any) => x.id) || [],
          workersEvening: taskDetail?.workers?.filter((x: any) => x.eveningShift === 1) || [],
          workerIdsOther: taskDetail?.workers?.filter((x: any) => x.otherShift === 1).map((x: any) => x.id) || [],
          workersOther: taskDetail?.workers?.filter((x: any) => x.otherShift === 1) || [],
          description: {
            content: taskDetail?.description,
            files: taskDetail?.files,
          },
          leaderId: taskDetail?.leaderTaskId,
          morning,
          evening,
          other,
          afternoon,
          ...certificatesVendorRemoveUndefined,
          ...unitPriceWorkerDefault,
          [nameTempTypeDate]: dateUtils.isSameDate(handleDate(modeUpdate)?.startDate, handleDate(modeUpdate)?.endDate)
            ? TypeTaskArrangement.ONE_DAY
            : TypeTaskArrangement.MULTI_DAYS,
          maxWorkerMorning: taskDetail?.maxWorkerMorning ?? taskDetail?.maxWorker,
          maxWorkerAfternoon: taskDetail?.maxWorkerAfternoon ?? taskDetail?.maxWorker,
          ...countWorkersDefault,
          // ...defaultOtherShiftType,
          ...unitPriceCertificateWorkerDefault,
        };

        if (taskDetail?.titleOtherShiftCustomer) {
          formValue.titleOtherShiftCustomer = setValueTitleOtherShiftCustomer(
            taskDetail?.titleOtherShiftCustomer as string,
            taskDetail.priceOtherShiftCustomer || 0
          );
        }

        isSingleTaskRef.current = checkIsSingleTask();

        // task được tạo bởi worker và admin chưa update thời gian.
        const isTaskCreatedByWorker = taskDetail.startTime === null || taskDetail.startTime === '';

        // Nếu task được tạo bởi worker
        // TH1 : đăng ký 1 ca -> 1 thời gian mong muốn của cusotmer -> fill vào theo customer nếu có || fill theo thời gian làm việc đăng ký của công ty
        // TH2 : đăng ký nhiều ca -> fill theo thời gian làm việc đăng ký của công ty

        if (isTaskCreatedByWorker) {
          handleFillDefaultTime({
            formValue,
            profileCompany,
            startTimeDesire: taskDetail.startTimeDesire,
            endTimeDesire: taskDetail.endTimeDesire,
          });
        }

        const metadataCustomerUpdate = JSON.parse(taskDetail?.metadataCustomerUpdate);

        const morningMetaData = metadataCustomerUpdate?.metadataCustomerUnitPriceCertificate?.morningShift?.map(
          (certificate: any) => ({
            workers: certificate?.workers,
            certificateId: { value: certificate?.id, label: certificate?.title },
          })
        );
        const afternoonMetaData = metadataCustomerUpdate?.metadataCustomerUnitPriceCertificate?.afternoonShift?.map(
          (certificate: any) => ({
            workers: certificate?.workers,
            certificateId: { value: certificate?.id, label: certificate?.title },
          })
        );
        const eveningMetaData = metadataCustomerUpdate?.metadataCustomerUnitPriceCertificate?.eveningShift?.map(
          (certificate: any) => ({
            workers: certificate?.workers,
            certificateId: { value: certificate?.id, label: certificate?.title },
          })
        );
        const otherMetaData = metadataCustomerUpdate?.metadataCustomerUnitPriceCertificate?.otherShift?.map(
          (certificate: any) => ({
            workers: certificate?.workers,
            certificateId: { value: certificate?.id, label: certificate?.title },
          })
        );

        let metadata = {
          ...taskDetail,
          ...metadataCustomerUpdate,
          startDate: moment(metadataCustomerUpdate?.date?.from),
          endDate: moment(metadataCustomerUpdate?.date?.to),
          // startDate: handleDate(modeUpdate)?.startDate,
          // endDate: handleDate(modeUpdate)?.endDate,
          description: {
            content: metadataCustomerUpdate?.description,
            files: metadataCustomerUpdate?.files,
          },
          morningShift: metadataCustomerUpdate?.morningShift,
          afternoonShift: metadataCustomerUpdate?.afternoonShift,
          eveningShift: metadataCustomerUpdate?.eveningShift,
          otherShift: metadataCustomerUpdate?.otherShift,
          maxWorker: metadataCustomerUpdate?.maxWorker,
          maxWorkerEvening: metadataCustomerUpdate?.maxWorkerEvening,
          maxWorkerOther: metadataCustomerUpdate?.maxWorkerOther,
          morning: morningMetaData,
          afternoon: afternoonMetaData,
          evening: eveningMetaData,
          other: otherMetaData,

          // this code to override the value of the form to fix bug when customer update task
          morningFrom: morningFrom ? moment(morningFrom, 'HH:mm') : '',
          morningTo: morningTo ? moment(morningTo, 'HH:mm') : '',
          afternoonFrom: afternoonFrom ? moment(afternoonFrom, 'HH:mm') : '',
          afternoonTo: afternoonTo ? moment(afternoonTo, 'HH:mm') : '',
          eveningFrom: eveningFrom ? moment(eveningFrom, 'HH:mm') : '',
          eveningTo: eveningTo ? moment(eveningTo, 'HH:mm') : '',
          otherFrom: taskDetail.otherFrom ? moment(taskDetail.otherFrom, 'HH:mm') : '',
          otherTo: taskDetail.otherTo ? moment(taskDetail.otherTo, 'HH:mm') : '',
          // this code to override the value of the form to fix bug when customer update task

          // [nameTempTypeDate]: isNil(taskDetail.groupId) ? TypeTaskArrangement.ONE_DAY : TypeTaskArrangement.MULTI_DAYS,
          [nameTempTypeDate]: dateUtils.isSameDate(
            moment(metadataCustomerUpdate?.date?.from),
            moment(metadataCustomerUpdate?.date?.to)
          )
            ? TypeTaskArrangement.ONE_DAY
            : TypeTaskArrangement.MULTI_DAYS,
          // ...defaultOtherShiftType,
        };

        const initialSelectedVendor = [...vendorsMorning, ...vendorsEvening, ...vendorsAfternoon, ...vendorsOther];
        setSelectedVendor(
          initialSelectedVendor.map((vendor: IVendor) => ({
            ...vendor,
            isPicked: Object.keys(certificatesVendorRemoveUndefined).includes(
              String(`certificate_vendor_id_${vendor.id}`)
            ),
          }))
        );

        if (
          // (taskDetail?.creatorRoleId === UserRole.CUSTOMER || taskDetail?.creatorRoleId === UserRole.VENDOR) &&
          !!taskDetail?.metadataCustomerUpdate &&
          !taskDetail?.isAdminUpdate &&
          isCustomerOfTask
        ) {
          const defaultCheckedOfCustomer = {
            morning: Boolean(metadata?.morningShiftCustomer),
            afternoon: Boolean(metadata?.afternoonShiftCustomer),
            evening: Boolean(metadata?.eveningShiftCustomer),
            other: Boolean(metadata?.otherShiftCustomer),
          };

          const defaultCheckedOfAdmin = {
            morningShift: Boolean(metadata?.morningShift),
            afternoonShift: Boolean(metadata?.afternoonShift),
            eveningShift: Boolean(metadata?.eveningShift),
            otherShift: Boolean(metadata?.otherShift),
          };

          // Vì shift của customer có thể khác biệt so với shift của admin nên cần ghi đề timeFrom và timeTo của customer
          if (isVendorCustomerOfTask || isCustomerOfTask) {
            const timeShiftOfCustomer = getTimeShiftCustomer({
              checkedOfAdmin: defaultCheckedOfAdmin,
              checkedOfCustomer: defaultCheckedOfCustomer,
              profileCompany,
              taskDetail,
              isCustomer: true,
            });

            metadata = {
              ...metadata,
              ...timeShiftOfCustomer,
            };
          }

          setChecked(defaultCheckedOfCustomer);
          setCheckedOfAdmin(defaultCheckedOfAdmin);

          initialFormValues.current = metadata;
          form.setFieldsValue(metadata);

          // set default name for task
          setName(metadata?.name);
        } else {
          // setChecked({
          //   morning: taskDetail?.morningShift === STATUS.ACTIVE && profileCompany?.morningShift ? true : false,
          //   afternoon: taskDetail?.afternoonShift === STATUS.ACTIVE && profileCompany?.afternoonShift ? true : false,
          //   evening: taskDetail?.eveningShift === STATUS.ACTIVE && profileCompany?.eveningShift ? true : false,
          //   other: taskDetail?.otherShift === STATUS.ACTIVE ? true : false,
          // });

          const defaultCheckedOfCustomer = {
            morning: Boolean(taskDetail?.morningShiftCustomer),
            afternoon: Boolean(taskDetail?.afternoonShiftCustomer),
            evening: Boolean(taskDetail?.eveningShiftCustomer),
            other: Boolean(taskDetail?.otherShiftCustomer),
          };

          const defaultCheckedOfAdmin = {
            morningShift: Boolean(taskDetail?.morningShift),
            afternoonShift: Boolean(taskDetail?.afternoonShift),
            eveningShift: Boolean(taskDetail?.eveningShift),
            otherShift: Boolean(taskDetail?.otherShift),
          };

          // Vì shift của customer có thể khác biệt so với shift của admin nên cần ghi đề timeFrom và timeTo của customer
          if (isVendorCustomerOfTask || isCustomerOfTask) {
            const timeShiftOfCustomer = getTimeShiftCustomer({
              checkedOfAdmin: defaultCheckedOfAdmin,
              checkedOfCustomer: defaultCheckedOfCustomer,
              profileCompany,
              taskDetail,
              isCustomer: true,
            });

            formValue = {
              ...formValue,
              ...timeShiftOfCustomer,
            };
          }

          setChecked(defaultCheckedOfCustomer);
          setCheckedOfAdmin(defaultCheckedOfAdmin);

          initialFormValues.current = formValue;
          form.setFieldsValue(formValue);

          // set default name for task
          setName(taskDetail?.name);
        }

        setVendor({
          vendorMorning: vendorsMorning,
          vendorAfternoon: vendorsAfternoon,
          vendorEvening: vendorsEvening,
          vendorOther: vendorsOther,
        } as any);

        if (taskDetail?.leaderTaskId) {
          setLeader([
            {
              id: taskDetail?.leaderTaskId,
              phone: taskDetail?.leaderTaskPhone,
              leaderId: taskDetail?.leaderId,
            },
          ]);
        }
      })
      .catch(handleErrorMessage)
      .finally(() => {
        setIsLoadingTaskDetail(false);
      });
  }, [profileCompany?.id]);

  const handleRemoveWorker = (shift: Shift) => (id: number) => {
    const workerIdsMorning = form.getFieldValue('workerIdsMorning');
    const workerIdsAfternoon = form.getFieldValue('workerIdsAfternoon');
    const workerIdsEvening = form.getFieldValue('workerIdsEvening');
    const workerIdsOther = form.getFieldValue('workerIdsOther');

    if (shift === 'morning') {
      form.setFieldsValue({ workerIdsMorning: workerIdsMorning?.filter((x: number) => x != id) });
    }
    if (shift === 'afternoon') {
      form.setFieldsValue({ workerIdsAfternoon: workerIdsAfternoon?.filter((x: number) => x != id) });
    }
    if (shift === 'evening') {
      form.setFieldsValue({ workerIdsEvening: workerIdsEvening?.filter((x: number) => x != id) });
    }
    if (shift === 'other') {
      form.setFieldsValue({ workerIdsOther: workerIdsOther?.filter((x: number) => x != id) });
    }
  };

  const removeAllWorkerInShift = (shift: Shift) => {
    if (shift === 'morning') {
      form.setFieldsValue({ workerIdsMorning: undefined });
    }
    if (shift === 'afternoon') {
      form.setFieldsValue({ workerIdsAfternoon: undefined });
    }
    if (shift === 'evening') {
      form.setFieldsValue({ workerIdsEvening: undefined });
    }
    if (shift === 'other') {
      form.setFieldsValue({ workerIdsOther: undefined });
    }
  };

  const { removeSingleTempTask } = useDragWorkersStore();
  const { resetSelectedDeleteTask } = useListDeleteTaskStore();

  const isSmallTask = !!taskDetail?.isAutoCreateTask;

  // const isFixPriceConstructionSite = useMemo(() => {
  //   return taskDetail?.constructionSiteType === ConstructionSiteType.FIXED_PRICE;
  // }, [taskDetail]);

  const { checkDataUpdateChanged } = useCheckDataUpdateChanged();
  const { refetchCalendar } = useRefetchCalendar();

  // Đối với small task :
  // - Admin được edit tất cả các trường
  // - Ngoài ra vendor còn có thể thêm worker vào ca.
  const handleSubmit = async (values: any) => {
    const isFixPriceConstructionSite = isSmallTask
      ? values?.constructionSite?.type === ConstructionSiteType.FIXED_PRICE
      : taskDetail?.constructionSiteType === ConstructionSiteType.FIXED_PRICE;

    if (isAdminNotSelectShift) {
      handleErrorMessage(messageError.needAtLeastOneShift, true);
      return;
    }

    const isCheckedShiftAdminSameCountShift = checkCheckedShiftAdminSameCountShift({ checkedOfAdmin, values });
    if (isAdmin && isCheckedShiftAdminSameCountShift) {
      handleErrorMessage(messageError.checkedShiftAdminSameCountShift, true);
      return;
    }

    const dayOffs = form.getFieldValue('dayOffs');

    const currentData = { ...values, dayOffs, name };
    const initialData = initialFormValues.current;

    const { hasChanged, differentKeys } = checkDataUpdateChanged({ currentData, initialData });

    // if customer or vendor, check if values changed
    if (isCustomerOfTask || isVendorCustomerOfTask) {
      if (!hasChanged) {
        handleClose();
        return;
      }
    }

    try {
      const selectedVendorIds = uniq(selectedVendor.map((vendor) => vendor?.id));

      // certificate vendor
      const unitPriceVendor = uniq(selectedVendorIds).map((id) => {
        const certificateNumberWorkers = values?.[`certificate_vendor_id_${id}` as any] as any;

        const vendorOtherShift: IVendor & { price?: number } = values?.vendorsOther?.find(
          (vendor: IVendor) => vendor?.id === id
        );

        return {
          userId: id,
          price: vendorOtherShift?.price,
          unitPriceCertificateIds: certificateNumberWorkers?.map(
            (certificate: any) => certificate?.certificateId?.value
          ),
          certificateNumberWorkers: certificateNumberWorkers?.map((certificate: any) => ({
            certificateId: certificate?.certificateId?.value,
            workers: certificate?.workers,
          })),
        };
      });

      // certificate customer
      const unitPriceCustomer = {
        morningShift:
          values?.morning?.map((certificate: any) => ({
            ...certificate,
            certificateId: certificate?.certificateId?.value,
          })) ?? [],
        afternoonShift:
          values?.afternoon?.map((certificate: any) => ({
            ...certificate,
            certificateId: certificate?.certificateId?.value,
          })) ?? [],
        eveningShift:
          values?.evening?.map((certificate: any) => ({
            ...certificate,
            certificateId: certificate?.certificateId?.value,
          })) ?? [],
        otherShift:
          values?.other?.map((certificate: any) => ({
            ...certificate,
            certificateId: certificate?.certificateId?.value,
          })) ?? [],
      };

      // const unitPriceWorker =
      //   values?.workerIdsOther?.map((id: number) => ({
      //     userId: id,
      //     price: values?.[`price_other_worker_${id}`] ? +values?.[`price_other_worker_${id}`] : 0,
      //   })) ?? [];

      setLoading(true);

      const timesDesire = getTimesDesireUpdate(values, taskDetail);
      const dayOffs = getDayOffs(form);

      // neu tu task le -> chuyen thanh task nhieu ngay -> mode = 2
      // others : mode = modeUpdate
      const mode =
        isSingleTaskRef.current && values?.[nameTempTypeDate] === TypeTaskArrangement.MULTI_DAYS
          ? UpdateTaskMode.FUTURE
          : modeUpdate;

      // task được tạo bởi worker và admin chưa update thời gian
      const isTaskCreatedByWorker = taskDetail.startTime === null || taskDetail.startTime === '';

      const { unitPriceWorker } = getUnitPriceWorker({ values });
      const countWork = getCountWork({ values });

      const morningShift = () => {
        const checkedValue = checked.morning ? 1 : 0;

        if (isAdmin) {
          return checkedOfAdmin.morningShift ? 1 : 0;
        }

        // Nếu task được tạo bởi worker -> cho phép tác động vào trường shift cũ
        // Others :  không cho phép tác động vào trường shift cũ
        return isTaskCreatedByWorker ? checkedValue : taskDetail?.morningShift;
      };

      const afternoonShift = () => {
        const checkedValue = checked.afternoon ? 1 : 0;

        if (isAdmin) {
          return checkedOfAdmin.afternoonShift ? 1 : 0;
        }

        // Nếu task được tạo bởi worker -> cho phép tác động vào trường shift cũ
        // Others :  không cho phép tác động vào trường shift cũ
        return isTaskCreatedByWorker ? checkedValue : taskDetail?.afternoonShift;
      };

      const eveningShift = () => {
        const checkedValue = checked.evening ? 1 : 0;

        if (isAdmin) {
          return checkedOfAdmin.eveningShift ? 1 : 0;
        }

        // Nếu task được tạo bởi worker -> cho phép tác động vào trường shift cũ
        // Others :  không cho phép tác động vào trường shift cũ
        return isTaskCreatedByWorker ? checkedValue : taskDetail?.eveningShift;
      };

      const otherShift = () => {
        const checkedValue = checked.other ? 1 : 0;

        if (isAdmin) {
          return checkedOfAdmin.otherShift ? 1 : 0;
        }

        // Nếu task được tạo bởi worker -> cho phép tác động vào trường shift cũ
        // Others :  không cho phép tác động vào trường shift cũ
        return isTaskCreatedByWorker ? checkedValue : taskDetail?.otherShift;
      };

      // const otherShiftType = getOtherShiftType({ values });
      const unitPriceCertificate = getUnitPriceCertificate(values);

      //
      let payload = {
        dayOffs,
        unitPriceCustomer,
        mode: mode,
        name: name || null,
        // maxWorker: isFixPriceConstructionSite ? 0 : values?.maxWorker,
        maxWorker: null,
        maxWorkerMorning: isFixPriceConstructionSite ? 0 : values?.maxWorkerMorning,
        maxWorkerAfternoon: isFixPriceConstructionSite ? 0 : values?.maxWorkerAfternoon,
        maxWorkerEvening: isFixPriceConstructionSite ? 0 : values?.maxWorkerEvening,
        maxWorkerOther: isFixPriceConstructionSite ? 0 : values?.maxWorkerOther,
        noteConstructionSite: values?.noteConstructionSite ?? '',
        date: {
          from: values?.startDate?.format('YYYY-MM-DD'),
          to:
            values?.[nameTempTypeDate] === TypeTaskArrangement.MULTI_DAYS
              ? values?.endDate?.format('YYYY-MM-DD')
              : values?.startDate?.format('YYYY-MM-DD'),
        },

        ...timesDesire,

        vendors: {
          morningShift: values?.vendorsMorning?.map((x: any) => ({ userId: x.id, maxWorker: x.maxWorker || 0 })) || [],
          afternoonShift:
            values?.vendorsAfternoon?.map((x: any) => ({ userId: x.id, maxWorker: x.maxWorker || 0 })) || [],
          eveningShift: values?.vendorsEvening?.map((x: any) => ({ userId: x.id, maxWorker: x.maxWorker || 0 })) || [],
          otherShift: values?.vendorsOther?.map((x: any) => ({ userId: x.id, maxWorker: x.maxWorker || 0 })) || [],
          unitPrice: unitPriceVendor,
        },
        workers: {
          morningShift: values?.workerIdsMorning || [],
          afternoonShift: values?.workerIdsAfternoon || [],
          eveningShift: values?.workerIdsEvening || [],
          otherShift: values?.workerIdsOther || [],
          unitPrice: unitPriceWorker,
          countWork,
          unitPriceCertificate,
        },
        // morningShift: values?.morningShift ? 1 : 0,
        // afternoonShift: values?.afternoonShift ? 1 : 0,
        // eveningShift: values?.eveningShift ? 1 : 0,
        // otherShift: values?.otherShift ? 1 : 0,
        morningFrom:
          typeof values?.morningFrom === 'object' ? values?.morningFrom?.format('HH:mm') : values?.morningFrom || null,
        morningTo:
          typeof values?.morningTo === 'object' ? values?.morningTo?.format('HH:mm') : values?.morningTo || null,
        afternoonFrom:
          typeof values?.afternoonFrom === 'object'
            ? values?.afternoonFrom?.format('HH:mm')
            : values?.afternoonFrom || null,
        afternoonTo:
          typeof values?.afternoonTo === 'object' ? values?.afternoonTo?.format('HH:mm') : values?.afternoonTo || null,
        eveningFrom:
          typeof values?.eveningFrom === 'object' ? values?.eveningFrom?.format('HH:mm') : values?.eveningFrom || null,
        eveningTo:
          typeof values?.eveningTo === 'object' ? values?.eveningTo?.format('HH:mm') : values?.eveningTo || null,
        otherFrom: values?.otherFrom ? values?.otherFrom?.format('HH:mm') : null,
        otherTo: values?.otherTo ? values?.otherTo?.format('HH:mm') : null,
        workerMorning: values?.workerMorning || 0,
        workerAfternoon: values?.workerAfternoon || 0,
        workerEvening: values?.workerEvening || 0,
        workerOther: values?.workerOther || 0,
        note: values?.note,
        leaderId: values?.leaderId ? values?.leaderId : null,
        description: values?.description?.content ?? '',
        files: values?.description?.files?.map((x: any) => ({ name: x.name, url: getFileNameFromUrl(x.url) })) || [],

        // shifts
        morningShiftCustomer: values?.morningShiftCustomer ? 1 : 0,
        afternoonShiftCustomer: values?.afternoonShiftCustomer ? 1 : 0,
        eveningShiftCustomer: values?.eveningShiftCustomer ? 1 : 0,
        otherShiftCustomer: values?.otherShiftCustomer ? 1 : 0,

        morningShift: morningShift(),
        afternoonShift: afternoonShift(),
        eveningShift: eveningShift(),
        otherShift: otherShift(),
        // otherShiftType,
        priceOtherShiftCustomer: values?.priceOtherShiftCustomer ?? null,
        titleOtherShiftCustomer: getValueTitleOtherShiftCustomer(values?.titleOtherShiftCustomer),
      };

      // not send noti when update only one of these fields
      if (isAdmin) {
        const { differentKeys } = checkDataUpdateChanged({ currentData, initialData });
        const fieldsNotSendNoti = ['note', 'workerAfternoon', 'workerEvening', 'workerMorning', 'workerOther'];
        const isNotSendNoti = differentKeys.every((key) => fieldsNotSendNoti.includes(key));

        if (isNotSendNoti) {
          (payload as any).isUpdateNoNotiStaff = true;
        }
      }

      // if change construction site --> use new api to create task
      let result = null;

      //
      if (isSmallTask) {
        // remove constructionSiteId
        (payload as any) = omit(payload, ['constructionSiteId']);

        if (isAdmin) {
          // add thêm data vào payload
          (payload as any).customer = {
            ...values?.customer,
            nightUnitPrice: values?.customer?.nightUnitPrice ?? null,
            companyName: values?.customer?.companyName?.trim() || null,
          };
          (payload as any).constructionSite = values?.constructionSite;
        }
        // các roles còn lại
        else {
          // lấy data cũ từ taskDetail truyền lên
          (payload as any).customer = {
            ...taskDetail?.customer,
            nightUnitPrice: taskDetail?.customer?.nightUnitPrice ?? null,
            companyName: taskDetail?.customer?.companyName ?? null,
          };
          (payload as any).constructionSite = taskDetail?.constructionSite;
        }

        result = await updateTaskNoProject({
          params: payload,
          taskId: +taskId!,
          companyId: companySelected?.id!,
        });
      } else if (isChangeConstruction) {
        result = await createTaskWhenChangeConstructionSite({
          taskId: +taskId!,
          payload: {
            ...payload,
            constructionSiteId: selectedConstructionSiteId,
          },
          companyId: companySelected?.id,
        });
      } else {
        // update task
        result = await updateTaskV2({ id: +taskId!, payload, companyId: companySelected?.id });
      }

      const newIdTask = result?.data?.[0]?.id ?? result?.data?.[0];

      if (!isNil(newIdTask)) {
        setFilterCalendar?.((pre: any) => ({ ...pre, taskId: newIdTask }));
      }

      /* ------------ Remove temporary worker and selected delete task ------------ */
      removeSingleTempTask({ taskId: taskId as number });
      resetSelectedDeleteTask();

      /* --------------------- Refetch apis after update task --------------------- */
      handleClose();
      handleMessageSuccess();

      // refetch calendar
      refetchCalendar();
    } catch (e) {
      handleErrorMessage(e);
    } finally {
      setLoading(false);
    }
  };

  const [listWorker, setListWorker] = useState();

  // list staff of company
  const { data: dataWorkers } = useQuery({
    queryKey: [queryKeys.staff.listStaff, companySelected?.id],
    queryFn: () =>
      getStaff({
        filters: {
          pageIndex: 1,
          pageSize: 10000000,
          roleIds: isVendorOfTask
            ? [UserRole.VENDOR_WORKER]
            : [
                UserRole.ADMIN,
                UserRole.MANAGER,
                UserRole.OFFICE_MANAGER,
                UserRole.LEADER,
                UserRole.VENDOR_WORKER,
                UserRole.PART_TIME_WORKER,
                UserRole.OTHER_WORKER,
                UserRole.WORKER,
              ],
          status: [CommonStatus.ACTIVE],
        },
        companyId: companySelected?.id,
      }),
    enabled: isSmallTask,
  });

  const listWorkerOfCompany =
    dataWorkers?.data?.map((worker) => {
      const additionalParams = isVendorOfTask ? { roleId: worker?.userCompany?.roleId } : {};

      return { ...worker, ...additionalParams };
    }) ?? [];

  const handleSearchWorker = _.debounce((keyword: string, shift: Shift) => {
    let vendorArr: any = [];
    let startTime: any;
    let endTime: any;
    let shiftConstructionSite: any;

    if (shift === 'morning') {
      vendorArr = form.getFieldValue('vendorsMorning');
      startTime = form.getFieldValue('morningFrom');
      endTime = form.getFieldValue('morningTo');
      shiftConstructionSite = SHIFT_TASK_SEARCH_CONSTRUCTION.MORNING;
    }
    if (shift === 'afternoon') {
      vendorArr = form.getFieldValue('vendorsAfternoon');
      startTime = form.getFieldValue('afternoonFrom');
      endTime = form.getFieldValue('afternoonTo');
      shiftConstructionSite = SHIFT_TASK_SEARCH_CONSTRUCTION.AFTERNOON;
    }
    if (shift === 'evening') {
      vendorArr = form.getFieldValue('vendorsEvening');
      startTime = form.getFieldValue('eveningFrom');
      endTime = form.getFieldValue('eveningTo');
      shiftConstructionSite = SHIFT_TASK_SEARCH_CONSTRUCTION.EVENING;
    }
    if (shift === 'other') {
      vendorArr = form.getFieldValue('vendorsOther');
      startTime = form.getFieldValue('otherFrom');
      endTime = form.getFieldValue('otherTo');
      shiftConstructionSite = SHIFT_TASK_SEARCH_CONSTRUCTION.OTHER;
    }

    const vendorIds = vendorArr ? vendorArr?.map((el: any) => el.id) : [];

    if (isSmallTask) {
      getStaff({
        filters: {
          pageIndex: 1,
          pageSize: 10000000,
          roleIds: isVendorOfTask
            ? [UserRole.VENDOR_WORKER]
            : [
                UserRole.ADMIN,
                UserRole.MANAGER,
                UserRole.OFFICE_MANAGER,
                UserRole.LEADER,
                UserRole.VENDOR_WORKER,
                UserRole.PART_TIME_WORKER,
                UserRole.OTHER_WORKER,
                UserRole.WORKER,
              ],
          status: [CommonStatus.ACTIVE],
          keyword,
        },
        companyId: companySelected?.id,
      }).then((res) => {
        setListWorker(res?.data as any);
      });
    } else {
      searchConstructionSiteWorkerV2(
        {
          keyword,
          constructionSiteId: form.getFieldValue('constructionSiteId'),
          workingDay: form.getFieldValue('startDate')?.format('YYYY-MM-DD'),
          startTime,
          endTime,
          shift: shiftConstructionSite,
          pageSize: 10000000,
          roleIds: isVendorOfTask
            ? [UserRole.VENDOR_WORKER]
            : [
                UserRole.WORKER,
                UserRole.OTHER_WORKER,
                UserRole.PART_TIME_WORKER,
                UserRole.ADMIN,
                UserRole.OFFICE_MANAGER,
                UserRole.MANAGER,
              ],
          vendorIds,
          leaderId: leader?.leaderId,
        },
        companySelected?.id
      ).then((res) => {
        setListWorker(res?.data);
      });
    }
  }, 300);

  const handleChangeStartDate = (e: any) => {
    form.setFieldsValue({
      endDate: e,
    });
  };

  const [leader, setLeader] = useState<any>();
  const handleSelectLeader = (id: number) => {
    setLeader(constructionSiteDetail?.data?.workers?.filter((x: any) => x?.id === id));
  };
  const handleClearLeader = () => {
    setLeader(undefined);
  };

  const joinedVendors = useMemo(() => {
    return constructionSiteDetail?.data?.vendors?.filter((x: any) => x.csmStatus === STATUS.ACTIVE) || [];
  }, [constructionSiteDetail]);

  const joinedVendorsActive = useMemo(
    () => joinedVendors.filter((vendor: any) => vendor?.isActive === STATUS.ACTIVE),
    [joinedVendors]
  );

  const [filtersVendor, setFilersVendor] = useState({
    pageSize: 20,
    keyword: '',
    pageIndex: 1,
  });
  // list vendor of company
  const {
    data: dataVendors,
    hasNextPage: hasNextPageVendor,
    fetchNextPage: fetchNextPageVendor,
  } = useInfiniteQuery({
    queryKey: [queryKeys.vendors.listVendorByCompany, filtersVendor.keyword, filtersVendor.pageIndex],
    queryFn: ({ pageParam = 1 }) => {
      return getListVendor({ ...filtersVendor, pageIndex: pageParam });
    },
    getNextPageParam: (lastPage, pages) => {
      const { pageIndex, totalPages } = lastPage;

      if (pageIndex >= totalPages) {
        return false;
      }

      const nextPage = pageIndex + 1;

      return nextPage;
    },
    keepPreviousData: true,
    enabled: isSmallTask && isAdmin,
  });

  const listVendor = dataVendors?.pages?.map((page) => page?.data)?.reduce((arr, data) => [...arr, ...data], []) || [];
  const handleScrollVendor = (e: any) => {
    const target: any = e.target;

    const threshold = 5;
    if (target?.scrollTop + target?.clientHeight + threshold >= target?.scrollHeight && hasNextPageVendor) {
      fetchNextPageVendor();
    }
  };
  const handleSearchVendor = debounce((keyword: string) => {
    setFilersVendor({ ...filtersVendor, keyword, pageIndex: 1 });
  }, 500);
  const handleOnBlurVendor = () => {
    setFilersVendor({ ...filtersVendor, keyword: '', pageIndex: 1 });
  };
  const propsOfSelectVendor = isSmallTask
    ? {
        onPopupScroll: handleScrollVendor,
        onSearch: handleSearchVendor,
        filterOption: false,
        onBlur: handleOnBlurVendor,
      }
    : {};

  const isWorker = [UserRole.WORKER, UserRole.VENDOR_WORKER, UserRole.OTHER_WORKER, UserRole.PART_TIME_WORKER].includes(
    currentRoleInCompany
  );

  const isCustomerOfTask = profile.id === taskDetail?.customerId;

  const isFilledTask =
    taskDetail?.morningFrom ||
    taskDetail?.morningTo ||
    taskDetail?.afternoonFrom ||
    taskDetail?.afternoonTo ||
    taskDetail?.eveningFrom ||
    taskDetail?.eveningTo ||
    taskDetail?.otherFrom ||
    taskDetail?.otherTo;

  const disableDatePicker = () => {
    // const disableRoles = ADMIN_ROLE.includes(currentRoleInCompany);

    if (isCustomerOfTask || isVendorCustomerOfTask) {
      return !isCustomerOfTask;
    }

    return isWorker || (isCustomerOfTask && isFilledTask) || isOnlyVendorOfTask;
  };

  const {
    uniqueSelectedVendor,
    setSelectedVendor,
    handlePickSelectedVendor,
    selectedVendor,
    removeAllVendorInShift,
    handleSelectedVendor,
  } = useSelectVendorCalendar({ form });

  const isCanViewCertificateCustomer = () => {
    // vendor_customer : Chỉ hiển thị khi nó là customer của task
    if (isVendorCustomerOfTask) {
      return true;
    }

    // Customer : Chỉ hiển thị với customer của task
    if (isCustomerOfTask) {
      return true;
    }

    if ([UserRole.ADMIN, UserRole.LEADER, UserRole.OFFICE_MANAGER, UserRole.MANAGER].includes(currentRoleInCompany)) {
      return true;
    }

    return false;
  };

  const isDisableFormAddCertificate = () => {
    // truong hop la admin | office manager | manager
    if ([UserRole.ADMIN, UserRole.OFFICE_MANAGER, UserRole.MANAGER].includes(currentRoleInCompany)) {
      return false;
    }
    // vendor_customer | customer
    if (isVendorCustomerOfTask || isCustomerOfTask) {
      return (
        isAdminUpdateAddedTime() ||
        getColorOfCalendar(taskDetail).isPurpleColor ||
        getColorOfCalendar(taskDetail).isBlackColor
      );
    }

    return isAdminUpdateAddedTime() || !isVendorCustomerOfTask;
  };

  const isDisableTime = () => {
    //  3 & isCustomer & task color red -> disable
    if ([UpdateTaskMode.FUTURE].includes(modeUpdate) && isCustomerOfTask && getColorOfCalendar(taskDetail).isRedColor) {
      return true;
    }

    // Task series and isCustomer & task color red & task series ->

    if (
      [UpdateTaskMode.ONE].includes(modeUpdate) &&
      isCustomerOfTask &&
      getColorOfCalendar(taskDetail).isRedColor &&
      isTaskInSeries
    ) {
      return true;
    }

    // this code from old cold base
    return isWorker || (isCustomerOfTask && isFilledTask) || isOnlyVendorOfTask;
  };

  const isHideIconAddDateExcludeTask = () => {
    const { isPurpleColor, isBlackColor } = getColorOfCalendar(taskDetail);

    if (isVendorCustomerOfTask) {
      return Boolean(isPurpleColor) || Boolean(isBlackColor);
    }

    return false;
  };

  const isDisableNoteConstructionSite = () => {
    if (isCustomerOfTask || (isVendorCustomerOfTask && isCustomerOfTask)) return false;

    return isWorker || (isCustomerOfTask && isFilledTask) || isOnlyVendorOfTask;
  };

  const isShowTimeDesireText = [UserRole.ADMIN, UserRole.LEADER, UserRole.OFFICE_MANAGER, UserRole.MANAGER].includes(
    currentRoleInCompany
  );

  const isDisableMaxWorker =
    isWorker || (isCustomerOfTask && isFilledTask) || isOnlyVendorOfTask || isDisableFormAddCertificate();

  const renderTimeDesire = () => {
    if (!isCustomer) return null;

    if (isShowTimeDesireText) {
      return (
        <Row gutter={[40, 20]} className="mb-10">
          <Col span={8} className="fontsize-14">
            希望時間
          </Col>

          <Col span={16}>
            <DetailsTimeDesire taskDetail={taskDetail} />
          </Col>
        </Row>
      );
    }
  };

  const { isCanViewTime, isCanViewOtherField, isCanViewPriceCustomerOtherShift } = useViewTask({
    vendor,
    taskDetail,
    isVendorCustomerOfTask,
    isCustomerOfTask,
  });

  const isConstructionCompletedOrPause = [ConstructionSiteState.COMPLETED, ConstructionSiteState.PAUSE].includes(
    constructionSiteDetail?.data?.state
  );

  const { spanCol1, spanCol2, widthModal } = useGetWidth({
    checked,
    task: taskDetail,
    companySelected,
    isOnlyCustomerOfTask,
  });

  const [name, setName] = useState<string>('');

  const menu = (
    <Menu
      multiple
      selectable
      onSelect={({ item, key }) => {
        setName(key as string);
      }}
      onDeselect={({ item, key }) => {
        setName('');
      }}
      selectedKeys={[name]}
    >
      {listTaskNames.map((item) => (
        <Menu.Item key={item.name} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {item.name}
          {name === item.name && <img src={icClose} alt="close" />}
        </Menu.Item>
      ))}
    </Menu>
  );

  const handleSearchConstructionSite = useCallback(
    _.debounce(
      (keyword: string) => setConstructionSiteFilter({ keyword, isAutoCreateTask: CommonStatus.INACTIVE }),
      300
    ),
    []
  );

  const { getListProjectTask } = useListProjectTask();
  const handleSelectConstructionSite = (id: number) => {
    setIsChangeConstruction(true);

    const listProject = getListProjectTask();

    const projectSelected = listProject?.find((project) => project.id === id);
    const newCompanySelected = listCompanyActive?.find((company) => company.id === projectSelected?.companyId);

    setCompanySelected(newCompanySelected);
    setSelectedConstructionSiteId(id);

    // // reset form
    // resetFormWhenChangeConstructionSite();

    // // if have dataNoteToTask not reset
    // if (!dataNoteToTask) {
    //   resetCheckedAllShift();
    // }

    setConstructionSiteFilter((pre) => ({ ...pre, keyword: '' }));
  };

  // check liệu task này admin có thể update shift của customer không
  const isTaskAdminCanUpdateCustomerShift =
    getColorOfCalendar(taskDetail).isPurpleColor ||
    getColorOfCalendar(taskDetail).isBlackColor ||
    getColorOfCalendar(taskDetail).isRedColor;

  // Check xem có hiển thị panel của shift nào không
  // Admin lấy theo shift
  // Customer lấy theo shiftCustomer
  const isShowPanelMorning = isAdmin ? !!checkedOfAdmin.morningShift : !!checked.morning;
  const isShowPanelAfternoon = isAdmin ? !!checkedOfAdmin.afternoonShift : !!checked.afternoon;
  const isShowPanelEvening = isAdmin ? !!checkedOfAdmin.eveningShift : !!checked.evening;
  const isShowPanelOther = isAdmin ? !!checkedOfAdmin.otherShift : !!checked.other;

  // Check xem liệu admin chưa chọn shift nào
  const isAdminNotSelectShift =
    isAdmin && [isShowPanelMorning, isShowPanelAfternoon, isShowPanelEvening, isShowPanelOther].every((x) => !x);

  // Check xem có hiển thị button tạo shift other không
  const isShowButtonCreateOtherShift = isTaskAdminCanUpdateCustomerShift && isAdmin && !checkedOfAdmin.otherShift;

  //
  const handleDeletePanel = (shift: Shift) => {
    setCheckedOfAdmin((pre) => ({ ...pre, [`${shift}Shift`]: false }));

    // reset number worker
    const nameWorkerField = `worker${capitalize(shift)}`;
    form.resetFields([nameWorkerField]);

    removeAllVendorInShift(shift);
    removeAllWorkerInShift(shift);
  };

  //
  const handleCreateOtherShift = () => {
    // Update state delete panel
    setCheckedOfAdmin((pre) => ({ ...pre, otherShift: true }));
  };

  const propsOfMaxWorkerShift = isSmallTask
    ? {
        spanMaxWorker: 24,
        isHideAddCertificate: true,
      }
    : {};

  const handleFillDefaultCountWorkWorker = ({ shift, workerId }: { shift: Shift; workerId: number }) => {
    const isFixPriceConstructionSite = checkIsFixPriceConstructionSite({
      constructionSiteDetail: constructionSiteDetail?.data,
      isSmallTask,
      values: form.getFieldsValue(true),
    });

    const initialCountWork = getInitialCountWorkWorker({
      values: form.getFieldsValue(true),
      checked,
      checkedOfAdmin,
      shift,
      isFixPriceConstructionSite,
    });

    const nameFromCountWork = `count_work_${shift}_${workerId}`;

    form.setFieldsValue({
      [nameFromCountWork]: initialCountWork,
    });
  };

  return (
    <CommonModal
      width={isLoadingTaskDetail ? configShowOneColumn.widthModal : widthModal}
      handleClose={handleClose}
      isModalVisible
      title={
        <div>
          <NameCompanyWithColor companySelected={companySelected} taskDetail={taskDetail} />
          <Space align="center" style={{ justifyContent: 'space-between', display: 'flex', marginTop: '10px' }}>
            <div>手配の詳細</div>

            <div className="d-flex justify-content-center">
              <Button
                style={{ marginRight: 5, width: 120 }}
                htmlType="button"
                className="btn-outline"
                onClick={handleClose}
              >
                キャンセル
              </Button>

              <Button
                style={{ marginLeft: 5, width: 120 }}
                htmlType="submit"
                type="primary"
                disabled={loading}
                onClick={() => {
                  form.validateFields().then((values) => {
                    handleSubmit(values);
                  });
                }}
              >
                {'保存 '}
              </Button>
            </div>
          </Space>
        </div>
      }
      footer={null}
      maskClosable={false}
      className={styles.modal}
    >
      <Form layout="vertical" form={form} scrollToFirstError onFinish={handleSubmit} className="fixed-header-form">
        <Loader isLoading={isLoadingTaskDetail || isFetchingConstruction}>
          <Row gutter={[20, 20]}>
            <Col span={spanCol1}>
              <div className={styles.colWrap}>
                {isSmallTask ? (
                  <InformationCustomer className="mb-10" disableAll={!isAdmin} />
                ) : (
                  <SectionWrapper className="mb-10">
                    {isConstructionCompletedOrPause ? (
                      <p className="heading-14">プロジェクト名: {constructionSiteDetail?.data?.name}</p>
                    ) : (
                      <>
                        <LabelRequired label="プロジェクト" />

                        <Form.Item
                          name="constructionSiteId"
                          rules={[rulesForm().required]}
                          labelAlign="left"
                          className="mb-10"
                        >
                          {/* <CommonSelect
                              filterOption={false}
                              options={[
                                ...(listConstructionSite?.data || []),
                                {
                                  id: selectedConstructionSiteId,
                                  name: convertFullNameProject(constructionSiteDetail?.data as any),
                                },
                              ]}
                              disabled={true}
                            /> */}

                          <SelectProject
                            onSearch={handleSearchConstructionSite}
                            onChange={handleSelectConstructionSite}
                            filters={constructionSiteFilter}
                            companySelected={companySelected}
                            defaultValue={constructionSiteDetail?.data}
                            disabled={!isAdmin}
                            numberCompanyUserCanCreateTask={numberCompanyUserCanCreateTask}
                          />
                        </Form.Item>

                        <DetailsProject details={constructionSiteDetail} roleInCompany={2} className="mb-10" />
                      </>
                    )}

                    <NoteConstruction disabled={isDisableNoteConstructionSite()} />

                    {constructionSiteDetail?.data?.files?.length > 0 && (
                      <Space direction="vertical" size={1} className="mt-10">
                        {constructionSiteDetail?.data?.files.map((file: any, index: number) => (
                          <Typography.Link target="_blank" href={file.url} key={file?.id || index}>
                            {file?.name}
                          </Typography.Link>
                        ))}
                      </Space>
                    )}
                  </SectionWrapper>
                )}

                <SectionWrapper>
                  <Space size="small" align="center" className="mb-10">
                    <img src={icCalendarWhite2} alt="" />

                    <div className="strong">手配情報</div>
                  </Space>

                  {isSmallTask && (
                    <>
                      <Form.Item
                        label={textJP.projectName}
                        rules={[rulesForm().required]}
                        className="mb-10"
                        required
                        name={['constructionSite', 'name']}
                      >
                        <CommonInput disabled={!isAdmin} />
                      </Form.Item>
                      <Form.Item
                        label={textJP.typeOfArrangement}
                        rules={[rulesForm().required]}
                        className="mb-10"
                        required
                        name={['constructionSite', 'type']}
                      >
                        <CommonSelect options={listProjectType} showSearch={false} disabled={!isAdmin} />
                      </Form.Item>
                    </>
                  )}

                  <TimeTaskPicker
                    disableStartDate={isDisableTime()}
                    disableEndDate={isDisableTime()}
                    form={form}
                    hideIconAdd={isHideIconAddDateExcludeTask()}
                    disableCheckBoxMultiDays={isTaskInSeries && modeUpdate === UpdateTaskMode.ONE}
                  />

                  {isCanViewCertificateCustomer() && (
                    <>
                      <Space
                        size="small"
                        align="center"
                        style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}
                      >
                        <LabelRequired label="プロジェクト時間" className="mr-10" />

                        <Space align="center">
                          {profileCompany?.morningShift === STATUS.ACTIVE && (
                            <Form.Item
                              className="mb-0"
                              name={'morningShiftCustomer'}
                              rules={[rulesForm().validateCheckBoxShiftTaskV2()]}
                              valuePropName="checked"
                              noStyle
                              dependencies={['afternoonShift', 'eveningShift', 'otherShift']}
                            >
                              <Checkbox
                                disabled={
                                  isWorker ||
                                  (isCustomerOfTask && isFilledTask) ||
                                  isOnlyVendorOfTask ||
                                  isDisableFormAddCertificate()
                                }
                                onChange={(e: any) => handleSelectShift(e, 'morning')}
                                className="strong"
                              >
                                午前
                              </Checkbox>
                            </Form.Item>
                          )}

                          {profileCompany?.afternoonShift === STATUS.ACTIVE && (
                            <Form.Item
                              className="mb-0"
                              name={'afternoonShiftCustomer'}
                              rules={[rulesForm().validateCheckBoxShiftTaskV2()]}
                              valuePropName="checked"
                              noStyle
                              dependencies={['afternoonShiftCustomer', 'eveningShiftCustomer', 'otherShiftCustomer']}
                            >
                              <Checkbox
                                disabled={
                                  isWorker ||
                                  (isCustomerOfTask && isFilledTask) ||
                                  isOnlyVendorOfTask ||
                                  isDisableFormAddCertificate()
                                }
                                onChange={(e: any) => handleSelectShift(e, 'afternoon')}
                                className="strong"
                              >
                                午後
                              </Checkbox>
                            </Form.Item>
                          )}

                          {profileCompany?.eveningShift === STATUS.ACTIVE && (
                            <Form.Item
                              className="mb-0"
                              name={'eveningShiftCustomer'}
                              rules={[rulesForm().validateCheckBoxShiftTaskV2()]}
                              valuePropName="checked"
                              noStyle
                              dependencies={['afternoonShiftCustomer', 'eveningShiftCustomer', 'otherShiftCustomer']}
                            >
                              <Checkbox
                                disabled={
                                  isWorker ||
                                  (isCustomerOfTask && isFilledTask) ||
                                  isOnlyVendorOfTask ||
                                  isDisableFormAddCertificate()
                                }
                                onChange={(e: any) => handleSelectShift(e, 'evening')}
                                className="strong"
                              >
                                夜間
                              </Checkbox>
                            </Form.Item>
                          )}

                          <Form.Item
                            className="mb-0"
                            name={'otherShiftCustomer'}
                            rules={[rulesForm().validateCheckBoxShiftTaskV2()]}
                            valuePropName="checked"
                            noStyle
                            dependencies={['afternoonShiftCustomer', 'eveningShiftCustomer', 'otherShiftCustomer']}
                          >
                            <Checkbox
                              disabled={
                                isWorker ||
                                (isCustomerOfTask && isFilledTask) ||
                                isOnlyVendorOfTask ||
                                isDisableFormAddCertificate()
                              }
                              onChange={(e: any) => handleSelectShift(e, 'other')}
                              className="strong"
                            >
                              オプション
                            </Checkbox>
                          </Form.Item>
                        </Space>
                      </Space>

                      {/* Remove because now 3 roles admin can edit time desire */}
                      {/* {renderTimeDesire()} */}

                      <AccessControlMultiCompany
                        accessibleRoles={[UserRole.CUSTOMER, UserRole.VENDOR, ...ROLES_ADMIN]}
                        hidden={isAdmin && !isHaveAtLeastOneDesiredTime}
                        roleInCompany={currentRoleInCompany}
                      >
                        {isCheckedAtLeastOneShift && (
                          <Row className="mb-10" gutter={[8, 8]}>
                            <TimeDesire
                              startTimeDesireName="startTimeDesire"
                              endTimeDesireName="endTimeDesire"
                              disabled={disableDatePicker()}
                            />
                          </Row>
                        )}
                      </AccessControlMultiCompany>

                      {/* Hide maxWorker + chứng chỉ khi ConstructionSiteType = FIXED_PRICE*/}
                      {(taskDetail as ITaskDetail)?.constructionSiteType !== ConstructionSiteType.FIXED_PRICE &&
                        (checked.afternoon || checked.morning || checked.evening || checked.other) && (
                          <div className="color-red">人工</div>
                        )}

                      <ErrorCheckBoxShift
                        nameCheckBoxMorningShift="morningShiftCustomer"
                        nameCheckBoxAfternoonShift="afternoonShiftCustomer"
                        nameCheckBoxEveningShift="eveningShiftCustomer"
                        nameCheckBoxOtherShift="otherShiftCustomer"
                      />

                      <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) => {
                          // data of small task
                          const typeOfConstruction = getFieldValue(['constructionSite', 'type']);
                          const isFixPriceSmallTask = typeOfConstruction === ConstructionSiteType.FIXED_PRICE;

                          // data of normal task
                          const isFixPriceNormalTask =
                            taskDetail?.constructionSiteType === ConstructionSiteType.FIXED_PRICE;

                          const isFixPriceConstructionSite = isSmallTask ? isFixPriceSmallTask : isFixPriceNormalTask;

                          if (isFixPriceConstructionSite) return null;

                          return (
                            <Row
                              gutter={[5, 5]}
                              // className={classNames({
                              //   [styles.wrapperMaxWorker]: isSmallTask,
                              // })}
                            >
                              <MaxWorkerShift
                                typeShift="morning"
                                form={form}
                                currentRoleInCompany={currentRoleInCompany}
                                selectedConstructionSiteId={selectedConstructionSiteId}
                                certificateOptions={certificateOptions}
                                labelMaxWorker={titlesShift.morningShift}
                                disabledMaxWorker={isDisableMaxWorker}
                                disableAddCertificate={isDisableFormAddCertificate()}
                                nameCheckBox="morningShiftCustomer"
                                {...propsOfMaxWorkerShift}
                              />

                              <MaxWorkerShift
                                typeShift="afternoon"
                                form={form}
                                currentRoleInCompany={currentRoleInCompany}
                                selectedConstructionSiteId={selectedConstructionSiteId}
                                certificateOptions={certificateOptions}
                                labelMaxWorker={titlesShift.afternoonShift}
                                disabledMaxWorker={isDisableMaxWorker}
                                disableAddCertificate={isDisableFormAddCertificate()}
                                nameCheckBox="afternoonShiftCustomer"
                                {...propsOfMaxWorkerShift}
                              />

                              <MaxWorkerShift
                                typeShift="evening"
                                form={form}
                                currentRoleInCompany={currentRoleInCompany}
                                selectedConstructionSiteId={selectedConstructionSiteId}
                                certificateOptions={certificateOptions}
                                labelMaxWorker={titlesShift.eveningShift}
                                disabledMaxWorker={isDisableMaxWorker}
                                disableAddCertificate={isDisableFormAddCertificate()}
                                nameCheckBox="eveningShiftCustomer"
                                {...propsOfMaxWorkerShift}
                              />

                              <MaxWorkerShift
                                typeShift="other"
                                form={form}
                                currentRoleInCompany={currentRoleInCompany}
                                selectedConstructionSiteId={selectedConstructionSiteId}
                                certificateOptions={certificateOptions}
                                labelMaxWorker={titlesShift.otherShift}
                                disabledMaxWorker={isDisableMaxWorker}
                                disableAddCertificate={isDisableFormAddCertificate()}
                                nameCheckBox="otherShiftCustomer"
                                unitPriceCustomerOptions={isSmallTask ? [] : unitPriceCustomerOptions}
                                isCanViewPriceOfCustomer={isCanViewPriceCustomerOtherShift()}
                                {...propsOfMaxWorkerShift}
                              />
                              {/* 
                        {!checkTaskSeparatedShift(taskDetail) && (
                          <div className="strong text-error">
                            システムは「人工」の値を分割しました。再度チェックしてください。
                          </div>
                        )} */}
                            </Row>
                          );
                        }}
                      </Form.Item>
                    </>
                  )}

                  <Form.Item label="仕事の内容" name="description" labelAlign="left" className="mt-20 mb-10">
                    <TextAreaWithAttachment disabled={isWorker || isOnlyVendorOfTask} autoSize={{ minRows: 3 }} />
                  </Form.Item>

                  <AccessControlMultiCompany
                    accessibleRoles={ROLES_ADMIN}
                    roleInCompany={currentRoleInCompany}
                    hidden={!isSmallTask}
                  >
                    <NoteTaskInput className="mb-10" />
                  </AccessControlMultiCompany>

                  {!isSmallTask && (
                    <Tooltip title="手配名" placement="topLeft">
                      <CustomDropDown
                        overlay={menu}
                        trigger={['click']}
                        disabled={isWorker || isOnlyVendorOfTask}
                        className="mb-10"
                      >
                        <div className="cursorPointer">
                          <LabelTaskName label={name} />
                        </div>
                      </CustomDropDown>
                    </Tooltip>
                  )}

                  <AccessControlMultiCompany accessibleRoles={ROLES_ADMIN} roleInCompany={currentRoleInCompany}>
                    <SelectLeaderTask
                      constructionSiteDetail={constructionSiteDetail}
                      listStaffOfCompany={dataWorkers?.data}
                      form={form}
                      setLeader={setLeader}
                      onSelect={handleSelectLeader}
                      onClear={handleClearLeader}
                      isSmallTask={isSmallTask}
                      classFormItem="mb-0"
                    />

                    {leader && <div className="mt-10">電話番号：{leader[0]?.phone}</div>}
                  </AccessControlMultiCompany>
                </SectionWrapper>

                <AccessControlMultiCompany
                  accessibleRoles={ROLES_ADMIN}
                  roleInCompany={currentRoleInCompany}
                  hidden={isSmallTask}
                >
                  <SectionWrapper className="mt-10 mb-0">
                    <NoteTaskInput />
                  </SectionWrapper>
                </AccessControlMultiCompany>

                {isSmallTask && (
                  <AccessControlMultiCompany roleInCompany={currentRoleInCompany} accessibleRoles={ROLES_ADMIN}>
                    <PaymentInformation className="mt-10" />
                  </AccessControlMultiCompany>
                )}
              </div>
            </Col>

            <Col span={spanCol2}>
              <div className={styles.colWrap}>
                <AccessControlMultiCompany
                  accessibleRoles={[
                    UserRole.ADMIN,
                    UserRole.MANAGER,
                    UserRole.OFFICE_MANAGER,
                    UserRole.LEADER,
                    UserRole.VENDOR,
                    UserRole.CUSTOMER,
                  ]}
                  roleInCompany={currentRoleInCompany}
                >
                  <AccessControlMultiCompany accessibleRoles={ROLES_ADMIN} roleInCompany={currentRoleInCompany}>
                    <CommonSpace className="mb-10" align="start" justify="space-between" widthFull>
                      <LabelRequired label="プロジェクト時間" />

                      <div className="text-right">
                        <Space align="center">
                          {(isSmallTask ? true : profileCompany?.morningShift === STATUS.ACTIVE) && (
                            <CheckBoxShiftAdmin
                              checkBoxText={textJP.shift.morningShift}
                              nameCountShift="otherShiftTypeMorning"
                              checked={checkedOfAdmin.morningShift}
                              onChange={(e) => {
                                const isChecked = e.target.checked;

                                if (isChecked) {
                                  setCheckedOfAdmin((pre) => ({ ...pre, morningShift: isChecked }));
                                }

                                if (!isChecked) {
                                  handleDeletePanel('morning');
                                }
                              }}
                            />
                          )}

                          {(isSmallTask ? true : profileCompany?.afternoonShift === STATUS.ACTIVE) && (
                            <CheckBoxShiftAdmin
                              checkBoxText={textJP.shift.afternoonShift}
                              nameCountShift="otherShiftTypeAfternoon"
                              checked={checkedOfAdmin.afternoonShift}
                              onChange={(e) => {
                                const isChecked = e.target.checked;

                                if (isChecked) {
                                  setCheckedOfAdmin((pre) => ({ ...pre, afternoonShift: isChecked }));
                                }

                                if (!isChecked) {
                                  handleDeletePanel('afternoon');
                                }
                              }}
                            />
                          )}

                          {(isSmallTask ? true : profileCompany?.eveningShift === STATUS.ACTIVE) && (
                            <CheckBoxShiftAdmin
                              checkBoxText={textJP.shift.eveningShift}
                              nameCountShift="otherShiftTypeEvening"
                              checked={checkedOfAdmin.eveningShift}
                              onChange={(e) => {
                                const isChecked = e.target.checked;

                                if (isChecked) {
                                  setCheckedOfAdmin((pre) => ({ ...pre, eveningShift: isChecked }));
                                }

                                if (!isChecked) {
                                  handleDeletePanel('evening');
                                }
                              }}
                            />
                          )}

                          <CheckBoxShiftAdmin
                            checkBoxText={textJP.shift.otherShift}
                            checked={checkedOfAdmin.otherShift}
                            onChange={(e) => {
                              const isChecked = e.target.checked;

                              if (isChecked) {
                                setCheckedOfAdmin((pre) => ({ ...pre, otherShift: isChecked }));
                              }

                              if (!isChecked) {
                                handleDeletePanel('other');
                              }
                            }}
                          />
                        </Space>

                        {isAdminNotSelectShift && (
                          <div className="text-error strong">{messageError.needAtLeastOneShift}</div>
                        )}

                        <ErrorCheckBoxShiftAdmin checkedOfAdmin={checkedOfAdmin} />
                      </div>
                    </CommonSpace>
                  </AccessControlMultiCompany>

                  {/* Total work output of workers in shifts */}
                  <AccessControlMultiCompany accessibleRoles={ROLES_ADMIN} roleInCompany={currentRoleInCompany}>
                    <Row gutter={[20, 20]} className="mb-10" align="middle">
                      <Col span={12} className="strong text-error">
                        {textJP.label.numberWorker}
                      </Col>

                      <Col span={12}>
                        <Row>
                          {checkedOfAdmin.morningShift && (
                            <Col span={12}>
                              <CountWorkShift label={textJP.shift.morningShift} shift="morning" />
                            </Col>
                          )}

                          {checkedOfAdmin.afternoonShift && (
                            <Col span={12} className="strong text-error">
                              <CountWorkShift label={textJP.shift.afternoonShift} shift="afternoon" />
                            </Col>
                          )}

                          {checkedOfAdmin.eveningShift && (
                            <Col span={12} className="strong text-error">
                              <CountWorkShift label={textJP.shift.eveningShift} shift="evening" />
                            </Col>
                          )}

                          {checkedOfAdmin.otherShift && (
                            <Col span={12} className="strong text-error">
                              <CountWorkShift label={textJP.shift.otherShift} shift="other" />
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </AccessControlMultiCompany>

                  {/* Morning shift */}
                  {isCanViewTime(isShowPanelMorning, 'morningShift') ? (
                    <Collapse
                      expandIconPosition="right"
                      className={styles.accordionShift}
                      defaultActiveKey={['morningShift', 'afternoonShift', 'eveningShift', 'otherShift']}
                    >
                      <Panel
                        key="morningShift"
                        header="午前"
                        extra={
                          <AccessControlMultiCompany accessibleRoles={ROLES_ADMIN} roleInCompany={currentRoleInCompany}>
                            <IconDeleteShift
                              onClick={(e) => {
                                e.stopPropagation();

                                handleDeletePanel('morning');
                              }}
                            />
                          </AccessControlMultiCompany>
                        }
                        // style={{ display: isCanViewTime(isShowPanelMorning, 'morningShift') ? '' : 'none' }}
                      >
                        <Row gutter={8}>
                          {isCanViewTime(isShowPanelMorning, 'morningShift') && (
                            <>
                              <Col xs={12}>
                                <Form.Item
                                  label="開始時刻"
                                  name="morningFrom"
                                  rules={[
                                    {
                                      required: true,
                                      message: '全ての項目を入力してください。',
                                    },
                                  ]}
                                  labelAlign="left"
                                >
                                  <CommonDatepicker
                                    picker={TYPE_PICKER.TIME}
                                    allowClear={false}
                                    disabled={[UserRole.VENDOR, UserRole.CUSTOMER].includes(currentRoleInCompany)}
                                    minuteStep={5}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={12}>
                                <Form.Item
                                  label="終了時刻"
                                  name="morningTo"
                                  rules={[
                                    {
                                      required: true,
                                      message: '全ての項目を入力してください。',
                                    },
                                  ]}
                                  labelAlign="left"
                                >
                                  <CommonDatepicker
                                    picker={TYPE_PICKER.TIME}
                                    allowClear={false}
                                    disabled={[UserRole.VENDOR, UserRole.CUSTOMER].includes(currentRoleInCompany)}
                                    minuteStep={5}
                                  />
                                </Form.Item>
                              </Col>
                            </>
                          )}

                          {isCanViewOtherField.morningShift && (
                            <>
                              {![UserRole.VENDOR, UserRole.CUSTOMER].includes(currentRoleInCompany) && (
                                <Col span={12}>
                                  <Form.Item
                                    label={textJP.label.numberPeople}
                                    name={'workerMorning'}
                                    rules={[
                                      {
                                        required: true,
                                        message: '全ての項目を入力してください。',
                                      },
                                    ]}
                                    labelAlign="left"
                                  >
                                    <CommonInputNumber min={0} isOnlyInteger />
                                  </Form.Item>
                                </Col>
                              )}

                              <AccessControlMultiCompany
                                accessibleRoles={[
                                  UserRole.ADMIN,
                                  UserRole.MANAGER,
                                  UserRole.OFFICE_MANAGER,
                                  UserRole.LEADER,
                                  UserRole.VENDOR,
                                ]}
                                roleInCompany={currentRoleInCompany}
                              >
                                <Col span={24}>
                                  <Loader isLoading={isLoadingConstruction}>
                                    <Form.Item label="サプライヤー" name="vendorsMorning" labelAlign="left">
                                      <VendorPicker
                                        options={isSmallTask ? listVendor : joinedVendorsActive}
                                        disabled={isVendorOfTask}
                                        form={form}
                                        handleSelectedVendor={(props) => {
                                          handleSelectedVendor({ ...props });

                                          if (isSmallTask) {
                                            handleOnBlurVendor();
                                          }
                                        }}
                                        shift="morning"
                                        {...propsOfSelectVendor}
                                      />
                                    </Form.Item>
                                  </Loader>
                                </Col>
                                <Col span={24}>
                                  <Form.Item
                                    label="スタッフ"
                                    name="workerIdsMorning"
                                    labelAlign="left"
                                    className={styles.workerSelectList}
                                  >
                                    <CommonSelect
                                      optionLabel={'name'}
                                      filterOption={false}
                                      onSearch={(e: any) => handleSearchWorker(e, 'morning')}
                                      onFocus={() => handleSearchWorker('', 'morning')}
                                      options={listWorker || []}
                                      mode={TYPE_INPUT.MULTIPLE}
                                      suffixIcon={<img src={icSearch} style={{ margin: -12 }} alt="" />}
                                      onSelect={(workerId: number) =>
                                        handleFillDefaultCountWorkWorker({ shift: 'morning', workerId })
                                      }
                                    />
                                  </Form.Item>

                                  <Loader isLoading={isLoadingConstruction}>
                                    <Form.Item
                                      shouldUpdate={(prev, next) => prev.workerIdsMorning !== next.workerIdsMorning}
                                      noStyle
                                    >
                                      {() => {
                                        const allWorkers = isSmallTask
                                          ? listWorkerOfCompany
                                          : constructionSiteDetail?.data?.workers || [];
                                        const allIdsWorker = allWorkers?.map((worker) => worker?.id);

                                        const selectedWorkerIds = form.getFieldValue('workerIdsMorning') as number[];

                                        const workersMorning = form.getFieldValue('workersMorning') || [];

                                        const selectedWorker = unionBy([...allWorkers, ...workersMorning], 'id')
                                          ?.filter((x: any) => selectedWorkerIds?.includes(x.id))
                                          ?.map((worker) => {
                                            const isKickOut = !allIdsWorker.includes(worker?.id);

                                            return { ...worker, isKickOut };
                                          });

                                        return (
                                          <Row className={styles.workerList} gutter={[8, 8]}>
                                            {selectedWorker?.map((worker: any) => {
                                              const isLeaderOfWorker =
                                                worker?.managerId === profile?.id || worker?.userCompany?.managerId;

                                              return (
                                                <Col
                                                  span={24}
                                                  key={worker.id}
                                                  className={classNames({
                                                    [styles.disabled]: isVendorOfTask && !isLeaderOfWorker,
                                                  })}
                                                >
                                                  <ItemWorkerSelected
                                                    worker={worker}
                                                    handleRemoveWorker={handleRemoveWorker('morning')}
                                                    shift="morning"
                                                    roleId={currentRoleInCompany}
                                                    // companyId={companySelected?.id}
                                                    isSmallTask={isSmallTask}
                                                    options={isSmallTask ? [] : certificateOptions}
                                                    form={form}
                                                  />
                                                </Col>
                                              );
                                            })}
                                          </Row>
                                        );
                                      }}
                                    </Form.Item>
                                  </Loader>
                                </Col>
                              </AccessControlMultiCompany>
                            </>
                          )}
                        </Row>
                      </Panel>
                    </Collapse>
                  ) : null}

                  {/* Afternoon shift */}
                  {isCanViewTime(isShowPanelAfternoon, 'afternoonShift') ? (
                    <Collapse
                      expandIconPosition="right"
                      className={styles.accordionShift}
                      defaultActiveKey={['morningShift', 'afternoonShift', 'eveningShift', 'otherShift']}
                    >
                      <Panel
                        key="afternoonShift"
                        header="午後"
                        // style={{
                        //   display: isCanViewTime(isShowPanelAfternoon, 'afternoonShift') ? '' : 'none',
                        // }}
                        extra={
                          <AccessControlMultiCompany accessibleRoles={ROLES_ADMIN} roleInCompany={currentRoleInCompany}>
                            <IconDeleteShift
                              onClick={(e) => {
                                e.stopPropagation();

                                handleDeletePanel('afternoon');
                              }}
                            />
                          </AccessControlMultiCompany>
                        }
                      >
                        <Row gutter={8}>
                          {isCanViewTime(isShowPanelAfternoon, 'afternoonShift') && (
                            <>
                              <Col xs={12}>
                                <Form.Item
                                  label="開始時刻"
                                  name="afternoonFrom"
                                  rules={[
                                    {
                                      required: true,
                                      message: '全ての項目を入力してください。',
                                    },
                                  ]}
                                  labelAlign="left"
                                >
                                  <CommonDatepicker
                                    picker={TYPE_PICKER.TIME}
                                    allowClear={false}
                                    disabled={[UserRole.VENDOR, UserRole.CUSTOMER].includes(currentRoleInCompany)}
                                    minuteStep={5}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={12}>
                                <Form.Item
                                  label="終了時刻"
                                  name="afternoonTo"
                                  rules={[
                                    {
                                      required: true,
                                      message: '全ての項目を入力してください。',
                                    },
                                  ]}
                                  labelAlign="left"
                                >
                                  <CommonDatepicker
                                    picker={TYPE_PICKER.TIME}
                                    allowClear={false}
                                    disabled={[UserRole.VENDOR, UserRole.CUSTOMER].includes(currentRoleInCompany)}
                                    minuteStep={5}
                                  />
                                </Form.Item>
                              </Col>
                            </>
                          )}

                          {isCanViewOtherField.afternoonShift && (
                            <>
                              {![UserRole.VENDOR, UserRole.CUSTOMER].includes(currentRoleInCompany) && (
                                <Col span={12}>
                                  <Form.Item
                                    label={textJP.label.numberPeople}
                                    name={'workerAfternoon'}
                                    rules={[
                                      {
                                        required: true,
                                        message: '全ての項目を入力してください。',
                                      },
                                    ]}
                                    labelAlign="left"
                                  >
                                    <CommonInputNumber min={0} isOnlyInteger />
                                  </Form.Item>
                                </Col>
                              )}
                              <AccessControlMultiCompany
                                accessibleRoles={[
                                  UserRole.ADMIN,
                                  UserRole.MANAGER,
                                  UserRole.OFFICE_MANAGER,
                                  UserRole.LEADER,
                                  UserRole.VENDOR,
                                ]}
                                roleInCompany={currentRoleInCompany}
                              >
                                <Col span={24}>
                                  <Loader isLoading={isLoadingConstruction}>
                                    <Form.Item label="サプライヤー" name="vendorsAfternoon" labelAlign="left">
                                      <VendorPicker
                                        options={isSmallTask ? listVendor : joinedVendorsActive}
                                        disabled={isVendorOfTask}
                                        form={form}
                                        handleSelectedVendor={(props) => {
                                          handleSelectedVendor({ ...props });

                                          if (isSmallTask) {
                                            handleOnBlurVendor();
                                          }
                                        }}
                                        shift="afternoon"
                                        {...propsOfSelectVendor}
                                      />
                                    </Form.Item>
                                  </Loader>
                                </Col>
                                <Col span={24}>
                                  <Form.Item
                                    label="スタッフ"
                                    name="workerIdsAfternoon"
                                    labelAlign="left"
                                    className={styles.workerSelectList}
                                  >
                                    <CommonSelect
                                      optionLabel={'name'}
                                      filterOption={false}
                                      onSearch={(e: any) => handleSearchWorker(e, 'afternoon')}
                                      onFocus={() => handleSearchWorker('', 'afternoon')}
                                      options={listWorker || []}
                                      mode={TYPE_INPUT.MULTIPLE}
                                      suffixIcon={<img src={icSearch} style={{ margin: -12 }} alt="" />}
                                      onSelect={(workerId: number) =>
                                        handleFillDefaultCountWorkWorker({ shift: 'afternoon', workerId })
                                      }
                                    />
                                  </Form.Item>

                                  <Loader isLoading={isLoadingConstruction}>
                                    <Form.Item
                                      shouldUpdate={(prev, next) => prev.workerIdsAfternoon !== next.workerIdsAfternoon}
                                      noStyle
                                    >
                                      {() => {
                                        const allWorkers = isSmallTask
                                          ? listWorkerOfCompany
                                          : constructionSiteDetail?.data?.workers || [];
                                        const allIdsWorker = allWorkers?.map((worker) => worker?.id);

                                        const selectedWorkerIds = form.getFieldValue('workerIdsAfternoon') as number[];

                                        const workersAfternoon = form.getFieldValue('workersAfternoon') || [];

                                        const selectedWorker = unionBy([...allWorkers, ...workersAfternoon], 'id')
                                          ?.filter((x: any) => selectedWorkerIds?.includes(x.id))
                                          ?.map((worker) => {
                                            const isKickOut = !allIdsWorker.includes(worker?.id);

                                            return { ...worker, isKickOut };
                                          });

                                        return (
                                          <Row className={styles.workerList} gutter={[8, 8]}>
                                            {selectedWorker?.map((worker: any) => {
                                              const isLeaderOfWorker =
                                                worker?.managerId === profile?.id || worker?.userCompany?.managerId;

                                              return (
                                                <Col
                                                  span={24}
                                                  key={worker.id}
                                                  className={classNames({
                                                    [styles.disabled]: isVendorOfTask && !isLeaderOfWorker,
                                                  })}
                                                >
                                                  <ItemWorkerSelected
                                                    worker={worker}
                                                    handleRemoveWorker={handleRemoveWorker('afternoon')}
                                                    shift="afternoon"
                                                    roleId={currentRoleInCompany}
                                                    // companyId={companySelected?.id}
                                                    isSmallTask={isSmallTask}
                                                    options={isSmallTask ? [] : certificateOptions}
                                                    form={form}
                                                  />
                                                </Col>
                                              );
                                            })}
                                          </Row>
                                        );
                                      }}
                                    </Form.Item>
                                  </Loader>
                                </Col>
                              </AccessControlMultiCompany>
                            </>
                          )}
                        </Row>
                      </Panel>
                    </Collapse>
                  ) : null}

                  {/* Evening shift */}
                  {isCanViewTime(isShowPanelEvening, 'eveningShift') ? (
                    <Collapse
                      expandIconPosition="right"
                      className={styles.accordionShift}
                      defaultActiveKey={['morningShift', 'afternoonShift', 'eveningShift', 'otherShift']}
                    >
                      <Panel
                        key="eveningShift"
                        header="夜間"
                        // style={{ display: isCanViewTime(isShowPanelEvening, 'eveningShift') ? '' : 'none' }}
                        extra={
                          <AccessControlMultiCompany accessibleRoles={ROLES_ADMIN} roleInCompany={currentRoleInCompany}>
                            <IconDeleteShift
                              onClick={(e) => {
                                e.stopPropagation();

                                handleDeletePanel('evening');
                              }}
                            />
                          </AccessControlMultiCompany>
                        }
                      >
                        <Row gutter={8}>
                          {isCanViewTime(isShowPanelEvening, 'eveningShift') && (
                            <>
                              <Col xs={12}>
                                <Form.Item
                                  label="開始時刻"
                                  name="eveningFrom"
                                  rules={[
                                    {
                                      required: true,
                                      message: '全ての項目を入力してください。',
                                    },
                                  ]}
                                  labelAlign="left"
                                >
                                  <CommonDatepicker
                                    picker={TYPE_PICKER.TIME}
                                    allowClear={false}
                                    disabled={[UserRole.VENDOR, UserRole.CUSTOMER].includes(currentRoleInCompany)}
                                    minuteStep={5}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={12}>
                                <Form.Item
                                  label="終了時刻"
                                  name="eveningTo"
                                  rules={[
                                    {
                                      required: true,
                                      message: '全ての項目を入力してください。',
                                    },
                                  ]}
                                  labelAlign="left"
                                >
                                  <CommonDatepicker
                                    picker={TYPE_PICKER.TIME}
                                    allowClear={false}
                                    disabled={[UserRole.VENDOR, UserRole.CUSTOMER].includes(currentRoleInCompany)}
                                    minuteStep={5}
                                  />
                                </Form.Item>
                              </Col>
                            </>
                          )}

                          {isCanViewOtherField.eveningShift && (
                            <>
                              {![UserRole.VENDOR, UserRole.CUSTOMER].includes(currentRoleInCompany) && (
                                <Col span={12}>
                                  <Form.Item
                                    label={textJP.label.numberPeople}
                                    name={'workerEvening'}
                                    rules={[
                                      {
                                        required: true,
                                        message: '全ての項目を入力してください。',
                                      },
                                    ]}
                                    labelAlign="left"
                                  >
                                    <CommonInputNumber min={0} isOnlyInteger />
                                  </Form.Item>
                                </Col>
                              )}

                              <AccessControlMultiCompany
                                accessibleRoles={[
                                  UserRole.ADMIN,
                                  UserRole.MANAGER,
                                  UserRole.OFFICE_MANAGER,
                                  UserRole.LEADER,
                                  UserRole.VENDOR,
                                ]}
                                roleInCompany={currentRoleInCompany}
                              >
                                <Col span={24}>
                                  <Loader isLoading={isLoadingConstruction}>
                                    <Form.Item label="サプライヤー" name="vendorsEvening" labelAlign="left">
                                      <VendorPicker
                                        options={isSmallTask ? listVendor : joinedVendorsActive}
                                        disabled={isVendorOfTask}
                                        form={form}
                                        handleSelectedVendor={(props) => {
                                          handleSelectedVendor({ ...props });

                                          if (isSmallTask) {
                                            handleOnBlurVendor();
                                          }
                                        }}
                                        shift="evening"
                                        {...propsOfSelectVendor}
                                      />
                                    </Form.Item>
                                  </Loader>
                                </Col>
                                <Col span={24}>
                                  <Form.Item
                                    label="スタッフ"
                                    name="workerIdsEvening"
                                    labelAlign="left"
                                    className={styles.workerSelectList}
                                  >
                                    <CommonSelect
                                      optionLabel={'name'}
                                      filterOption={false}
                                      onSearch={(e: any) => handleSearchWorker(e, 'evening')}
                                      onFocus={() => handleSearchWorker('', 'evening')}
                                      options={listWorker || []}
                                      mode={TYPE_INPUT.MULTIPLE}
                                      suffixIcon={<img src={icSearch} style={{ margin: -12 }} alt="" />}
                                      onSelect={(workerId: number) =>
                                        handleFillDefaultCountWorkWorker({ shift: 'evening', workerId })
                                      }
                                    />
                                  </Form.Item>
                                  <Loader isLoading={isLoadingConstruction}></Loader>

                                  <Form.Item
                                    shouldUpdate={(prev, next) => prev.workerIdsEvening !== next.workerIdsEvening}
                                    noStyle
                                  >
                                    {() => {
                                      const allWorkers = isSmallTask
                                        ? listWorkerOfCompany
                                        : constructionSiteDetail?.data?.workers || [];
                                      const allIdsWorker = allWorkers?.map((worker) => worker?.id);

                                      const selectedWorkerIds = form.getFieldValue('workerIdsEvening') as number[];

                                      const workersEvening = form.getFieldValue('workersEvening') || [];

                                      const selectedWorker = unionBy([...allWorkers, ...workersEvening], 'id')
                                        ?.filter((x: any) => selectedWorkerIds?.includes(x.id))
                                        ?.map((worker) => {
                                          const isKickOut = !allIdsWorker.includes(worker?.id);

                                          return { ...worker, isKickOut };
                                        });

                                      return (
                                        <Row className={styles.workerList} gutter={[8, 8]}>
                                          {selectedWorker?.map((worker: any) => {
                                            const isLeaderOfWorker =
                                              worker?.managerId === profile?.id || worker?.userCompany?.managerId;

                                            return (
                                              <Col
                                                span={24}
                                                key={worker.id}
                                                className={classNames({
                                                  [styles.disabled]: isVendorOfTask && !isLeaderOfWorker,
                                                })}
                                              >
                                                <ItemWorkerSelected
                                                  worker={worker}
                                                  handleRemoveWorker={handleRemoveWorker('evening')}
                                                  shift="evening"
                                                  roleId={currentRoleInCompany}
                                                  // companyId={companySelected?.id}
                                                  isSmallTask={isSmallTask}
                                                  options={isSmallTask ? [] : certificateOptions}
                                                  form={form}
                                                />
                                              </Col>
                                            );
                                          })}
                                        </Row>
                                      );
                                    }}
                                  </Form.Item>
                                </Col>
                              </AccessControlMultiCompany>
                            </>
                          )}
                        </Row>
                      </Panel>
                    </Collapse>
                  ) : null}

                  {/* otherShift */}
                  {isCanViewTime(isShowPanelOther, 'otherShift') ? (
                    <Collapse
                      expandIconPosition="right"
                      className={styles.accordionShift}
                      defaultActiveKey={['morningShift', 'afternoonShift', 'eveningShift', 'otherShift']}
                    >
                      <Panel
                        key="otherShift"
                        header="オプション"
                        extra={
                          <AccessControlMultiCompany accessibleRoles={ROLES_ADMIN} roleInCompany={currentRoleInCompany}>
                            <IconDeleteShift
                              onClick={(e) => {
                                e.stopPropagation();

                                handleDeletePanel('other');
                              }}
                            />
                          </AccessControlMultiCompany>
                        }
                      >
                        <Row gutter={8}>
                          {/* <AccessControlMultiCompany accessibleRoles={ROLES_ADMIN} roleInCompany={currentRoleInCompany}>
                            <Space
                              size="small"
                              align="center"
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                                marginBottom: '10px',
                              }}
                            >
                              <LabelRequired label={textJP.label.projectTimeFrame} className="mr-10" />

                              <Space align="center">
                                {(isSmallTask ? true : profileCompany?.morningShift === STATUS.ACTIVE) && (
                                  <Form.Item
                                    className="mb-0"
                                    name="otherShiftTypeMorning"
                                    valuePropName="checked"
                                    noStyle
                                  >
                                    <Checkbox className="strong" disabled={checkedOfAdmin.morningShift}>
                                      {textJP.shift.morningShift}
                                    </Checkbox>
                                  </Form.Item>
                                )}

                                {(isSmallTask ? true : profileCompany?.afternoonShift === STATUS.ACTIVE) && (
                                  <Form.Item
                                    className="mb-0"
                                    name="otherShiftTypeAfternoon"
                                    valuePropName="checked"
                                    noStyle
                                  >
                                    <Checkbox className="strong" disabled={checkedOfAdmin.afternoonShift}>
                                      {textJP.shift.afternoonShift}
                                    </Checkbox>
                                  </Form.Item>
                                )}

                                {(isSmallTask ? true : profileCompany?.eveningShift === STATUS.ACTIVE) && (
                                  <Form.Item
                                    className="mb-0"
                                    name="otherShiftTypeEvening"
                                    valuePropName="checked"
                                    noStyle
                                  >
                                    <Checkbox className="strong" disabled={checkedOfAdmin.eveningShift}>
                                      {textJP.shift.eveningShift}
                                    </Checkbox>
                                  </Form.Item>
                                )}
                              </Space>
                            </Space>
                          </AccessControlMultiCompany> */}

                          {isCanViewTime(isShowPanelOther, 'otherShift') && (
                            <>
                              <Col xs={12}>
                                <Form.Item
                                  label="開始時刻"
                                  name="otherFrom"
                                  rules={[
                                    {
                                      required: true,
                                      message: '全ての項目を入力してください。',
                                    },
                                  ]}
                                  labelAlign="left"
                                >
                                  <CommonDatepicker
                                    picker={TYPE_PICKER.TIME}
                                    allowClear={false}
                                    disabled={[UserRole.VENDOR, UserRole.CUSTOMER].includes(currentRoleInCompany)}
                                    minuteStep={5}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={12}>
                                <Form.Item
                                  label="終了時刻"
                                  name="otherTo"
                                  rules={[
                                    {
                                      required: true,
                                      message: '全ての項目を入力してください。',
                                    },
                                  ]}
                                  labelAlign="left"
                                >
                                  <CommonDatepicker
                                    picker={TYPE_PICKER.TIME}
                                    allowClear={false}
                                    disabled={[UserRole.VENDOR, UserRole.CUSTOMER].includes(currentRoleInCompany)}
                                    minuteStep={5}
                                  />
                                </Form.Item>
                              </Col>
                            </>
                          )}

                          {isCanViewOtherField.otherShift && (
                            <>
                              {![UserRole.VENDOR, UserRole.CUSTOMER].includes(currentRoleInCompany) && (
                                <Col span={12}>
                                  <Form.Item
                                    label={textJP.label.numberPeople}
                                    name={'workerOther'}
                                    rules={[
                                      {
                                        required: true,
                                        message: '全ての項目を入力してください。',
                                      },
                                    ]}
                                    labelAlign="left"
                                  >
                                    <CommonInputNumber min={0} isOnlyInteger />
                                  </Form.Item>
                                </Col>
                              )}
                              <AccessControlMultiCompany
                                accessibleRoles={[
                                  UserRole.ADMIN,
                                  UserRole.MANAGER,
                                  UserRole.OFFICE_MANAGER,
                                  UserRole.LEADER,
                                  UserRole.VENDOR,
                                ]}
                                roleInCompany={currentRoleInCompany}
                              >
                                <Col span={24}>
                                  <Loader isLoading={isLoadingConstruction}>
                                    <Form.Item label="サプライヤー" name="vendorsOther" labelAlign="left">
                                      <VendorPicker
                                        options={isSmallTask ? listVendor : joinedVendorsActive}
                                        disabled={isVendorOfTask}
                                        form={form}
                                        handleSelectedVendor={(props) => {
                                          handleSelectedVendor({ ...props });

                                          if (isSmallTask) {
                                            handleOnBlurVendor();
                                          }
                                        }}
                                        shift="other"
                                        {...propsOfSelectVendor}
                                      />
                                    </Form.Item>
                                  </Loader>
                                </Col>
                                <Col span={24}>
                                  <Form.Item
                                    label="スタッフ"
                                    name="workerIdsOther"
                                    labelAlign="left"
                                    className={styles.workerSelectList}
                                  >
                                    <CommonSelect
                                      optionLabel={'name'}
                                      filterOption={false}
                                      onSearch={(e: any) => handleSearchWorker(e, 'other')}
                                      onFocus={() => handleSearchWorker('', 'other')}
                                      options={listWorker || []}
                                      mode={TYPE_INPUT.MULTIPLE}
                                      suffixIcon={<img src={icSearch} style={{ margin: -12 }} alt="" />}
                                      onSelect={(workerId: number) =>
                                        handleFillDefaultCountWorkWorker({ shift: 'other', workerId })
                                      }
                                    />
                                  </Form.Item>

                                  <Loader isLoading={isLoadingConstruction}>
                                    <Form.Item
                                      shouldUpdate={(prev, next) => prev.workerIdsOther !== next.workerIdsOther}
                                      noStyle
                                    >
                                      {() => {
                                        const allWorkers = isSmallTask
                                          ? listWorkerOfCompany
                                          : constructionSiteDetail?.data?.workers || [];
                                        const allIdsWorker = allWorkers?.map((worker) => worker?.id);

                                        const selectedWorkerIds = form.getFieldValue('workerIdsOther') as number[];

                                        const workersOther = form.getFieldValue('workersOther') || [];

                                        const selectedWorker = unionBy([...allWorkers, ...workersOther], 'id')
                                          ?.filter((x: any) => selectedWorkerIds?.includes(x.id))
                                          ?.map((worker) => {
                                            const isKickOut = !allIdsWorker.includes(worker?.id);

                                            return { ...worker, isKickOut };
                                          });

                                        return (
                                          <Row className={styles.workerList} gutter={[8, 8]}>
                                            {selectedWorker?.map((worker: any) => {
                                              const isLeaderOfWorker =
                                                worker?.managerId === profile?.id || worker?.userCompany?.managerId;

                                              return (
                                                <Col
                                                  span={24}
                                                  key={worker.id}
                                                  className={classNames({
                                                    [styles.disabled]: isVendorOfTask && !isLeaderOfWorker,
                                                  })}
                                                >
                                                  <ItemWorkerSelected
                                                    worker={worker}
                                                    handleRemoveWorker={handleRemoveWorker('other')}
                                                    form={form}
                                                    shift="other"
                                                    roleId={currentRoleInCompany}
                                                    // companyId={companySelected?.id}
                                                    isSmallTask={isSmallTask}
                                                    options={isSmallTask ? [] : certificateOptions}
                                                  />
                                                </Col>
                                              );
                                            })}
                                          </Row>
                                        );
                                      }}
                                    </Form.Item>
                                  </Loader>
                                </Col>
                              </AccessControlMultiCompany>
                            </>
                          )}
                        </Row>
                      </Panel>
                    </Collapse>
                  ) : null}
                </AccessControlMultiCompany>

                {/* {isShowButtonCreateOtherShift && (
                  <div onClick={handleCreateOtherShift} className={styles.btnAddOtherShift}>
                    <CommonSpace justify="center" widthFull align="center">
                      <Button icon={<img src={icons.plus.outlineGray} alt="" />} type="text" shape="circle" />
                      <div className="mt-6">{textJP.shift.otherShift}</div>
                    </CommonSpace>
                  </div>
                )} */}

                {/* Start Certificate vendor */}
                <AccessControlMultiCompany
                  accessibleRoles={[
                    UserRole.ADMIN,
                    UserRole.MANAGER,
                    UserRole.OFFICE_MANAGER,
                    UserRole.LEADER,
                    UserRole.VENDOR,
                  ]}
                  roleInCompany={currentRoleInCompany}
                >
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                      const vendorsMorning = getFieldValue('vendorsMorning') || [];
                      const vendorsAfternoon = getFieldValue('vendorsAfternoon') || [];
                      const vendorsEvening = getFieldValue('vendorsEvening') || [];
                      const vendorsOther = getFieldValue('vendorsOther') || [];

                      const isEmptyVendor =
                        vendorsMorning.length === 0 &&
                        vendorsAfternoon.length === 0 &&
                        vendorsEvening.length === 0 &&
                        vendorsOther.length === 0;

                      // number of vendors have certificate
                      const numberVendorHaveCertificate = getNumberVendorHaveCertificate({
                        taskDetail,
                        profile,
                        currentRoleInCompany,
                      });

                      // const isRolesCanNotEditCertificate -> CUSTOMER, VENDOR
                      const isUserCanNotEditCertificate = [UserRole.CUSTOMER, UserRole.VENDOR].includes(
                        currentRoleInCompany
                      );

                      if (isEmptyVendor || (numberVendorHaveCertificate === 0 && isUserCanNotEditCertificate)) {
                        return null;
                      }
                      return (
                        <Col span={24}>
                          <Form.Item
                            label="サプライヤーのライセンス"
                            name="certificatesVendor"
                            labelAlign="left"
                            shouldUpdate
                          >
                            <VendorPicker
                              options={uniqueSelectedVendor}
                              type="certificateVendor"
                              handlePickSelectedVendor={handlePickSelectedVendor}
                              form={form}
                              onRemove={(vendor) => {
                                const values = form.getFieldsValue(true) || {};
                                form.setFieldsValue({ ...values, [vendor?.id]: undefined });
                              }}
                              disabled={isUserCanNotEditCertificate}
                            />
                          </Form.Item>
                        </Col>
                      );
                    }}
                  </Form.Item>
                </AccessControlMultiCompany>
                {/* End Certificate vendor */}
              </div>
            </Col>
          </Row>
        </Loader>
      </Form>
    </CommonModal>
  );
};
