import { ModeType, UserRole } from 'common';
import { ICheckedTask, ICompanyActive, ITaskDetail } from 'common/interface';
import { getColorOfCalendar } from 'helper';

import { isNil } from 'lodash';

export const configShowOneColumn = {
  widthModal: 900,
  spanCol1: 24,
  spanCol2: 0,
};

const configShowTwoColumn = {
  widthModal: 1200,
  spanCol1: 12,
  spanCol2: 12,
};

const useGetWidth = ({
  checked,
  mode,
  task,
  companySelected,
  isOnlyCustomerOfTask,
}: {
  checked: ICheckedTask;
  mode?: ModeType;
  task?: ITaskDetail;
  companySelected?: ICompanyActive;
  isOnlyCustomerOfTask?: boolean;
}) => {
  const isAdminAlreadyUpdateTask = !isNil(task?.startTime);
  const { isRedColor, bgGrayColor, isOrangeColor } = (task && getColorOfCalendar(task as any)) || {};

  const isCustomerCreateTask =
    [companySelected?.roleId === UserRole.CUSTOMER, Boolean(companySelected?.isCustomer)].some(Boolean) &&
    mode === ModeType.CREATE;

  // Roles : CUSTOMER, VENDOR_CUSTOMER create task
  if (isCustomerCreateTask) {
    return configShowOneColumn;
  }

  // Roles : CUSTOMER
  if (isOnlyCustomerOfTask) {
    const arrShowOneColumn = [mode === ModeType.CREATE, isRedColor, isOrangeColor && !isAdminAlreadyUpdateTask];

    if (arrShowOneColumn.some((item) => Boolean(item))) {
      return configShowOneColumn;
    }

    return configShowTwoColumn;
  }

  // other roles view : ROLES_ADMIN, LEADER, VENDOR_CUSTOMER
  if (mode === ModeType.VIEW && isRedColor) {
    return configShowOneColumn;
  }

  // if (isOrangeColor && !bgGrayColor && !isAdminAlreadyUpdateTask) {
  if (isOrangeColor && !isAdminAlreadyUpdateTask) {
    return configShowOneColumn;
  }

  // Nếu check vào checkbox thì hiển thị 2 cột
  const isCheckedShift = checked.morning || checked.afternoon || checked.evening || checked.other;

  return isCheckedShift ? configShowTwoColumn : configShowOneColumn;
};

export default useGetWidth;
