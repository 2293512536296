import { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { TaskDetailModal } from 'pages/Tasks/TaskDetailModal';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { getConstructionSiteTaskDetails } from 'api/constructionSite';
import { Button, Form } from 'antd';
import { CommonInput } from 'components/CommonInput';
import Loader from 'components/Loader';
import { sendEmail } from 'api/task';
import { handleErrorMessage, handleMessageSuccess } from 'helper';
import { useRulesForm } from 'hooks/useRulesForm';
import { getNamePdfFile } from 'pages/Tasks/components/ButtonSendEmail/utils';
import { TYPE_INPUT } from 'common';
import useProfile from 'hooks/useProfile';
import sanitize from 'sanitize-filename';
//@ts-ignore
import html2pdf from 'html2pdf.js';
import { CommonButton } from 'components/CommonButton';
import { getImageFormUrl } from 'api/common';
import { dateUtils } from 'common/dateUtils';
import CommonSpace from 'components/CommonSpace';
import icons from 'assets/icons';
import { uploadFilePdf } from 'api/upload';

type TValue = {
  replyToEmail: string;
  to: string;
  subject: string;
  html: string;
};

type TInfoFile = {
  file: File | null;
  blob: Blob | null;
};

const SendEmail = () => {
  const detailsTaskRef = useRef<any>(null);

  const { taskId, companyId } = useParams<{ taskId: string; companyId: string }>();
  const profile = useProfile();

  const [form] = Form.useForm();
  const { rulesForm } = useRulesForm();

  const [infoFile, setInfoFile] = useState<TInfoFile>({
    file: null,
    blob: null,
  });
  const [isLoadingPdf, setIsLoadingPdf] = useState(false);

  // get details task
  const { data: taskDetail, isLoading: isLoadingTaskDetail } = useQuery(
    ['construction-site-task-detail', taskId, companyId],
    () => getConstructionSiteTaskDetails(+taskId, +companyId).then((res: any) => res?.data),
    { enabled: !!taskId }
  );

  const sendEmailMutation = useMutation({
    mutationFn: sendEmail,
    onSuccess() {
      // Ví sau khi gửi email xong thì không đóng modal lại nên không cần reset form, state
      handleMessageSuccess('メールを送信しました。');

      // reset fields form after send email success
      // form.resetFields(['subject', 'html']);
    },
    onError(error, variables, context) {
      handleErrorMessage(error);
    },
  });

  const onSendEmail = (values: TValue) => {
    const formData = new FormData();
    formData.append('replyToEmail', values.replyToEmail);
    formData.append('to', values.to);
    formData.append('subject', values.subject ?? '');
    formData.append('html', values.html ?? '');
    formData.append('file', infoFile.file as File);
    formData.append('taskId', taskDetail?.id as any);

    sendEmailMutation.mutate({ data: formData, companyId: +companyId });
  };

  const generatePdf = async ({ nameFile, isViewNewTab }: { nameFile: string; isViewNewTab?: boolean }) => {
    if (!detailsTaskRef.current) return;

    setIsLoadingPdf(true);

    try {
      const clonedElement = detailsTaskRef.current.cloneNode(true) as HTMLElement;

      // Add extra elements
      const extraElements = document.createElement('div');
      extraElements.innerHTML = `
        <div style="padding-left:10px;">
          <div>
            ${[taskDetail?.taskCompanyName, profile?.name].filter(Boolean).join(' - ')}
          </div>
          <div>件名 : ${nameFile}</div>
          <div>${dateUtils.currentFullTimeJaPan3()}</div>
          <div></div>
        </div>
      `;

      // Hide unnecessary elements with class hide-with-pdf
      clonedElement.querySelectorAll('.hide-with-pdf').forEach((el) => el.remove());

      // Replace image URLs to avoid CORS issues
      clonedElement.querySelectorAll('img')?.forEach((img: any) => {
        if (img?.src?.startsWith('https://')) {
          img.src = img.src.replace(img.src, getImageFormUrl(img.src));
        }
      });

      // Remove unnecessary classes for proper PDF rendering
      clonedElement.classList.remove('fixed-header-form');

      // Tạo div cha mới
      const wrapperDiv = document.createElement('div');

      // Thêm extraElements và clonedElement vào wrapperDiv
      wrapperDiv.appendChild(extraElements);
      wrapperDiv.appendChild(clonedElement);

      // PDF options
      const opt = {
        margin: 0.5,
        image: { type: 'jpeg', quality: 0.95 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: 'in', format: 'a3', orientation: 'portrait' },
      };

      // Generate PDF blob

      const blob = await html2pdf().set(opt).from(wrapperDiv).outputPdf('blob', nameFile);

      // Convert blob to file
      const file = new File([blob], nameFile, { type: 'application/pdf' });

      setInfoFile((pre) => ({ ...pre, file, blob }));

      if (isViewNewTab) {
        if ((window as any).ReactNativeWebView) {
          const { domain, data } = await uploadFilePdf(file);
          const url = `${domain}/${data?.[0]}`;

          (window as any).ReactNativeWebView.postMessage(JSON.stringify({ type: 'viewPdf', url }));
        }
      }
    } catch (error) {
      handleErrorMessage(error);
    } finally {
      setIsLoadingPdf(false);
    }
  };

  // watch isLoadingTaskDetail and ref detailsTaskRef to render pdf
  useEffect(() => {
    if (!detailsTaskRef.current || isLoadingTaskDetail) return;

    const namePdfFile = sanitize(getNamePdfFile(taskDetail) || 'task_details.pdf');

    // Set default form values
    form.setFieldsValue({
      replyToEmail: profile?.email,
      to: taskDetail?.customerEmail,
      subject: namePdfFile,
    });

    // Generate PDF
    generatePdf({ nameFile: namePdfFile });
  }, [detailsTaskRef.current, isLoadingTaskDetail, taskDetail, profile?.email, form]);

  return (
    <>
      <div className={styles.wrapper}>
        <Form form={form} layout="vertical" onFinish={onSendEmail}>
          <Form.Item label="From" name="replyToEmail" className="mb-10" rules={[rulesForm().required]}>
            <CommonInput placeholder="example@gmail.com" />
          </Form.Item>
          <Form.Item label="To" name="to" className="mb-10" rules={[rulesForm().required]}>
            <CommonInput placeholder="example@gmail.com" />
          </Form.Item>
          <Form.Item label="件名" name="subject" className="mb-10" rules={[rulesForm().required]}>
            <CommonInput placeholder="タイトルを入力してください。" />
          </Form.Item>
          <Form.Item label="メッセージ" name="html" className="mb-10">
            <CommonInput placeholder="メッセージを入力してください" mode={TYPE_INPUT.MULTIPLE} />
          </Form.Item>

          <Loader isLoading={isLoadingPdf}>
            {infoFile.file && (
              <CommonSpace align="center" gap={10}>
                <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues?.subject !== curValues?.subject}>
                  {({ getFieldValue }) => {
                    const fileNamePdf = getFieldValue('subject');

                    return <div className={styles.linkPdf}>{fileNamePdf}</div>;
                  }}
                </Form.Item>

                <Form.Item shouldUpdate noStyle>
                  {({ getFieldValue }) => {
                    // Re-render PDF
                    const namePdfFile = getFieldValue('subject');

                    return (
                      <Button
                        type="text"
                        icon={<img src={icons.eye.gray} alt="" style={{ width: '20px' }} />}
                        shape="circle"
                        onClick={async () => {
                          await generatePdf({ nameFile: namePdfFile, isViewNewTab: true });
                        }}
                      />
                    );
                  }}
                </Form.Item>
              </CommonSpace>
            )}
          </Loader>

          <CommonButton
            title="メール送信"
            htmlType="submit"
            type="primary"
            loading={sendEmailMutation.isLoading}
            className="mt-20 w-100"
          />
        </Form>
      </div>

      <TaskDetailModal
        taskId={+taskId}
        onCancel={() => {}}
        visible
        className={styles.myModal}
        ref={detailsTaskRef as any}
      />
    </>
  );
};

export default SendEmail;
