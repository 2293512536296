import {
  IFilter,
  IResConstructionSiteWorker,
  TResCommonWorkers,
  TResSearchUserCompany,
  TResWorkerSalary,
} from 'common/interface';
import { axiosInstance, sendGet, sendPost } from './axios';
import configs from 'config';

export const getCompanyCommon = (filter: Partial<IFilter>) => sendPost('/common/company', filter);
export const getVendorManager = (payload: any) => sendPost('/common/vendor-manager', payload);
export const searchConstructionSiteWorker = (payload: any) =>
  sendPost<IResConstructionSiteWorker>('common/search-construction-site-worker', payload);
export const searchConstructionSiteOfWorker = (payload: any) => sendPost('/common/construction-site', payload);
export const searchCommonVendor = (filter: Partial<IFilter>) => sendPost('common/vendor', filter);
export const searchCommonWorker = (filter: Partial<IFilter>) => sendPost<TResCommonWorkers>('/common/worker', filter);
export const searchCommonLeader = (filter: Partial<IFilter>) => sendPost('/common/leader', filter);
export const searchCommonCustomer = (filter: Partial<IFilter>) => sendPost('/common/customer', filter);

export const searchConstructionSiteWorkerV2 = async (payload: any, companyId?: number) => {
  const response = await axiosInstance.post('/v2/common/search-construction-site-worker', payload, {
    headers: { companyId },
  });

  return response.data;
};

export const searchUserCompany = (payload: any) =>
  sendPost<TResSearchUserCompany>('/v2/user/search-user-company', payload);

export const getUserSalaryType = async (params: any, companyId?: number) => {
  const response = await axiosInstance.get('/v5/timekeeping/get-user-salary-type', {
    params,
    headers: { companyId },
  });

  return response?.data;
};

export const getWorkerSalary = async (targetId: number, companyId?: number) => {
  const res = await axiosInstance.get<TResWorkerSalary>(`/v2/common/get-construction-site-worker-salary/${targetId}`, {
    headers: { companyId },
  });

  return res?.data;
};

export const getImageFormUrl = (url: string) => {
  const domain = configs.API_DOMAIN;

  return `${domain}/nest/common?url=${url}`;
};
