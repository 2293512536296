import AvatarProfile from 'components/Avatar';
import styles from './styles.module.scss';
import { IWorker } from 'common/interface';
import { getSalaryStaff, getWorkerType, setValueLabelInValue } from 'helper';
import { SalaryType, UserRole } from 'common';
import icClose from 'assets/icons/x.svg';
import { Col, Divider, Form, Row } from 'antd';
import { CommonInput } from 'components/CommonInput';

import { FormInstance } from 'antd/es/form/Form';
import { Shift } from 'pages/Tasks/AddTaskModal';
import { messageConfirm, ROLES_ADMIN, textJP } from 'common/const';
import CommonInputNumberV2 from 'components/CommonInputNumberV2';

import useProfile from 'hooks/useProfile';
import { isNil } from 'lodash';
import { CommonIconButton } from 'components/CommonIconButton';
import icons from 'assets/icons';
import { CommonSelect } from 'components/CommonSelect';
import CommonSpace from 'components/CommonSpace';
import { useState } from 'react';
import { CommonButton } from 'components/CommonButton';
import { useRulesForm } from 'hooks/useRulesForm';

interface IProps {
  worker: IWorker;
  handleRemoveWorker: (id: number) => void;
  form?: FormInstance;
  // showPrice?: boolean;
  shift: Shift;
  roleId: UserRole;
  // companyId?: number;
  // initialCountWork?: number;
  isSmallTask: boolean;
  options?: any[];
}

export const priceByCountWorkShift: Record<Shift, string> = {
  morning: 'price_morning',
  afternoon: 'price_afternoon',
  evening: 'price_evening',
  other: 'price_other',
};

const ItemWorkerSelected = ({
  worker,
  handleRemoveWorker,
  form,
  // showPrice = false,
  shift,
  roleId,
  // companyId,
  // initialCountWork,
  isSmallTask,
  options = [],
}: IProps) => {
  const { rulesForm } = useRulesForm();
  const profile = useProfile();
  const [customTitle, setCustomTitle] = useState<string>('');

  const nameFormPriceByShift = `${priceByCountWorkShift[shift]}_${worker.id}`;
  const nameFormPriceByOtherShift = `price_other_worker_${worker.id}`;
  const nameFormPrice = shift === 'other' ? nameFormPriceByOtherShift : nameFormPriceByShift;
  const nameFromCountWork = `count_work_${shift}_${worker.id}`;
  const nameFormList = `certificate_worker_${shift}_${worker.id}`;

  const salary = isSmallTask ? worker?.salary : worker?.userSalary;

  const defaultPrice = getSalaryStaff({
    roleIdStaff: worker?.roleId,
    shift,
    salary,
    salaryType: salary?.salaryType,
  });

  const isCanViewPrice = () => {
    if (ROLES_ADMIN.includes(roleId)) {
      return true;
    }

    // Công nhân của vendor
    if (roleId === UserRole.VENDOR) {
      return worker?.roleId === UserRole.VENDOR_WORKER && worker?.managerId === profile?.id;
    }

    // chính bản thân worker
    return worker?.id === profile.id;
  };

  const isWorkerHavePrice = () => {
    const isHavePrice =
      !isNil(salary?.salaryType) && ![SalaryType.MONTH_SALARY, SalaryType.HOUR_SALARY].includes(salary?.salaryType);

    return isHavePrice;
  };

  const isCanEditPrice = () => {
    if (ROLES_ADMIN.includes(roleId)) {
      return true;
    }

    // Công nhân của vendor
    if (roleId === UserRole.VENDOR) {
      return worker?.roleId === UserRole.VENDOR_WORKER && worker?.managerId === profile?.id;
    }

    return false;
  };

  const isShowRemove = () => {
    if (ROLES_ADMIN.includes(roleId)) {
      return true;
    }

    // Công nhân của vendor
    if (roleId === UserRole.VENDOR) {
      return worker?.roleId === UserRole.VENDOR_WORKER && worker?.managerId === profile?.id;
    }

    return false;
  };

  const isCanViewCertificateStaff = () => {
    const isAdmin = ROLES_ADMIN.includes(roleId);
    return isAdmin;

    // if (isAdmin) return true;

    // // lương giờ không view được chứng chỉ
    // const isHourSalary = salary?.salaryType === SalaryType.HOUR_SALARY;
    // if (isHourSalary) return false;

    // // worker có thể tự xem thông tin của mìnhs
    // return worker?.id === profile?.id;
  };

  const getTileAdd = () => {
    // Các roles khác --> 手当
    // Freelance --> その他の費用

    const isFreelancer = worker?.roleId === UserRole.OTHER_WORKER;

    if (!isFreelancer) return textJP.btn.addAllowance;

    return textJP.btn.addOtherCost;
  };

  const getTitleCertificate = () => {
    // Các roles khác --> 手当
    // Freelance --> その他の費用

    const isFreelancer = worker?.roleId === UserRole.OTHER_WORKER;

    if (!isFreelancer) return textJP.label.titleCertificateWorker;

    return textJP.label.titleCertificateFreelancer;
  };

  const isCanViewCountWorker = () => {
    const isAdmin = ROLES_ADMIN.includes(roleId);
    if (isAdmin) return true;

    // lương giờ không view công số được
    const isHourSalary = salary?.salaryType === SalaryType.HOUR_SALARY;
    if (isHourSalary) return false;

    // worker có thể tự xem thông tin của mìnhs
    return worker?.id === profile?.id;
  };

  const isCanEditCountWork = () => {
    return ROLES_ADMIN.includes(roleId);
  };

  // const isWorkerHaveCountWork = () => {
  //   const isHaveCountWork = !isNil(salary?.salaryType) && ![SalaryType.HOUR_SALARY].includes(salary?.salaryType);

  //   return isHaveCountWork;
  // };

  return (
    <div className={styles.workerItem}>
      <CommonSpace align="center" justify="space-between" gap={5}>
        <div style={{ display: 'flex' }}>
          <AvatarProfile src={worker.avatar} name={worker.name} size={33} />

          <div className="ml-10" style={{ display: 'flex', flexDirection: 'column' }}>
            <div className={styles.workerName}>{worker?.name}</div>
            <div className={styles.vendorName}>
              {worker?.vendorName ? `サプライヤー: ${worker?.vendorName}` : getWorkerType(worker.roleId)}
            </div>
          </div>
        </div>

        <Row gutter={[15, 10]}>
          {isCanViewCountWorker() && (
            <Col span={11}>
              <Form.Item name={nameFromCountWork} label="人工" className={styles.formPrice}>
                <CommonInputNumberV2 min={0} decimalPlaces={2} disabled={!isCanEditCountWork()} />
              </Form.Item>
            </Col>
          )}

          {isCanViewPrice() && isWorkerHavePrice() && (
            <Col span={11}>
              <Form.Item
                name={nameFormPrice}
                label="単価"
                initialValue={defaultPrice ?? 0}
                className={styles.formPrice}
              >
                <CommonInput type="number" min={0} defaultValue={0} disabled={!isCanEditPrice()} />
              </Form.Item>
            </Col>
          )}
        </Row>
      </CommonSpace>

      {isCanViewCertificateStaff() && (
        <Form.List name={nameFormList}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Row key={key} gutter={[10, 5]} className={key !== 0 ? 'mt-5' : ''}>
                  <Col span={11}>
                    <Form.Item
                      {...restField}
                      name={[name, 'title']}
                      className="mb-0"
                      label={getTitleCertificate()}
                      rules={[rulesForm().required]}
                    >
                      <CommonSelect
                        allowClear
                        style={{ width: '100%' }}
                        options={options}
                        optionLabel="title"
                        optionValue="id"
                        showSearch={false}
                        labelInValue
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <CommonSpace style={{ padding: '0 8px 4px' }} gap={10} align="center">
                              <CommonInput
                                value={customTitle}
                                onChange={(e) => setCustomTitle(e.target.value)}
                                placeholder={textJP.label.manualInput}
                              />
                              <CommonButton
                                title={textJP.btn.save}
                                type="primary"
                                onClick={() => {
                                  if (!customTitle.trim()) return;

                                  const valueUpdate = form
                                    ?.getFieldValue(nameFormList)
                                    ?.map((item: any, index: number) => {
                                      if (index === name) {
                                        return {
                                          ...item,
                                          title: setValueLabelInValue(customTitle),
                                        };
                                      }

                                      return item;
                                    });

                                  // set custom price to form
                                  form?.setFieldsValue({
                                    [nameFormList]: valueUpdate,
                                  });
                                }}
                              />
                            </CommonSpace>
                          </>
                        )}
                        onSelect={(value: any) => {
                          const valueUpdate = form?.getFieldValue(nameFormList)?.map((item: any, index: number) => {
                            if (index === name) {
                              const priceStaff = options.find((opt) => opt.id === value?.value)?.priceStaff ?? 0;

                              return {
                                ...item,
                                priceStaff,
                              };
                            }

                            return item;
                          });

                          form?.setFieldsValue({
                            [nameFormList]: valueUpdate,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={11}>
                    <Form.Item
                      {...restField}
                      name={[name, 'priceStaff']}
                      label={textJP.label.price}
                      rules={[rulesForm().required]}
                    >
                      <CommonInputNumberV2 />
                    </Form.Item>
                  </Col>

                  <Col span={1}>
                    <Form.Item label=" ">
                      <CommonIconButton
                        icon={icons.trash.gray}
                        title={textJP.delete}
                        onClick={() => remove(name)}
                        shape="circle"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              ))}

              <Form.Item>
                <Row
                  align="middle"
                  gutter={5}
                  onClick={() => add()}
                  style={{ width: 'fit-content', cursor: 'pointer' }}
                >
                  <CommonIconButton icon={icons.plus.outlineGray} shape="circle" className={styles.addNewRow} />

                  <span>{getTileAdd()}</span>
                </Row>
              </Form.Item>
            </>
          )}
        </Form.List>
      )}

      {isShowRemove() ? (
        <img className={styles.remove} src={icClose} onClick={() => handleRemoveWorker(worker?.id)} alt="" />
      ) : null}
    </div>
  );
};

export default ItemWorkerSelected;
