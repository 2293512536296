import { InputNumber, type InputNumberProps } from 'antd';
import { formatValue, isValidNumber } from './utils';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface IPropsInputNumber extends InputNumberProps {
  decimalPlaces?: number; // Số chữ số sau dấu phẩys
}

const CommonInputNumberV2 = ({ onChange, decimalPlaces, className, ...rest }: IPropsInputNumber) => {
  const handleChange = (value: string | number | null | undefined) => {
    if (value !== null && value !== undefined && !isValidNumber(value, decimalPlaces)) {
      return;
    }
    onChange?.(value);
  };

  return (
    <InputNumber
      className={classNames(styles.input, className)}
      onChange={handleChange}
      formatter={(value) => formatValue(value?.toString(), decimalPlaces)}
      parser={(value) => formatValue(value, decimalPlaces)}
      {...rest}
    />
  );
};

export default CommonInputNumberV2;
