import { Checkbox, Form } from 'antd';
import { CheckboxProps } from 'antd/es/checkbox';
import classNames from 'classnames';

interface IProps extends CheckboxProps {
  nameCountShift?: 'otherShiftTypeMorning' | 'otherShiftTypeAfternoon' | 'otherShiftTypeEvening';
  checkBoxText: string;
}

const CheckBoxShiftAdmin = ({ nameCountShift, checkBoxText, className, ...rest }: IProps) => {
  return (
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, curValues) => {
        if (!nameCountShift) return false;

        return prevValues[nameCountShift] !== curValues[nameCountShift];
      }}
    >
      {({ getFieldValue }) => {
        // const isAddCountToShift = nameCountShift && getFieldValue([nameCountShift]);

        return (
          <Checkbox className={classNames('strong', className)} {...rest}>
            {checkBoxText}
          </Checkbox>
        );
      }}
    </Form.Item>
  );
};

export default CheckBoxShiftAdmin;
